import { LocalStorageItem } from '../enums'

const handleAppVersionChange = () => {
  const savedAppVersion = localStorage[LocalStorageItem.APP_VERSION]
  if (savedAppVersion && savedAppVersion === APP_VERSION) {
    return
  }

  localStorage[LocalStorageItem.APP_VERSION] = APP_VERSION
  // Strings are used for old info that is no longer used
  ;[LocalStorageItem.TOKEN_ADDRESS, 'chain'].map((item) => localStorage.removeItem(item))
}

export { handleAppVersionChange }
