import { FC } from 'react'

import useCheckUser from '@/hooks/useCheckUser'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ButtonIcon, Tooltip } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  iconName: IconName
  label: string
  redirectTo?: string
}

const CollapsedIcon: FC<TProps> = ({ iconName, label, redirectTo }) => {
  const navigate = useCustomNavigate()
  const checkUserAndExecute = useCheckUser()

  return (
    <div className={styles.wrapper}>
      <Tooltip title={label} withIcon={false} placement="right" className={styles.tooltip}>
        <ButtonIcon
          icon={iconName}
          onClick={() =>
            checkUserAndExecute(
              () =>
                redirectTo &&
                navigate({
                  isDashboard: true,
                  path: redirectTo,
                }),
            )
          }
        />
      </Tooltip>
    </div>
  )
}

export { CollapsedIcon }
