import { FC, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import StarRateIcon from '@mui/icons-material/StarRate'
import { Stack } from '@mui/material'
import cls from 'classnames'

import { addUserFavoriteToken, deleteUserFavoriteToken } from '@/api/favorites'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, ButtonCopy, Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { validateLink } from '@/libs/helper/formatLink.ts'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { useAppSelector } from '@/store'

import { TokenInfoLite } from './libs/components/token-info-lite'
import { TokenInfoPro } from './libs/components/token-info-pro'
import styles from './styles.module.scss'

type TProps = {
  selectedToken: TTokenInfo
}

const SelectedTokenInfo: FC<TProps> = ({ selectedToken }) => {
  const { mode } = useParams()
  const navigate = useCustomNavigate()
  const userFavorites = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const userData = useAppSelector((state) => state.user.userData)

  const isUserFavoriteToken = useMemo(
    () => userFavorites?.some(({ t }) => t === selectedToken?.token?.address),
    [selectedToken, userFavorites],
  )

  const handleOpenBubbleMapModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.BUBBLE_MAP}`,
    })
  }
  const handleOpenModal = () => {
    navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.TOKEN_INFO}` })
  }

  const secondaryToken =
    selectedToken?.token && selectedToken?.pair
      ? selectedToken.token.address !== selectedToken.pair.address
        ? selectedToken.pair
        : selectedToken.token
      : null

  const handleUserFavourite = () => {
    if (!selectedToken.token) return
    ;(isUserFavoriteToken ? deleteUserFavoriteToken : addUserFavoriteToken)(
      selectedToken.token.address,
      currentChain.indexerChainId,
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={cls(styles.container, mode === 'lite' && styles.containerLite)}
    >
      <Stack className={styles.token}>
        <Stack direction="column" gap={0.5} className={styles.tokenDescrWrapper}>
          <div className={styles.tokenDescr}>
            <div className={styles.tokenInfo}>
              {selectedToken.token?.images.small ? (
                <img
                  className={styles.tokenLogo}
                  src={selectedToken.token.images.small}
                  alt={selectedToken.token.name + ' token'}
                />
              ) : (
                <TokenPlaceholder />
              )}

              <Typography variant="body1" className={styles.tokenName}>
                <span>
                  {selectedToken.token?.name} / {secondaryToken?.symbol}
                </span>
              </Typography>
            </div>
            {userData && (
              <Button onClick={handleUserFavourite} className={styles.favoriteBtn}>
                <StarRateIcon
                  className={styles.favoriteIcon}
                  fontSize="small"
                  sx={{
                    fill: isUserFavoriteToken ? '#FFC800' : 'transparent',
                    stroke: isUserFavoriteToken ? 'transparent' : '#979797',
                  }}
                />
              </Button>
            )}
          </div>

          <Typography variant="body1" className={styles.pairAddress}>
            <ButtonCopy value={selectedToken.token?.address} />

            {selectedToken.token && hideWalletAddress(selectedToken.token.address, 7)}
          </Typography>
        </Stack>

        {mode === AppMode.PRO && (
          <Stack className={styles.linksWrapper}>
            <div className={styles.links}>
              <Link
                to={validateLink(selectedToken?.socialLinks?.website) || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks?.website,
                })}
              >
                <Icon name={IconName.LANGUAGE} />
              </Link>
              <Link
                to={validateLink(selectedToken?.socialLinks?.telegram) || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks?.telegram,
                })}
              >
                <Icon name={IconName.EXCLUDE} />
              </Link>
              <Link
                to={validateLink(selectedToken?.socialLinks?.x) || ''}
                target="_blank"
                className={cls(styles.socialLink, {
                  [styles.disabledLink]: !selectedToken?.socialLinks?.x,
                })}
              >
                <Icon name={IconName.TWITTER} />
              </Link>
            </div>

            <div className={styles.searchLink}>
              {/* <Button

                styleVariant="black"
                className={styles.btn}
                transparent
                onClick={handleOpenBubbleMapModal}
              >
                Bubblemaps
              </Button> */}
              <Link to={`${AppRoute.MODAL}/${AppRoute.BUBBLE_MAP}`}>
                <Icon name={IconName.BUBBLE} />
              </Link>
              <Link target="_blank" to={`https://x.com/search?q=%24${selectedToken?.token?.name}`}>
                <Icon name={IconName.SEARCH} />
              </Link>
            </div>
          </Stack>
        )}
      </Stack>
      {mode === AppMode.PRO ? (
        <TokenInfoPro
          selectedToken={selectedToken}
          simulation={simulation}
          handleOpenModal={handleOpenModal}
        />
      ) : (
        <TokenInfoLite
          selectedToken={selectedToken}
          simulation={simulation}
          handleOpenModal={handleOpenModal}
        />
      )}
    </Stack>
  )
}

export { SelectedTokenInfo }
