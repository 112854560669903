import { FC, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { base, bsc, mainnet } from '@reown/appkit/networks'
import { useAppKit } from '@reown/appkit/react'
import { WalletKit } from '@reown/walletkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Core } from '@walletconnect/core'
import {
  WagmiProvider,
  useAccount,
  useBalance,
  useChainId,
  useConnect,
  useDisconnect,
  useSignMessage,
} from 'wagmi'
import { walletConnect } from 'wagmi/connectors'

import { getNonce, getStateOfUser, signIn } from '@/api/user'
import { TSignupBody } from '@/api/user/types'
import { CustomToast } from '@/components/custom-toast'
import { Spinner } from '@/components/spinner'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions.ts'
import { ListButton, Typography } from '@/libs/common'
import { AppRoute, SpinnerSize } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

const queryClient = new QueryClient()
const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID

const wagmiConfig = new WagmiAdapter({
  networks: [mainnet, bsc, base],
  projectId,
  connectors: [walletConnect({ projectId })],
})

type TProps = { isAuth?: boolean }
let nonce = ''

const ConnectWallet: FC<TProps> = ({ isAuth }) => {
  const { disconnect } = useDisconnect()
  const { address, isConnected } = useAccount()
  const windowDimension = useWindowDimensions()
  const isMobile = windowDimension.width <= 630
  const chainId = useChainId()
  const { connect } = useConnect()
  const { data: signMessageData, signMessageAsync, isPending } = useSignMessage()
  const { open } = useAppKit()
  const providers = useMemo(
    () =>
      wagmiConfig.wagmiConfig.connectors.filter((wallet) =>
        ['io.metamask', 'walletConnect', 'io.rabby'].includes(wallet.id),
      ),
    [],
  )
  const navigate = useCustomNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)
  const { isLoading } = useBalance({ address })

  useEffect(() => {
    disconnect()
  }, [])

  const saveData = (signature: string) => {
    localStorage.signData = JSON.stringify({
      blockchain: networkId,
      signatory: address,
      signature: signature ?? signMessageData,
      nonce,
    } as TSignupBody)
  }

  const redirect = async (signature: string) => {
    if (localStorage.signData) {
      const data = JSON.parse(localStorage.signData)
      if (address === data.signatory) {
        CustomToast('error', 'Please select your previous wallet, not the same one')
        disconnect()
        setTimeout(() => location.reload(), 3000)
        return
      }
    }

    if (address) {
      try {
        const res = await signIn({
          blockchain: networkId,
          signatory: address,
          signature: signature ?? signMessageData,
          nonce,
        })
        const { token, refresh_token, id } = res.data.data
        localStorage.token = token
        localStorage.refreshToken = refresh_token

        await dispatch(fetchUserById(id)).unwrap()
        dispatch(fetchUserBuyTemplates())

        navigate({
          path: isAuth
            ? `/${AppRoute.ADD_CHAIN}`
            : localStorage.code
              ? AppRoute.ADD_DEVICE
              : localStorage.cachedPath,
          isDashboard: !localStorage.code,
          searchParams: localStorage.code
            ? { code: localStorage.code }
            : { token: searchParams.get('token') },
        })
      } catch (e: any) {
        const errCode = e?.response?.data?.data?.code
        if (errCode === 'Q9S7WRQT81') {
          saveData(signature)
          navigate({
            path: isAuth ? `/${AppRoute.ENTER_CODE}` : `/${AppRoute.ENTER_CODE}`,
            searchParams: { wallet: address },
          })
        } else {
          navigate({ delta: -1 })
        }
      }
    }
  }

  const customConnect = async (data: any) => {
    await disconnect()
    await connect(data)
  }

  useEffect(() => {
    if (!isMobile) {
      sign()
    }
  }, [isConnected])

  const sign = async () => {
    if (isConnected) {
      try {
        const { data } = await getNonce()
        nonce = data.data.nonce
        const msg = `By Signing, You Agree To Our Terms Of Use And Privacy Policy: ${nonce}`
        const result = await signMessageAsync({ message: msg })
        const userState = await getStateOfUser(address as string)
        if (!userState.data.data.exists && address) {
          if (!isAuth) {
            saveData(result)
            localStorage.account = JSON.stringify({
              signatory: address,
              signature: result,
              nonce,
            })
            navigate({ path: `/${AppRoute.ALREADY_SIGN}` })
          } else {
            await redirect(result)
          }
        } else {
          await redirect(result)
        }
      } catch (error) {
        console.error('Error fetching nonce or signing message:', error)
      }
    }
  }

  return (
    <WagmiProvider config={wagmiConfig.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.connectWalletContainer}>
          <div className={styles.titleWrapper}>
            <Typography variant="h1" className={styles.title}>
              {isAuth ? 'Please connect your previous wallet' : 'Connect a Wallet'}
            </Typography>
          </div>
          {!isPending ? (
            isMobile ? (
              <div className={styles.connectorsList}>
                {!isConnected && !address ? (
                  <ListButton
                    iconName="WalletConnect"
                    title="Connect Wallet"
                    onClick={() => open({ view: 'Connect' })}
                  />
                ) : (
                  <ListButton title="Please verify your wallet signature." onClick={sign} />
                )}
              </div>
            ) : (
              <div className={styles.connectorsList}>
                {providers.map((connector) => (
                  <ListButton
                    title={connector.name}
                    key={connector.uid}
                    iconName={connector.name as any}
                    onClick={() => customConnect({ connector, chainId })}
                  />
                ))}
              </div>
            )
          ) : (
            <div className={styles.spinnerWrapper}>
              <Typography variant="h2" className={styles.title}>
                Signing...
              </Typography>
              <Spinner size={SpinnerSize.MEDIUM} centered />
            </div>
          )}
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export { ConnectWallet, wagmiConfig }
