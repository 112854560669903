import { FC } from 'react'

import cn from 'classnames'

import { ButtonCopy, Table, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  wallets: {
    wallet?: {
      private_key: string
      name?: string
      address?: string
    }
    address: string
    private_key?: string
    name?: string
  }[]
  withName?: boolean
}

const PrivateKeysTable: FC<TProps> = ({ wallets = [], withName = false }) => {
  return (
    <Table
      header={[
        <div className={cn(styles.row, { [styles.withName]: withName })} key={wallets.address}>
          {withName && (
            <Typography key="key" variant="body2" className={cn(styles.keyHeader, styles.name)}>
              Name
            </Typography>
          )}
          <Typography key="key" variant="body2" className={cn(styles.keyHeader, styles.privateKey)}>
            Private key
          </Typography>
        </div>,
      ]}
      rows={wallets.map((wallet) => [
        <div className={cn(styles.row, { [styles.withName]: withName })} key={wallet.address}>
          {withName && (
            <Typography
              variant="body2"
              className={cn(styles.keyColumn, styles.name)}
              component="div"
            >
              {wallet.wallet?.name ?? wallet.name}
            </Typography>
          )}
          <Typography
            variant="body2"
            className={cn(styles.keyColumn, styles.privateKey)}
            component="div"
          >
            {wallet.wallet?.private_key ?? wallet.private_key}
            <ButtonCopy
              value={wallet.wallet?.private_key ?? wallet.private_key}
              className={styles.copy}
            />
          </Typography>
        </div>,
      ])}
    />
  )
}

export { PrivateKeysTable }
