import {
  MarkConstColors,
  MarkCustomColor,
  ResolutionString,
} from '@/charting_library/charting_library'
import { ETxType } from '@/libs/enums'

type ResolutionMap = Record<string, string>

type NumberMap = Record<number, string>

const supportedResolutions = [
  // '1S',
  // '15S',
  '1',
  '5',
  '15',
  '60',
  '240',
  '720',
  '1D',
  '3D',
  '7D',
] as ResolutionString[]

const SHORT_INTERVALS = ['1S', '15S']
const CUSTOM_INTERVALS = ['3D']

const SUBSCRIPT_NUMBER_MAP: NumberMap = {
  4: '₄',
  5: '₅',
  6: '₆',
  7: '₇',
  8: '₈',
  9: '₉',
  10: '₁₀',
  11: '₁₁',
  12: '₁₂',
  13: '₁₃',
  14: '₁₄',
  15: '₁₅',
  16: '₁₆',
  17: '₁₇',
  18: '₁₈',
  19: '₁₉',
  20: '₂₀',
  21: '₂₁',
  22: '₂₂',
  23: '₂₃',
  24: '₂₄',
  25: '₂₅',
  26: '₂₆',
  27: '₂₇',
  28: '₂₈',
  29: '₂₉',
  30: '³₀',
  31: '³₁',
  32: '³₂',
  33: '³₃',
  34: '³₄',
  35: '³₅',
  36: '³₆',
  37: '³₇',
  38: '³₈',
  39: '³₉',
  40: '４₀',
  41: '４₁',
  42: '４₂',
}

const RESOLUTION_MAP: ResolutionMap = {
  //seconds
  '1S': 'r1_s',
  '15S': 'r15_s',
  //minutes
  '1': 'r1',
  '5': 'r5',
  '15': 'r15',
  //hours
  '60': 'r60',
  '240': 'r240',
  '720': 'r720',
  //days
  '1D': 'r1_d',
  '7D': 'r7_d',
}

const MARKS_CONFIG: Record<
  string,
  {
    color: MarkConstColors | MarkCustomColor
    textLabel: string
    label: string
  }
> = {
  [ETxType.SELL]: {
    color: 'red',
    textLabel: 'Sold',
    label: 'S',
  },
  [ETxType.BUY]: {
    color: 'green',
    textLabel: 'Bought',
    label: 'B',
  },
  [ETxType.DISPERSE]: {
    color: 'blue',
    textLabel: 'Transfered',
    label: 'T',
  },
  [ETxType.COLLECT]: {
    color: 'blue',
    textLabel: 'Collected',
    label: 'C',
  },
}

export {
  supportedResolutions,
  RESOLUTION_MAP,
  SUBSCRIPT_NUMBER_MAP,
  CUSTOM_INTERVALS,
  SHORT_INTERVALS,
  MARKS_CONFIG,
}
