import { FC } from 'react'

import { Grid } from '@mui/material'

import { Typography } from '@/libs/common/typography'

import { ButtonCopy } from '../button-copy'
import styles from './styles.module.scss'

type TProps = {
  title: string
  value: string
}

const ValueWithCopyAndTitle: FC<TProps> = ({ title, value }) => {
  return (
    <Grid width="100%">
      <Typography variant="body1" className={styles.title}>
        {title}
      </Typography>
      <Grid display="flex" alignItems="center" gap={0.5}>
        <Typography variant="h6">{value}</Typography>
        <ButtonCopy value={value} />
      </Grid>
    </Grid>
  )
}

export { ValueWithCopyAndTitle }
