import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { AppRoute } from '@/libs/enums'
import { useAppSelector } from '@/store'

const useCheckUser = () => {
  const navigate = useCustomNavigate()
  const userData = useAppSelector((state) => state.user.userData)
  const location = useLocation()

  return useCallback(
    (callback: any) => {
      if (!userData) {
        localStorage.setItem('cachedPath', location.search)
        navigate({
          path: `${AppRoute.MODAL}/${AppRoute.CONNECT_WALLET}`,
          isDashboard: true,
        })
        return
      } else {
        localStorage.removeItem('cachedPath')
      }
      if (callback) return callback()
    },
    [userData, navigate],
  )
}

export default useCheckUser
