import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { store } from '@/store'

import {
  TCreateProfitPanelPayload,
  TProfitPanel,
  TProfitSellDetails,
  TProfitSellItem,
} from './types'

const BASE_URL = 'api/v1/order/panel'

const getProfitPanel = (blockchain: number) => {
  const userId = getCurrentUserId()
  return api.get<TBackendResponse<TProfitPanel>>(`${BASE_URL}/${userId}/get?l=en&b=${blockchain}`)
}

const deleteProfitPanel = (panel: TActivePanelItem) => {
  const userId = getCurrentUserId()
  const blockchain = store.getState().chain.currentChain.indexerChainId
  return api.post(`${BASE_URL}/${userId}/disable?b=${blockchain}&l=en`, {
    ids: [panel.id],
    blockchain: blockchain,
  })
}

// Old endpoints

const getProfitLossForTokens = async () => api.get<TProfitSellItem[]>(`${BASE_URL}`)
const getProfitLossDetails = async (token: string) =>
  api.get<TProfitSellDetails>(`${BASE_URL}/seperate/${token}`)

const createProfitPanel = (body: TCreateProfitPanelPayload) => {
  const userId = getCurrentUserId()
  const blockchain = store.getState().chain.currentChain.indexerChainId
  return api.post(`${BASE_URL}/${userId}/create?&l=en`, {
    token: body.token_address,
    blockchain: blockchain,
  })
}

// const deleteProfitPanel = (panel: TProfitSellPanel) => api.patch(`${BASE_URL}/${panel._id}`, panel)

const setProfitPanelActivity = (panelId: string, isActive: boolean) =>
  api.patch(`${BASE_URL}/${panelId}`, { isActive })

export {
  getProfitPanel,
  getProfitLossForTokens,
  createProfitPanel,
  deleteProfitPanel,
  setProfitPanelActivity,
  getProfitLossDetails,
}
