import { FC, useEffect, useMemo, useState } from 'react'

import { Grid, Menu } from '@mui/material'
import cn from 'classnames'

import { getUserBalances } from '@/api/indexer'
import { CustomToast } from '@/components/custom-toast'
import { Spinner } from '@/components/spinner'
import { Icon, Typography } from '@/libs/common'
import { chainsConfig } from '@/libs/configs/chains.config'
import { IconName, SpinnerSize } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppSelector } from '@/store'

import { BridgeTokenSelectItem } from './libs/components/bridge-token-select-item'
import { TBridgeTokenItem } from './libs/types'
import styles from './styles.module.scss'

type TProps = {
  title: string
  selectedIndexerChainId: number
  selectChain: (chainId: number) => void
  selectedNetwork: TChainConfig
  excludeChainIds?: number[]
  onTokenSelect: (token: TBridgeTokenItem) => void
  onDataLoad?: (token: TBridgeTokenItem) => void
  disabled?: boolean
}

const BALANCES_FETCH_INTERVAL = 30000

const BridgeTokenSelect: FC<TProps> = ({
  title,
  selectedIndexerChainId,
  selectChain,
  excludeChainIds,
  selectedNetwork,
  onTokenSelect,
  onDataLoad,
  disabled,
}) => {
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedToken, setSelectedToken] = useState<TBridgeTokenItem | null>(null)
  const [availableTokens, setAvailableTokens] = useState<TBridgeTokenItem | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const availableChains = useMemo(
    () =>
      excludeChainIds?.length
        ? chainsConfig.filter((chain) => !excludeChainIds.includes(chain.indexerChainId))
        : chainsConfig,
    [excludeChainIds, selectedIndexerChainId],
  )

  const handleHoldingsForChain = async (chain: TChainConfig) => {
    try {
      const { data } = await getUserBalances(chain.indexerChainId)

      const totalBalance = data.data.reduce(
        (acc, item) => {
          acc.usd += +item.balance.usd
          acc.native += +item.balance.formatted
          return acc
        },
        { usd: 0, native: 0 },
      )

      return {
        iconName: chain.iconName,
        tokenSymbol: chain.chainSymbol,
        networkLabel: chain.networkLabel,
        // tokenAddress: chain.nativeTokenAddress,
        tokenAddress: '0x0000000000000000000000000000000000000000', // This placeholder is used for native tokens only
        indexerChainId: chain.indexerChainId,
        totalBalance,
        cost: data.data[0].balance.price,
        wallets: data.data,
      }
    } catch (err) {
      CustomToast('error', 'Error fetching balances')
      return null
    }
  }

  const handleChainSelect = async (chain: TChainConfig) => {
    if (isLoading || disabled || chain.indexerChainId === selectedToken?.indexerChainId) return

    setIsLoading(true)

    const newTokens = await handleHoldingsForChain(chain)

    if (newTokens) {
      setSelectedToken(newTokens)
      onDataLoad?.(newTokens)
      onTokenSelect(newTokens)
    }
    setAvailableTokens(newTokens)
    setIsLoading(false)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (isAppIdle) {
      return
    }

    handleChainSelect(selectedNetwork)
    const intervalId = setInterval(() => {
      handleChainSelect(selectedNetwork)
    }, BALANCES_FETCH_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [selectedNetwork.indexerChainId, isAppIdle])

  return (
    <div>
      <Grid gap={1} display="grid">
        <Typography variant="body1" className={styles.title}>
          {title}
        </Typography>

        <div
          className={styles.info}
          onClick={(e) => {
            if (disabled) return
            setAnchorEl(e.currentTarget)
          }}
        >
          {!!selectedToken && <BridgeTokenSelectItem tokenItem={selectedToken} onlyShowInfo />}
        </div>
      </Grid>

      <Menu
        classes={styles}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        transformOrigin={{
          vertical: -6,
          horizontal: 0,
        }}
      >
        <div className={styles.menuHeader}>
          <Grid display="flex" alignItems="center" gap={1}>
            {availableChains.map((chain) => (
              <div
                key={chain.indexerChainId}
                className={cn(styles.chainItem, {
                  [styles.active]: selectedIndexerChainId === chain.indexerChainId,
                })}
                onClick={() => {
                  if (selectedIndexerChainId !== chain.indexerChainId)
                    selectChain(chain.indexerChainId)
                }}
              >
                <Icon name={(IconName as any)[chain.iconName as any]} />
                <Typography variant="body2">{chain.chainSymbol}</Typography>
              </div>
            ))}
          </Grid>
        </div>

        {isLoading && (
          <div className={styles.loaderConteiner}>
            <Spinner size={SpinnerSize.SMALL} centered />
          </div>
        )}

        {!!availableTokens && !isLoading && (
          <BridgeTokenSelectItem
            tokenItem={availableTokens}
            onTokenSelect={(value) => {
              onClose()
              if (value.tokenAddress === selectedToken?.tokenAddress) {
                return
              }
              onTokenSelect(value)
            }}
            hideTokenAddress
          />
        )}
      </Menu>
    </div>
  )
}

export { BridgeTokenSelect }
