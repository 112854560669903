const checkForBraveBrowser = async () => {
  try {
    const isBrave = await (navigator as any).brave?.isBrave?.()
    return isBrave
  } catch (err) {
    return false
  }
}

export { checkForBraveBrowser }
