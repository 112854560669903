import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { checkForBraveBrowser } from '@/libs/helper'

type TInitialState = {
  isAppIdle: boolean
  isBrowserSupported: boolean
  browserInfo: {
    isBrave: boolean
  }
  isNavigateAvailable: boolean
  navigateTo: string | null
  showUserTradesOnChart: boolean
}

const initialState: TInitialState = {
  isAppIdle: false,
  isBrowserSupported: true, // This variable is not being changed right now (consider implementing such a feature)
  browserInfo: {
    isBrave: false,
  },
  isNavigateAvailable: true,
  navigateTo: null,
  showUserTradesOnChart: true,
}

const getBrowserInfo = createAsyncThunk('app/getBrowserInfo', async () => {
  const [isBrave] = await Promise.all([checkForBraveBrowser()])
  return { isBrave }
})

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppIdleState: (state, { payload }: PayloadAction<boolean>) => {
      state.isAppIdle = payload
    },
    setBrowserSupportedState: (state, { payload }: PayloadAction<boolean>) => {
      state.isBrowserSupported = payload
    },
    setIsNavigateAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isNavigateAvailable = payload
    },
    setNavigateTo: (state, { payload }: PayloadAction<string | null>) => {
      state.navigateTo = payload
    },
    setShowUserTradesOnChart: (state, { payload }: PayloadAction<boolean>) => {
      state.showUserTradesOnChart = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrowserInfo.fulfilled, (state, { payload }) => {
      state.browserInfo = payload
    })
  },
})

const {
  setAppIdleState,
  setBrowserSupportedState,
  setIsNavigateAvailable,
  setNavigateTo,
  setShowUserTradesOnChart,
} = appSlice.actions

export {
  appSlice,

  // Reducers
  setAppIdleState,
  setBrowserSupportedState,
  setIsNavigateAvailable,
  setNavigateTo,
  setShowUserTradesOnChart,

  // Thunks
  getBrowserInfo,
}
