import { ChainName, EManualSettingsSection } from '@/libs/enums'

import { TOptionalSnipeTemplateFields } from './types'

// Template has all fields with empty params
const SNIPE_TEMPLATE_CHAIN_SETTINGS: Record<ChainName, TOptionalSnipeTemplateFields> = {
  [ChainName.ETHEREUM]: {
    [EManualSettingsSection.BUY]: {
      buyWithSimulation: false,
    },
  },
  [ChainName.BNB]: {
    [EManualSettingsSection.BUY]: {
      firstBundleBuy: false,
      firstBundleOrFail: false,
      backupBundle: false,
    },
    [EManualSettingsSection.SAFETY]: {},
    [EManualSettingsSection.SELL]: {},
  },
  [ChainName.AVAX]: {},
  [ChainName.ARBITRUM]: {},
  [ChainName.BASE]: {},
}

export { SNIPE_TEMPLATE_CHAIN_SETTINGS }
