import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Grid } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Icon,
  Input,
  InputWithRadioGroup,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TSnipeTemplate } from '@/libs/types/snipe.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const limitOptions: TSelectOption<number>[] = [
  {
    value: -10,
    label: '-10%',
  },
  {
    value: -20,
    label: '-20%',
  },
  {
    value: -30,
    label: '-30%',
  },
  {
    value: -40,
    label: '-40%',
  },
]

const defaultValues = {
  slippage: 0,
  buyPriority1: '',
  selectedWallets: [],
  //advanced settings
  buyPriority2: '',
  approvePriority: '',
  maxTxOrFail: false,
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  dip: '',
  marketcap: '',
  price: '',
  triggerPricePercent: '',
  expiration: '',
  spend: '',
  receive: '',
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]
const mockTemplates: TSnipeTemplate[] = [
  {
    _id: '1',
    name: 'Manual',
  },
  {
    _id: '2',
    name: 'Fast Buy',
  },
  {
    _id: '3',
    name: 'Max profit',
  },
  {
    _id: '4',
    name: 'Quick trade',
  },
]

const EditLimitBuy = () => {
  const { token } = useParams()
  const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  const [currentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  const [footerTab, setFooterTab] = useState<string>('market')
  const { control, handleSubmit } = useForm({ defaultValues })
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { iconName, chainSymbol } = currentChain

  const customizeTemplate = () => {
    // Implement template customization here
  }

  const handleFooterTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setFooterTab(newValue)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }
  return (
    <div className={styles.container}>
      <Typography variant="h1" textColor="light-grey" textAlign="center">
        Edit {token} Limit Buy settings
      </Typography>
      <Grid>
        <TemplatesControl
          templates={templates}
          currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
          onSelectTemplate={(id) => id}
          onCustomize={customizeTemplate}
          customizeDisabled={currentTemplate?._id === '1'}
          editDisabled={currentTemplate?._id === '1'}
          deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate._id)}
        />
      </Grid>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowGap={3}>
          {currentTemplate === mockTemplates[0] && (
            <>
              <Accordion
                className={styles.accordion}
                titleClassName={styles.accordionTitle}
                title="Advanced Manual Buy Settings"
                withDivider
                mainColorArrow
              >
                <Grid container rowGap={3} flexDirection="column" marginTop={1} marginBottom={3}>
                  <Grid container rowGap={2}>
                    <Typography variant="body2" textColor="white">
                      Buy
                    </Typography>
                    <Controller
                      name="buyPriority2"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label="Buy Priority"
                          placeholder="Enter gwei amount"
                          isNumeric
                          tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                          onAutoChange={() => {
                            //TO DO: added auto switch
                          }}
                          endAdornment={
                            <EndAdornment
                              label="GWEI"
                              icon={(IconName as any)[currentChain.iconName]}
                            />
                          }
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="approvePriority"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label="Approve priority"
                          placeholder="Enter gwei amount"
                          isNumeric
                          tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                          endAdornment={
                            <EndAdornment
                              label="GWEI"
                              icon={(IconName as any)[currentChain.iconName]}
                            />
                          }
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="maxTxOrFail"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Max Tx or fail"
                          tooltipInfo="Will try to buy maximum available amount of tokens in one transaction. The transaction will fail if this cannot be done."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="white">
                      Safety
                    </Typography>

                    <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="transferOnBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Transfer On Blacklist"
                            tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="antiBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Anti-Blacklist"
                            tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAntiRugSettings}
                      trigger={
                        <Controller
                          name="antiRug"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label="Anti-Rug"
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAntiRugSettings(checked)
                                onChange(checked)
                              }}
                              {...field}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2}>
                        <Controller
                          name="antiRugThreshold"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label="Anti-Rug Threshold"
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="sellRugExtraTip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Amount"
                              isNumeric
                              label="Sell Rug Extra Tip"
                              tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </HiddenInputs>

                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="maxBuyTax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter percentage"
                            isNumeric
                            label="Max Buy Tax"
                            adornmentText="%"
                            tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="maxSellTax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter percentage"
                            isNumeric
                            label="Max Sell Tax"
                            adornmentText="%"
                            tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="minLiquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter min liquidity amount"
                            isNumeric
                            label="Min Liquidity"
                            adornmentText="$"
                            tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="maxLiquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter max liquidity amount"
                            isNumeric
                            label="Max Liquidity"
                            adornmentText="$"
                            tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Controller
                      name="maxMarketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter max marketcap"
                          isNumeric
                          label="Max Marketcap"
                          adornmentText="$"
                          tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Accordion>

              <Controller
                name="slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label="Slippage"
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                    placeholder="X"
                    {...field}
                  />
                )}
              />

              <Controller
                name="buyPriority1"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label="Buy Priority"
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                    endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                    {...field}
                  />
                )}
              />

              <Controller
                name="selectedWallets"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <SelectWallet tooltipInfo="tooltipInfo" isMulti wallet={value} {...field} />
                )}
              />
            </>
          )}

          <Grid container rowGap={2} flexDirection="column">
            <ButtonGroupRadio
              onChange={handleFooterTabChange}
              value={footerTab}
              exclusive
              className={cls(styles.group, styles.bgGrey)}
            >
              <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
            </ButtonGroupRadio>
            {footerTab === 'limit' && (
              <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                <div>
                  <Typography variant="body2" textColor="light-grey">
                    Trigger price
                  </Typography>
                  <div className={styles.inputWrapper}>
                    <Controller
                      name="dip"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.first)}
                          placeholder="Enter dip %"
                          endAdornment={<EndAdornment label="%" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="marketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={styles.input}
                          placeholder="Enter marketcap"
                          endAdornment={<EndAdornment label="MC" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.last)}
                          placeholder="Enter price"
                          endAdornment={<EndAdornment label="$" />}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="triggerPricePercent"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <ButtonGroupRadio
                        className={styles.radioGroup}
                        onChange={(_, value: string) => onChange(value)}
                        exclusive
                        {...field}
                      >
                        {limitOptions.map((option) => (
                          <ButtonGroupRadioButton
                            value={option.value}
                            className={styles.radioButton}
                            key={option.value}
                          >
                            {option.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>
                    )}
                  />
                </div>

                <Controller
                  name="expiration"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label="Expiration"
                      tooltipInfo="Enter the number of hours the limit order is valid. After this time expires, it will be removed."
                      className={styles.input}
                      placeholder="Enter the position expiration time in hours"
                      adornmentText="Hours"
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <div className={styles.footer}>
            <div className={styles.arrow}>
              <Icon name={IconName.ARROW_DOWN_GREY} />
            </div>

            <div className={styles.container}>
              <div className={styles.spend}>
                <Controller
                  name="spend"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithRadioGroup
                      label="Spend"
                      placeholder="Enter amount to spend"
                      isNumeric
                      className={styles.input}
                      onOptionSelect={(value) => field.onChange(value)}
                      endAdornment={
                        <EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />
                      }
                      radioGroupName="amount"
                      options={spendOptions}
                      {...field}
                    />
                  )}
                />
                <div className={styles.available}>
                  <Typography variant="body2" className={styles.param}>
                    Available:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    13.003454 ETH
                  </Typography>
                </div>
              </div>

              <div className={styles.union}>
                <Controller
                  name="receive"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Input
                      label="Receive"
                      placeholder="Enter amount to receive"
                      isNumeric
                      className={styles.input}
                      endAdornment={<EndAdornment label="PEPE" icon={IconName.PEPE} />}
                      {...field}
                    />
                  )}
                />
                <div className={cls(styles.infoContainer)}>
                  <div className={styles.info}>
                    <Typography variant="body2" className={styles.param}>
                      DEX:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      UNI V2
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <Button type="submit">Save</Button>
          </div>
        </Grid>
      </form>
    </div>
  )
}

export { EditLimitBuy }
