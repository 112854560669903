import { FC, useEffect } from 'react'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook.ts'
import { ListButton } from '@/libs/common'
import { chainsConfig } from '@/libs/configs/chains.config'
import { IconName } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'
import { fetchUserBuyTemplates } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

type TProperty = { test?: string }

const SwitchChain: FC<TProperty> = () => {
  const dispatch = useAppDispatch()
  const handleNewTokenSelection = useSelectNewToken()
  const navigate = useCustomNavigate()
  const { setModalProps } = useModal()

  const onChange = (newChain: TChainConfig) => {
    dispatch(setCurrentChain(newChain))
    handleNewTokenSelection(newChain.defaultToken, {
      customChain: newChain.description.toLowerCase(),
    })
    dispatch(fetchUserBuyTemplates())
    navigate({
      isDashboard: true,
      newChain: newChain.description.toLowerCase(),
      searchParams: {
        token: newChain.defaultToken,
      },
    })
  }

  useEffect(() => {
    setModalProps({
      title: 'Switch Chain',
    })
  }, [])

  return (
    <div className={styles.switchChainContainer}>
      <div className={styles.chainsList}>
        {chainsConfig.map((chain) => (
          <ListButton
            iconName={(IconName as any)[chain.iconName]}
            title={chain.label}
            description={chain.description}
            onClick={() => {
              onChange(chain)
            }}
            key={chain.iconName}
          />
        ))}
      </div>
    </div>
  )
}

export { SwitchChain }
