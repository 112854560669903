import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice.tsx'
import { useModal } from '@/pages/modal-page/modal-page.tsx'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const ApproveSell = () => {
  const { setModalProps } = useModal()
  const location = useLocation()
  const navigate = useCustomNavigate()
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const data = location.state?.data || null

  useEffect(() => {
    setModalProps({ title: 'Confirm sell transaction' })
  }, [])

  const sell = async () => {
    await handleSell(location.state?.payload)
    navigate({ isDashboard: true })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon name={IconName.APPROVE_BIG} />
      </div>
      <div className={styles.message}>
        <Typography variant="body2" textColor="color-grey-2">
          Are you sure you want to sell
        </Typography>
        <div className={styles.tokenInfo}>
          <Typography variant="body2" textColor="color-grey-3" className={styles.message}>
            {data ? formatTokenPrice(data.sell).formatted : 0}{' '}
            {data?.tokenName || currentToken?.token.name}
          </Typography>{' '}
          <Typography variant="body2" textColor="color-grey-2" className={styles.message}>
            for
          </Typography>{' '}
          <Typography variant="body2" textColor="color-grey-3" className={styles.message}>
            {data ? formatTokenPrice(data.receive).formatted : 0} ETH.
          </Typography>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button styleVariant="black" onClick={() => navigate({ delta: -1 })}>
          <Typography variant="body2" textColor="white" className={styles.message}>
            Cancel
          </Typography>
        </Button>

        <Button styleVariant="yellow" onClick={sell}>
          <Typography variant="body2" textColor="black" className={styles.message}>
            Sell
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export { ApproveSell }
