import { FC, PropsWithChildren, ReactNode } from 'react'

import { Grid } from '@mui/material'

import { TAffiliatesValueItem } from '@/api/affiliate/types'
import { Accordion, Typography } from '@/libs/common'
import { EAffiliateSnapshotStatus } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'

import { StatusIndicator } from '../status-indicator'
import styles from './styles.module.scss'

type TProps = PropsWithChildren & {
  title: string | ReactNode
  earnings: TAffiliatesValueItem
  status?: EAffiliateSnapshotStatus
}

const AffiliatesAccordion: FC<TProps> = ({ title, earnings, status, children }) => {
  return (
    <div className={styles.accordionContainer}>
      <Accordion
        title={
          <>
            <Grid display="flex" alignItems="center" gap={1}>
              {title}
              {!!status && <StatusIndicator status={status} />}
            </Grid>
            <Typography variant="body1" className={styles.accordionSubtitle}>
              Total Earnings: {formatNumber(earnings.native).formatted} (≈$
              {formatNumber(earnings.usd).formatted})
            </Typography>
          </>
        }
        className={styles.accordion}
        titleClassName={styles.accordionTitle}
        contentClassName={styles.accordionContent}
        withDivider
        mainColorArrow
      >
        {children}
      </Accordion>
    </div>
  )
}

export { AffiliatesAccordion }
