enum EBuyMode {
  CHAIN_CURRENCY = 0,
  MAX_TRANSACTION = 1,
  MIN_PERCENT = 5,
  MIN_TOKENS = 2,
  MAX_PERCENT = 100,
  MAX_LIQUIDITY = 100_000_000,
  MAX_MARKET_CAP = 10_000_000_000,
}

export { EBuyMode }
