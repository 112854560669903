import * as yup from 'yup'

const stringOfNumbersValidation = yup
  .string()
  .trim()
  .matches(/^$|^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?%?$/)

const networkFeesValidation = yup.object({
  type: yup.string(),
  minerTip: yup
    .number()
    .required()
    .test((value) => +value > 0),
})

export { stringOfNumbersValidation, networkFeesValidation }
