import { Button } from '@/libs/common'
import { ModalComponent } from '@/libs/common/modal-component'
import { useAppSelector } from '@/store'

const IdleWarningModal = () => {
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  return (
    <ModalComponent
      open={isAppIdle}
      name="idle-modal"
      title="You Have Been Disconnected"
      message="You have been inactive for 30 minutes, so your app has been disconnected. Please reload the page to reconnect."
    >
      <Button onClick={() => location.reload()}>Reload</Button>
    </ModalComponent>
  )
}

export { IdleWarningModal }
