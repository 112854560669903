import { useEffect } from 'react'

import { Grid } from '@mui/material'

import { ProfitSellTab } from '@/components/profit-sell-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const ProfitModal = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Positions', titleProps: { marginBottom: 3 } })
  }, [])

  return (
    <Grid>
      <ProfitSellTab usedInModal />
    </Grid>
  )
}

export { ProfitModal }
