import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { TableBody } from '@mui/material'
import moment from 'moment'

import { TAffiliateUserTransaction, TAffiliatesValueItem } from '@/api/affiliate/types'
import { Icon } from '@/libs/common'
import {
  CustomTable,
  CustomTableCell,
  CustomTableContainer,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableRow,
} from '@/libs/common/custom-table'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { store, useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  transactions: TAffiliateUserTransaction[]
}

const AffiliateUserData: FC<TProps> = ({ transactions }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const nativeTokeSymbol = currentChain.chainSymbol

  const renderPriceText = useCallback(
    (item: TAffiliatesValueItem) => {
      return (
        <>
          {formatNumber(item.native).formatted} {nativeTokeSymbol}{' '}
          <span className={styles.usdPrice}>
            (≈$
            {formatNumber(item.usd).formatted})
          </span>
        </>
      )
    },
    [currentChain],
  )

  return (
    <CustomTableContainer dataExists={!!transactions.length}>
      <CustomTable>
        <CustomTableHead>
          <CustomTableHeadCell>Date</CustomTableHeadCell>
          <CustomTableHeadCell>Tier</CustomTableHeadCell>
          <CustomTableHeadCell>Level</CustomTableHeadCell>
          <CustomTableHeadCell>%</CustomTableHeadCell>
          <CustomTableHeadCell>Volume</CustomTableHeadCell>
          <CustomTableHeadCell>Type</CustomTableHeadCell>
          <CustomTableHeadCell>Fees Collected</CustomTableHeadCell>
          <CustomTableHeadCell>Your Earnings</CustomTableHeadCell>
          <CustomTableHeadCell>TX</CustomTableHeadCell>
        </CustomTableHead>

        <TableBody>
          {transactions.map((tx) => (
            <CustomTableRow>
              <CustomTableCell nowrap>{moment(tx.date).format('MMM D, HH:MM:SS')}</CustomTableCell>
              <CustomTableCell>{tx.tier}</CustomTableCell>
              <CustomTableCell>{tx.level}</CustomTableCell>
              <CustomTableCell>{tx.percentage}%</CustomTableCell>
              <CustomTableCell nowrap>{renderPriceText(tx.volume)}</CustomTableCell>
              <CustomTableCell className={styles.typeCell}>{tx.kind}</CustomTableCell>
              <CustomTableCell nowrap>{renderPriceText(tx.collected_fees)}</CustomTableCell>
              <CustomTableCell nowrap>{renderPriceText(tx.earned_fees)}</CustomTableCell>
              <CustomTableCell>
                <Link
                  to={`${store.getState().chain.currentChain.explorer}/tx/${tx.hash}`}
                  target="_blank"
                >
                  <Icon name={IconName.ETHERSCAN} />
                </Link>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </CustomTable>
    </CustomTableContainer>
  )
}

export { AffiliateUserData }
