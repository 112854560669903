import { FC } from 'react'

import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Typography } from '@/libs/common'
import { SpinnerSize } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

import { TokenDataItem } from './libs/components/token-data-item'
import styles from './styles.module.scss'

// @TODO delete mock data
// const mockedData = {
//   preLaunchActive: 11256,
//   profitSellPanelActive: Math.floor(Math.random() * 5000),
//   activeTemplatesPreLaunch: 11256,
//   totalWalletsTryingToSnipe: Math.floor(Math.random() * 1000),
//   freshWallets: Math.floor(Math.random() * 100),
//   alphaWallets: Math.floor(Math.random() * 50),
// }

type TProperty = {
  isDefaultToken: boolean
}

const TokenData: FC<TProperty> = ({ isDefaultToken }) => {
  const windowDimension = useWindowDimensions()
  const useSlider = windowDimension.width <= 630
  const isResponsive = !useSlider && windowDimension.width <= 1500
  const isMobile = !useSlider && windowDimension.width <= 980
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.mobileGradient, { [styles.visible]: windowDimension.width <= 630 })}
      />
      <div className={styles.tokenDataWrapper}>
        {!simulation || (isDefaultToken && !currentToken) ? (
          <div className={styles.spinnerWrapper}>
            <Spinner size={SpinnerSize.SMALL} centered />
          </div>
        ) : simulation ? (
          <>
            <TokenDataItem isUpgradePlan title="Dex:" value={simulation.l.dn} />
            <TokenDataItem
              isUpgradePlan
              title="Total Supply:"
              value={
                <>
                  {simulation?.t.ts === '-' || simulation.t.ts == '0'
                    ? '-'
                    : `${formatNumber(simulation?.t.ts).raw} ${simulation.t.s}`}
                </>
              }
            />
            {/*<TokenDataItem*/}
            {/*  isUpgradePlan*/}
            {/*  title="Vol 24h:"*/}
            {/*  value={<>${formatNumber(+(simulation?.additional_data?.volume || '0')).formatted}</>}*/}
            {/*/>*/}
            <TokenDataItem
              isUpgradePlan
              title="Contract Verified:"
              value={simulation?.t.t ? 'Yes' : 'No'}
            />
            {/* {!isMobile && (
              <TokenDataItem isUpgradePlan title="Top 10 holders:" value={simulation?.t.ho} />
            )} */}
            {!isMobile && (
              <TokenDataItem isUpgradePlan title="Holder Count:" value={simulation?.t.ho} />
            )}
            {/* {!isMobile && (
              <TokenDataItem isUpgradePlan title="Owner Supply:" value={`${simulation?.oh}%`} />
            )} */}

            {!isResponsive && (
              <TokenDataItem isUpgradePlan title="Burned Tokens:" value={`${simulation?.t.b.p}%`} />
            )}

            {/* <TokenDataItem
      title="Pre-launch Active: "
      value={mockedData.preLaunchActive}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Profit/Sell Panel Active: "
      value={mockedData.profitSellPanelActive}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Active Templates Prelaunch: "
      value={mockedData.activeTemplatesPreLaunch}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Total wallets trying to snipe this: "
      value={mockedData.totalWalletsTryingToSnipe}
    />

    <TokenDataItem title="Fresh Wallets: " value={mockedData.freshWallets} />

    <TokenDataItem title="Alpha Wallets: " value={mockedData.alphaWallets} /> */}
          </>
        ) : (
          <div className={styles.spinnerWrapper}>
            <Typography variant="body1" align="center">
              Current token simulation is not available
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export { TokenData }
