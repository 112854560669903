import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Grid } from '@mui/material'

import { FilterRadio, InputWithRadioGroup, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import { EBridgeInputMode } from '../../enums'
import styles from './styles.module.scss'

const options: Record<EBridgeInputMode, TSelectOption<number>[]> = {
  [EBridgeInputMode.PERCENTAGE]: [
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  [EBridgeInputMode.AMOUNT]: [
    {
      value: 0.25,
      label: '0.25',
    },
    {
      value: 0.5,
      label: '0.5',
    },
    {
      value: 0.75,
      label: '0.75',
    },
    {
      value: 1,
      label: '1',
    },
  ],
}

const inputModes: TSelectOption<EBridgeInputMode>[] = [
  {
    value: EBridgeInputMode.AMOUNT,
    label: '#',
  },
  {
    value: EBridgeInputMode.PERCENTAGE,
    label: '%',
  },
]

type TProps = {
  name: string
  label: string
  placeholder: string
  withAvailableTokens?: boolean
  availableTokens?: number
  availableTokensInUsd?: number
  tokenSymbol?: string
  tokenIcon?: string
  withoutOptions?: boolean
  withPercentageMode?: boolean
  onChange?: (value: number, isPercentage: boolean) => void
  isValid?: boolean
  disabled?: boolean
}

const BridgeAmountInput = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      availableTokens = 0,
      availableTokensInUsd = 0,
      tokenSymbol,
      tokenIcon,
      withoutOptions,
      withPercentageMode,
      onChange: onChangeCb,
      isValid = true,
      disabled,
      withAvailableTokens = false,
    }: TProps,
    ref,
  ) => {
    const currentChain = useAppSelector((state) => state.chain.currentChain)

    const [inputMode, setInputMode] = useState(EBridgeInputMode.AMOUNT)

    const {
      control,
      formState: { errors },
    } = useFormContext<any>()

    const calculateTokensForChangeCallback = (value: number, customMode = inputMode) => {
      if (!onChangeCb) return

      let newValue = value || 0
      if (customMode === EBridgeInputMode.PERCENTAGE) {
        newValue = (value / 100) * availableTokens
      }
      onChangeCb(newValue, customMode === EBridgeInputMode.PERCENTAGE)
    }

    const currentSymbol = tokenSymbol || currentChain.chainSymbol
    const currentIcon = (IconName as any)[tokenIcon || currentChain.iconName]

    useImperativeHandle(ref, () => ({
      setInputMode,
      getInputMode: () => inputMode,
    }))

    return (
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { ref, onChange, value, ...field } }) => {
            return (
              <InputWithRadioGroup
                label={label}
                placeholder={placeholder}
                isNumeric
                labelClassName={styles.label}
                onOptionSelect={(newOption) => {
                  onChange(convertScientificNotationNumber(+newOption))
                  calculateTokensForChangeCallback(+newOption)
                }}
                endAdornment={
                  <Grid display="flex" alignItems="center" columnGap={1}>
                    {withPercentageMode && (
                      <FilterRadio
                        value={inputMode}
                        setValue={(newMode) => {
                          calculateTokensForChangeCallback(+value, newMode)
                          setInputMode(newMode)
                        }}
                        options={inputModes}
                      />
                    )}
                    <EndAdornment label={currentSymbol} icon={currentIcon} />
                  </Grid>
                }
                radioGroupName="amount"
                options={withoutOptions ? null : options[inputMode]}
                onChange={(e) => {
                  onChange(e)
                  calculateTokensForChangeCallback(+e.target.value)
                }}
                error={!!errors[name]?.message || !isValid}
                value={value}
                valueForRadio={+value}
                disabled={disabled}
                {...field}
              />
            )
          }}
        />
        {withAvailableTokens && (
          <div className={styles.available}>
            <Typography variant="body2" className={styles.param}>
              Available:
            </Typography>
            <Typography variant="body2">
              {availableTokens ? (
                <>
                  {formatNumber(availableTokens).formatted} {currentSymbol}
                  {!!availableTokensInUsd && (
                    <> (~${formatNumber(availableTokensInUsd).formatted})</>
                  )}
                </>
              ) : (
                '-'
              )}
            </Typography>
          </div>
        )}
      </div>
    )
  },
)

export { BridgeAmountInput }
