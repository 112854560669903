import { ChainName, EManualSettingsSection } from '@/libs/enums'

import { TOptionalCopyTradeFields } from './types'

// Template has all fields with empty params
const COPYTRADE_CHAIN_SETTINGS: Record<ChainName, TOptionalCopyTradeFields> = {
  [ChainName.ETHEREUM]: {},
  [ChainName.BNB]: {
    [EManualSettingsSection.BUY]: {
      antiMev: false,
      buyAmount: false,
    },
    [EManualSettingsSection.SAFETY]: {},
    [EManualSettingsSection.SELL]: {},
  },
  [ChainName.AVAX]: {},
  [ChainName.ARBITRUM]: {},
  [ChainName.BASE]: {},
}

const defaultFormValues = {
  privateTransaction: false,
  degenChadMode: false,
  frontrunBuys: false,
  backrunBuys: false,
  frontrunBuyPriority: '',
  frontrunSells: false,
  frontrunSellPriority: '',
  copySells: false,
  slippage: 0,
  maxTxOrFail: true,
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: true,
  sellPriority: '',
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  amount: '',
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  mempoolBackupBuy: '',
  minPercentForFail: '',
  sellOnProfit: '',
  sellOnProfitAmount: '',
  trailingSell: '',
}

export { COPYTRADE_CHAIN_SETTINGS, defaultFormValues }
