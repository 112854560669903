import { useMemo } from 'react'

import { useAppSelector } from '@/store'

const useCurrentTokenInfoLoading = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentTokenSimulationWebsocket = useAppSelector(
    (state) => state.chain.currentTokenSimulationWebsocket,
  )

  return useMemo(() => {
    return (
      selectedTokenAddress !== currentToken?.token.address.toLowerCase() ||
      selectedTokenAddress !== currentTokenSimulationWebsocket?.t.a.toLowerCase()
    )
  }, [selectedTokenAddress, currentToken, currentTokenSimulationWebsocket])
}

export { useCurrentTokenInfoLoading }
