import { FC, PropsWithChildren } from 'react'

import { TableHead, TableHeadProps, TableRow, TableRowProps } from '@mui/material'
import cn from 'classnames'

import styles from '@/libs/common/custom-table/styles.module.scss'

type TProps = PropsWithChildren & {
  headProps?: TableHeadProps
  rowProps?: TableRowProps
  headClassName?: string
  rowClassName?: string
}

const CustomTableHead: FC<TProps> = ({
  headProps,
  rowProps,
  headClassName,
  rowClassName,
  children,
}) => {
  return (
    <TableHead {...headProps} className={headClassName}>
      <TableRow className={cn(styles.headRow, rowClassName)} {...rowProps}>
        {children}
      </TableRow>
    </TableHead>
  )
}

export { CustomTableHead }
