import { Grid } from '@mui/material'

import { SettingField } from '@/components/setting-field'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const mockData = {
  buy: {
    amount: '0.1 ETH',
    frontrunPriority: '20 GWEI',
    privateTransaction: true,
    walletsSelected: '5/10',
    approvePriority: '20 GWEI',
    frontrunBuys: true,
    backrunBuys: true,
    slippage: 'Unlimited',
    buyPriority: '20 GWEI',
    degenChadMode: true,
  },
  safety: {
    antiRug: true,
    sellRugExtraTip: '20 GWEI',
    maxBuyTax: '30%',
    minLiquidity: '1000 USD',
    maxMcap: '30000 USD',
    antiRugThreshold: '30%',
    antiBlacklist: false,
    maxSellTax: '30%',
    maxLiquidity: '3000 USD',
    transferOnBlacklist: true,
  },
  sell: {
    copySells: true,
    frontrunSellPriority: '20 GWEI',
    autoSell: true,
    sellProfitAmount: '100%',
    walletsToSellOnLoss: '3/10',
    trailingSell: '20%',
    frontrunSells: true,
    sellPriority: '20 GWEI',
    sellOnProfit: '100%',
    walletsToSellOnProfit: '3/10',
    sellInSteps: ['10x-2', '20x-2', '50x-1'],
    sellOnLoss: '-50%',
  },
}

const CopyTrade = () => {
  const navigate = useCustomNavigate()

  const handleOpenCopyTradeSettings = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.COPY_TRADE_SETTINGS}`,
      searchParams: {
        action: 'edit-copytrade',
      },
    })
  }

  return (
    <Grid className={styles.wrapper}>
      <Typography variant="h1" align="center">
        Copycat
      </Typography>

      <div className={styles.header}>
        <Typography variant="h2" className={styles.subtitle}>
          Copy trade settings
        </Typography>

        <Button
          className={styles.headerButton}
          styleVariant="borderless-gold"
          iconStartName={IconName.EDIT}
          transparent
          onClick={handleOpenCopyTradeSettings}
        >
          Edit
        </Button>
      </div>

      <Grid container flexDirection="column" gap={1.5}>
        <Typography variant="body2" textColor="white">
          Buy
        </Typography>

        <Grid container gap={3}>
          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Amount" value={mockData.buy.amount} />

            <SettingField title="Frontrun Priority" value={mockData.buy.frontrunPriority} />

            <SettingField title="Anti-Mev" value={mockData.buy.privateTransaction} isGreen />

            <SettingField title="Wallets selected" value={mockData.buy.walletsSelected} />

            <SettingField title="Approve Priority" value={mockData.buy.approvePriority} />
          </Grid>

          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Frontrun Buys" value={mockData.buy.frontrunBuys} isGreen />

            <SettingField title="Backrun Buys" value={mockData.buy.backrunBuys} isGreen />

            <SettingField title="Slippage" value={mockData.buy.slippage} />

            <SettingField title="Buy Priority" value={mockData.buy.buyPriority} />

            <SettingField title="Degen Chad Mode" value={mockData.buy.degenChadMode} isGreen />
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" gap={1.5}>
        <Typography variant="body2" textColor="white">
          Safety
        </Typography>

        <Grid container gap={3}>
          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Anti-Rug" value={mockData.safety.antiRug} isGreen />

            <SettingField title="Sell Rug Extra Tip" value={mockData.safety.sellRugExtraTip} />

            <SettingField title="Max Buy Tax" value={mockData.safety.maxBuyTax} />

            <SettingField title="Min Liquidity" value={mockData.safety.minLiquidity} />

            <SettingField title="Max Mcap" value={mockData.safety.maxMcap} />
          </Grid>

          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Anti-Rug Threshold" value={mockData.safety.antiRugThreshold} />

            <SettingField title="Anti Blacklist" value={mockData.safety.antiBlacklist} isGreen />

            <SettingField title="Max Sell Tax" value={mockData.safety.maxSellTax} />

            <SettingField title="Max Liquidity" value={mockData.safety.maxLiquidity} />

            <SettingField
              title="Transfer on blacklist:"
              value={mockData.safety.transferOnBlacklist}
              isGreen
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" gap={1.5}>
        <Typography variant="body2" textColor="white">
          Sell
        </Typography>

        <Grid container gap={3}>
          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Copy Sells" value={mockData.sell.copySells} isGreen />

            <SettingField
              title="Frontrun Sell Priority"
              value={mockData.sell.frontrunSellPriority}
            />

            <SettingField title="Auto Sell" value={mockData.sell.autoSell} isGreen />

            <SettingField title="Sell Profit Amount" value={mockData.sell.sellProfitAmount} />

            <SettingField
              title="Wallets to sell on Loss"
              value={mockData.sell.walletsToSellOnLoss}
            />

            <SettingField title="Trailing sell" value={mockData.sell.trailingSell} />
          </Grid>

          <Grid width="47.9%" container flexDirection="column" gap={1}>
            <SettingField title="Frontrun Sells" value={mockData.sell.frontrunSells} isGreen />

            <SettingField title="Sell Priority" value={mockData.sell.sellPriority} />

            <SettingField title="Sell on Profit" value={mockData.sell.sellOnProfit} />

            <SettingField
              title="Wallets to sell on Profit"
              value={mockData.sell.walletsToSellOnProfit}
            />

            <SettingField title="Sell in Steps" value={mockData.sell.sellInSteps} />

            <SettingField title="Sell on Loss" value={mockData.sell.sellOnLoss} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { CopyTrade }
