import { ComponentProps, FC } from 'react'

import cls from 'classnames'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ButtonIcon } from '@/libs/common/button-icon'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = {
  isAbsolute?: boolean
  customNavigate?: () => void
}

const BackButton: FC<ComponentProps<'button'> & TProperty> = ({
  className,
  isAbsolute,
  customNavigate,
  ...props
}) => {
  const navigate = useCustomNavigate()
  const onClick = () => {
    if (customNavigate) {
      customNavigate()
      return
    }
    navigate({ delta: -1 })
  }
  return (
    <ButtonIcon
      icon={IconName.ARROW_RIGHT}
      onClick={onClick}
      className={cls({ [styles.absolute]: isAbsolute }, className)}
      {...props}
    />
  )
}

export { BackButton }
