import { FC } from 'react'
import { useParams } from 'react-router-dom'

import cls from 'classnames'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, LocalStorageItem } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const SwitchVariant: FC = () => {
  const navigate = useCustomNavigate()
  const isNavigateAvailable = useAppSelector((state) => state.app.isNavigateAvailable)
  const { mode: currentAppMode } = useParams()
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const handleChangeVariant = (_: React.BaseSyntheticEvent, newValue: string) => {
    if (isNavigateAvailable && newValue !== currentAppMode) {
      //dispatch(setIsNavigateAvailable(false))
      localStorage.setItem(LocalStorageItem.MODE, newValue)
      localStorage.removeItem(LocalStorageItem.ACTIVE_PANEL_TAB)
      navigate({
        isDashboard: true,
        newMode: newValue,
        searchParams: {
          token: currentToken?.token.address ?? currentChain.defaultToken ?? DEFAULT_TOKEN,
        },
      })
    }
  }

  return (
    <ButtonGroupRadio
      onChange={handleChangeVariant}
      disabled={!isNavigateAvailable}
      value={currentAppMode}
      exclusive
      className={styles.group}
    >
      <ButtonGroupRadioButton value={AppMode.LITE}>
        <Typography
          variant="body2"
          textColor="color-grey-3"
          className={cls(currentAppMode === AppMode.LITE && styles.active)}
        >
          Lite
        </Typography>
      </ButtonGroupRadioButton>
      <ButtonGroupRadioButton value={AppMode.PRO}>
        <Typography
          variant="body2"
          textColor="color-grey-3"
          className={cls(currentAppMode === AppMode.PRO && styles.active)}
        >
          Pro
        </Typography>
      </ButtonGroupRadioButton>
    </ButtonGroupRadio>
  )
}

export { SwitchVariant }
