import { useEffect, useState } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { deleteDevice, getDevicesList } from '@/api/device'
import DeleteConfirmationDevice from '@/components/user-settings/libs/components/active-devices-table/delete-confirmation-device.tsx'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { type TOnChangeVisible, Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

interface Column {
  id: 'linked' | 'deviceType' | 'deviceName' | 'deviceId' | 'actionButtons'
  label: string
  minWidth?: number
  align?: 'right' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'deviceType', label: 'Device Type', minWidth: 230 },
  { id: 'deviceName', label: 'Device Name', minWidth: 230 },
  { id: 'deviceId', label: 'Device ID', minWidth: 430 },
  {
    id: 'actionButtons',
    label: '',
    minWidth: 40,
    format: (value: number) => value.toLocaleString('en-US'),
  },
]

interface Data {
  deviceType: string
  deviceName: string
  deviceId: string
  actionButtons: string
}

function createData(
  deviceType: string,
  deviceName: string,
  deviceId: string,
  actionButtons: string,
): Data {
  return { deviceType, deviceName, deviceId, actionButtons }
}

const ActiveDevicesTable = () => {
  const [rows, setRows] = useState<any>([])
  const checkUserAndExecute = useCheckUser()
  const userData = useAppSelector((state) => state.user.userData)
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  useEffect(() => {
    checkUserAndExecute(async () => {
      await getInfo()
    })
  }, [userData])
  const getInfo = async () => {
    const { data } = await getDevicesList()
    setRows(
      data.data.map((item) => {
        return createData(item.type, item.name, item.id, '')
      }),
    )
  }

  const cancelConnection = async (id: string) => {
    await deleteDevice({ id: id })
    await getInfo()
  }

  const renderCell = (column: Column, row: Data) => {
    const value = row[column.id]
    if (column.id === 'actionButtons') {
      return (
        <DeleteConfirmationDevice
          deviceName={row.deviceName}
          onConfirm={() => cancelConnection(row.deviceId)}
        />
      )
    } else {
      return value
    }
  }

  return (
    <TableContainer className={styles.container}>
      <Table stickyHeader aria-label="sticky table" classes={styles}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <>
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={cn(styles.tableHeadCell, {
                    [styles.tableHeadCellAction]: column.id === 'actionButtons',
                  })}
                >
                  {column.label}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={styles.tableBody}>
          {rows.map((row, indx) => {
            return (
              <TableRow
                role="checkbox"
                tabIndex={-1}
                key={row.deviceId + indx}
                className={cn(styles.bodyRow, { [styles.background]: indx % 2 !== 0 })}
              >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} className={cn(styles.cell)}>
                    {renderCell(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
          {!rows.length && (
            <TableRow className={styles.headRow}>
              <TableCell />
              <TableCell>
                <Typography variant="body2" textColor="color-grey-3">
                  You don`t have any devices yet
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { ActiveDevicesTable }
