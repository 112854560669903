import { FC, useMemo } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cls from 'classnames'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { AppRoute } from '@/libs/enums'
import { createSellPayload, handleSell } from '@/libs/helper'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import { Row } from './libs/components/row/row'
import styles from './styles.module.scss'

type TProperty = {
  wallet: TUserWallet
  isHidden?: boolean
  filteredHoldings?: THoldingsToken[] | null
  isHoneypot?: boolean
}

const WalletHoldingsTable: FC<TProperty> = ({ wallet, isHidden, filteredHoldings, isHoneypot }) => {
  const getCurrentTokenByWallet = (token: THoldingsToken, walletAddress: string) => {
    return token.w.find(
      (tokenWallet) => tokenWallet.a?.toLowerCase() === walletAddress?.toLowerCase(),
    )
  }

  const walletHoldings = useMemo(() => {
    if (!filteredHoldings) return []

    return filteredHoldings
      .filter((token) => getCurrentTokenByWallet(token, wallet.address))
      .map((token) => ({
        currentBalance: getCurrentTokenByWallet(token, wallet.address),
        ...token,
      }))
  }, [filteredHoldings, wallet.address])
  const userConfirmationRequired = useAppSelector((state) => state.user.userData.sell_confirmation)
  const navigate = useCustomNavigate()

  const handleMarketSellAll = async (holding: THoldingsToken) => {
    const wallets = [
      {
        name: wallet.name,
        public_key: wallet.public_key,
      },
    ]
    const payload = await createSellPayload({
      token: holding.a,
      wallets,
      sellPercentage: 100,
    })
    if (payload) {
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: {
            payload,
          },
        })
      } else {
        await handleSell(payload)
      }
    }
  }

  return (
    <div className={styles.container}>
      <Table className={styles.table}>
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell className={cls(styles.cell, styles.bold)}>Token</TableCell>
            <TableCell className={cls(styles.cell, styles.bold)}>Token Value</TableCell>
            {/* <TableCell className={cls(styles.cell, styles.bold)}>Description</TableCell> */}
            <TableCell align="right" className={cls(styles.cell, styles.bold, styles.sticky)}>
              Token Balance
            </TableCell>
            <TableCell className={cls(styles.cell, styles.visible)}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walletHoldings.map((holding) => (
            <Row
              key={holding.a}
              holding={holding}
              isHidden={isHidden}
              onMarketSellAll={() => handleMarketSellAll(holding)}
              isHoneypot={isHoneypot}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export { WalletHoldingsTable }
