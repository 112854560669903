import { FC, PropsWithChildren } from 'react'

import { Table, TableProps } from '@mui/material'

import styles from '@/libs/common/custom-table/styles.module.scss'

type TProps = PropsWithChildren & TableProps

const CustomTable: FC<TProps> = ({ children, ...props }) => {
  return (
    <Table stickyHeader aria-label="sticky table" {...props} classes={styles}>
      {children}
    </Table>
  )
}

export { CustomTable }
