import { FC, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import styles from '@/components/pending-orders/libs/components/order-history-table/styles.module.scss'
import { ChipToOrderStatus } from '@/components/pending-orders/libs/maps/chip-to-order-status'
import rowStyles from '@/components/pending-orders/row-styles.module.scss'
import { Chip, Icon, TokenRedirect, Typography } from '@/libs/common'
import { IconWithTooltip } from '@/libs/common/icon-with-tooltip'
import { ETxType, IconName } from '@/libs/enums'
import { calculateETHAmountByHash } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice.tsx'
import { TOrderHistory } from '@/libs/types'
import { store, useAppSelector } from '@/store'

type TProps = {
  row: TOrderHistory
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  const [open, setOpen] = useState(false)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { date, isBuy, isSell, isApprove, isDisperse, isCollect } = useMemo(
    () => ({
      date: moment(row.d).format('MMM D, HH:mm:ss'),
      isBuy: row.ty === ETxType.BUY,
      isSell: row.ty === ETxType.SELL,
      isApprove: row.ty === ETxType.APPROVE,
      isDisperse: row.ty === ETxType.DISPERSE,
      isCollect: row.ty === ETxType.COLLECT,
    }),
    [row],
  )

  return (
    row.tr.length && (
      <>
        <TableRow
          className={cn(rowStyles.tableRow, {
            [rowStyles.backgroundEven]: index % 2 !== 0,
            [rowStyles.backgroundOdd]: index % 2 === 0,
          })}
        >
          <TableCell className={cn(rowStyles.cell, rowStyles.rowCell)}>
            {row.tr.length > 1 && (
              <IconButton className={styles.expandIcon} onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUpIcon className={rowStyles.icon} />
                ) : (
                  <KeyboardArrowDownIcon className={rowStyles.icon} />
                )}
              </IconButton>
            )}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.paddingZero, styles.wallet)}
            component="th"
            scope="row"
            align="center"
          >
            {row.tr.length}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.paddingZero, styles.type)}
            component="th"
            scope="row"
            align="center"
          >
            {isSell && (
              <IconWithTooltip
                iconName={IconName.SELL2}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.SELL2} />
                    <Typography textColor="light-grey">Sell</Typography>
                  </div>
                }
              />
            )}
            {isBuy && (
              <IconWithTooltip
                iconName={IconName.SHOPPING_CART}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.SHOPPING_CART} />
                    <Typography textColor="light-grey">Buy</Typography>
                  </div>
                }
              />
            )}
            {isApprove && (
              <IconWithTooltip
                iconName={IconName.APPROVE}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.APPROVE} />
                    <Typography textColor="light-grey">Approve</Typography>
                  </div>
                }
              />
            )}
            {isDisperse && (
              <IconWithTooltip
                iconName={IconName.WITHDRAW}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.WITHDRAW} />
                    <Typography textColor="light-grey">Disperse</Typography>
                  </div>
                }
              />
            )}
            {isCollect && (
              <IconWithTooltip
                iconName={IconName.CROSS}
                messageClassName={rowStyles.tooltipWrapper}
                tooltip={
                  <div className={rowStyles.tooltip}>
                    <Icon name={IconName.CROSS} />
                    <Typography textColor="light-grey">Collect</Typography>
                  </div>
                }
              />
            )}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, styles.token, styles.tokenName)}
          >
            {row.tn === '-' ? (
              <div>{row.tn}</div>
            ) : (
              <TokenRedirect address={row.ta}>{row.tn || 'Token'}</TokenRedirect>
            )}
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.amount)}>
            {!isApprove && row.tr[0].s && (
              <>
                {formatTokenPrice(`${calculateETHAmountByHash(row.tr)}`).formatted}{' '}
                {currentChain.chainSymbol}
              </>
            )}
          </TableCell>

          <TableCell
            className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.rowPadding, styles.date)}
          >
            <Box display="flex" flexDirection="column">
              <div>{date.split('T')[0]}</div>
              <div>{date.split('T')[1]}</div>
            </Box>
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, styles.status)}>
            {
              <Chip
                key={row._id}
                styleVariant={ChipToOrderStatus[row.s?.toLowerCase() || 'pending']?.color}
                label={ChipToOrderStatus[row.s?.toLowerCase() || 'pending']?.label}
              />
            }
          </TableCell>

          <TableCell className={cn(rowStyles.cell, rowStyles.tableRow, rowStyles.tableButtonsRow)}>
            {row.tr.length === 1 && (
              <Link
                to={`${store.getState().chain.currentChain.explorer}/tx/${row.tr[0].h}`}
                target="_blank"
              >
                <Icon name={(IconName as any)[currentChain.scanLogo]} />
              </Link>
            )}
          </TableCell>
        </TableRow>

        <TableRow className={rowStyles.backgroundInner}>
          <TableCell
            className={cn(rowStyles.cell, rowStyles.cellWrapper)}
            sx={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1} width={'100%'}>
                <Table classes={rowStyles} size="small" aria-label="purchases">
                  <TableHead className={rowStyles.tableHead}></TableHead>

                  <TableBody>
                    {row.tr.map((wallet, index) => {
                      return (
                        <TableRow key={wallet.n + index}>
                          <TableCell className={cn(rowStyles.cell)}></TableCell>
                          <TableCell
                            className={cn(rowStyles.cell, styles.innerWalletName)}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {wallet.n}
                          </TableCell>

                          <TableCell className={cn(rowStyles.cell, styles.amount)}>
                            {(isBuy || isSell) && wallet.a && (
                              <>
                                {formatTokenPrice(wallet.a).formatted} {currentChain.chainSymbol}
                              </>
                            )}
                          </TableCell>
                          <TableCell
                            className={cn(
                              rowStyles.cell,

                              styles.date,
                            )}
                          >
                            <Box display="flex" flexDirection="column">
                              <div>{date.split('T')[0]}</div>
                              <div>{date.split('T')[1]}</div>
                            </Box>
                          </TableCell>
                          <TableCell className={cn(rowStyles.cell, styles.status)}>
                            {
                              <Chip
                                key={row._id}
                                styleVariant={
                                  ChipToOrderStatus[wallet.s?.toLowerCase() || 'pending']?.color
                                }
                                label={
                                  ChipToOrderStatus[wallet.s?.toLowerCase() || 'pending']?.label
                                }
                              />
                            }
                          </TableCell>

                          <TableCell className={cn(rowStyles.cell, rowStyles.etherscan)}>
                            {wallet.h && (
                              <Link
                                to={`${store.getState().chain.currentChain.explorer}/tx/${wallet.h}`}
                                target="_blank"
                              >
                                <Icon name={(IconName as any)[currentChain.scanLogo]} />
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  )
}

export { Row }
