import { FC, useEffect } from 'react'

import { DepositTab } from '@/components/deposit-tab'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Typography } from '@/libs/common'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const DepositToWallet: FC = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallet = useWalletFromParams()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({
      title: `Deposit to ${wallet?.name}`,
      withBackButton: true,
      titleProps: { className: styles.title },
    })
  }, [wallet])

  return (
    <div>
      {!!wallet && (
        <Typography variant="body1" align="center" marginBottom={3}>
          {formatTokenPrice(wallet.balanceFormatted || '0').formatted} {currentChain.chainSymbol}{' '}
          (~$
          {convertScientificNotationNumber(
            +(wallet.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0),
            2,
          )}
          )
        </Typography>
      )}

      <DepositTab wallet={wallet} />
    </div>
  )
}

export { DepositToWallet }
