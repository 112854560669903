import { FC, MouseEvent, useState } from 'react'

import cn from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  options: {
    value: string
  }[]
}

const TimeFrameToggle: FC<TProps> = ({ options }) => {
  const [alignment, setAlignment] = useState('1Y')

  const handleAlignment = (_: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }

  return (
    <ButtonGroupRadio
      className={styles.group}
      value={alignment}
      onChange={handleAlignment}
      exclusive
    >
      {options.map(({ value }) => (
        <ButtonGroupRadioButton
          key={value}
          value={value}
          className={cn(styles.button, { [styles.active]: alignment === value })}
        >
          {value}
        </ButtonGroupRadioButton>
      ))}
    </ButtonGroupRadio>
  )
}

export { TimeFrameToggle }
