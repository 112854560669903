import { AppRoute } from '@/libs/enums'

const authRoutes = [
  `/`,
  AppRoute.SELECT_PLAN,
  AppRoute.SETUP_WALLETS,
  AppRoute.GENERATE_WALLETS,
  AppRoute.IMPORT_KEYS,
  AppRoute.ADD_DEVICE,
  AppRoute.IMPORT_WALLET_FILE,
  AppRoute.MASTER_PASSWORD_ADVANTAGES,
  AppRoute.CREATE_MASTER_PASSWORD,
  AppRoute.SETUP_SUCCESS,
  AppRoute.ALREADY_SIGN,
  AppRoute.ADD_CHAIN,
  AppRoute.ENTER_CODE,
  AppRoute.WALLET_CONNECT,
]

export { authRoutes }
