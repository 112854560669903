function splitAndTrimString(inputString: string, splitSubstring: string) {
  const splitIndex = inputString.indexOf(splitSubstring)

  if (splitIndex === -1) {
    return [inputString.trim(), '']
  }

  const firstPart = inputString.substring(0, splitIndex).trim()
  const secondPart = inputString.substring(splitIndex).trim()

  return [firstPart, secondPart]
}

export { splitAndTrimString }
