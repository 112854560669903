import { FC, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TImportWalletParsed } from '@/api/wallet/types'
import { PrivateKeysTable } from '@/components/private-keys-table'
import { SelectWalletCount } from '@/components/select-wallet-count'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { BackButton, Button } from '@/libs/common'
import { AppRoute, WalletsQuantityButton } from '@/libs/enums'
import { importWalletsHandler } from '@/libs/helper/importWalletsHandler'
import { getWalletsTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch } from '@/store'
import { fetchUserWalletsForce } from '@/store/slices/user.slice'

import { EImportModalType } from './libs/enum'
import styles from './styles.module.scss'

const ImportWallets: FC = () => {
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState<number>()
  const [wallets, setWallets] = useState<TImportWalletParsed[]>([])
  const [initialWallets, setInitialWallets] = useState('')

  const dispatch = useAppDispatch()
  const navigate = useCustomNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const modalType = useMemo(() => searchParams.get('type'), [searchParams])

  const { setModalProps } = useModal()

  useEffect(() => {
    const title = getWalletsTitle(modalType)
    setModalProps({ title })
  }, [])

  useEffect(() => {
    if (initialWallets) return

    const walletsJson = searchParams.get('wallets') || ''

    if (!walletsJson) return

    setInitialWallets(walletsJson)

    setWallets(walletsJson ? (JSON.parse(walletsJson) as TImportWalletParsed[]) : [])
    searchParams.delete('wallets')
    setSearchParams(searchParams, {
      replace: true,
    })
  }, [searchParams])

  const isPublicAndPrivate = modalType === EImportModalType.PUBLIC_AND_PRIVATE_KEYS

  const submit = async () => {
    setLoading(true)
    await importWalletsHandler({
      wallets,
      onSuccess: async () => {
        await dispatch(fetchUserWalletsForce('')).unwrap()
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`,
          replace: true,
        })
      },
      // onPartialSuccess: async () => {
      //   await dispatch(fetchUserWalletsForce('')).unwrap()
      // },
    })
    setLoading(false)
  }

  const handleChange = (newValue: number | string) => {
    if (newValue !== WalletsQuantityButton.CUSTOM) {
      setCounter(+newValue)
      return
    }
    setCounter(0)
  }

  return (
    <div className={styles.container}>
      <BackButton
        isAbsolute
        onClick={() => {
          navigate({
            isDashboard: true,
            path: `${AppRoute.MODAL}/${AppRoute.IMPORT_PRIVATE_KEY}`,
            searchParams: {
              wallets: initialWallets,
            },
          })
        }}
      />
      {/* <Typography variant="h1" align="center">
        {title}
      </Typography> */}
      {!isPublicAndPrivate && <SelectWalletCount counter={counter} handleChange={handleChange} />}
      <div className={styles.tableWrapper}>
        <PrivateKeysTable wallets={wallets} />
      </div>
      <Button onClick={submit} isLoading={loading}>
        Import wallets
      </Button>
    </div>
  )
}

export { ImportWallets }
