import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { chainsConfig } from '@/libs/configs/chains.config'
import { TChainConfig } from '@/libs/types/chain.type.ts'

const CHAIN_INDEX_IN_URL = 2

const useCurrentChain = () => {
  const location = useLocation()
  return useMemo(() => {
    const pathParts = location.pathname.split('/')?.filter((o) => o)
    const chainFromUrl = pathParts[CHAIN_INDEX_IN_URL] as string
    return (
      (chainsConfig.find(
        (chain: TChainConfig) => chain.description.toLowerCase() === chainFromUrl,
      ) as TChainConfig) ?? chainsConfig[0]
    )
  }, [location])
}

export { useCurrentChain }
