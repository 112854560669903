import { useNavigate, useParams } from 'react-router-dom'

import { AppMode, AppRoute } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString.ts'
import { TQueryObject } from '@/libs/types'

type TProps = {
  isDashboard?: boolean
  delta?: number
  path?: string
  newMode?: string
  newChain?: string
  searchParams?: TQueryObject
  state?: any
  replace?: boolean
}

const useCustomNavigate = () => {
  const { chain, mode } = useParams()
  const navigate = useNavigate()
  return ({
    path = '',
    isDashboard,
    delta,
    searchParams,
    newMode = '',
    newChain = '',
    state = {},
    replace,
  }: TProps) => {
    const query = searchParams ? createQueryString(searchParams) : ''
    if (delta) {
      navigate(delta)
    } else if (path || isDashboard) {
      navigate(
        isDashboard
          ? `${AppRoute.DASHBOARD}/${newMode || mode || AppMode.PRO}/${newChain || chain || 'eth'}${path && '/'}${path}${query}`
          : `${path}${query}`,
        {
          state: state,
          replace: !!replace,
        },
      )
    }
  }
}

export { useCustomNavigate }
