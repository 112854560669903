import { FC } from 'react'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Icon } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = {
  isActive?: boolean
  name: string
  price: number
  link: string
}

const PlanCard: FC<TProperty> = ({ name, price, isActive, link }) => {
  const navigate = useCustomNavigate()
  const handleSelectPlan = () => {
    navigate({ isDashboard: true, path: link })
  }

  return (
    <div className={styles.container}>
      {isActive && <div className={styles.label}>Active</div>}
      <div className={styles.header}>
        <Typography variant="h3">{name}</Typography>
        <Typography variant="body2">
          Best for everyone who wants to enter the trading & sniping world.
        </Typography>
        <Typography variant="h1" isEmphasize>
          {price ? `From $${price}` : 'FREE'}
        </Typography>
      </div>
      <ul className={styles.advantages}>
        <li className={styles.advantageItem}>
          <Icon name={IconName.LIST_POINT} />
          <Typography variant="body2">Maximum 5 wallets</Typography>
        </li>
        <li className={styles.advantageItem}>
          <Icon name={IconName.LIST_POINT} />
          <Typography variant="body2">Maximum 5 wallets</Typography>
        </li>
        <li className={styles.advantageItem}>
          <Icon name={IconName.LIST_POINT} />
          <Typography variant="body2">Maximum 5 wallets</Typography>
        </li>
      </ul>

      <div className={styles.divider}>
        <div className={styles.line} />
        <Typography variant="body2" variantWeight="semibold">
          Enjoy for free / includes
        </Typography>
        <div className={styles.line} />
      </div>

      <ul className={styles.advantages}>
        <li className={styles.advantageItem}>
          <Icon name={IconName.LIST_POINT} />
          <Typography variant="body2">Maximum 5 wallets</Typography>
        </li>
        <li className={styles.advantageItem}>
          <Icon name={IconName.LIST_POINT} />
          <Typography variant="body2">Maximum 5 wallets</Typography>
        </li>
      </ul>
      <Button onClick={handleSelectPlan}>Choose</Button>
    </div>
  )
}

export { PlanCard }
