import { TPair } from '@/libs/types/token-info-response.type'

export const formatPairSymbol = (selectedPairAddress: string, pairs: TPair) => {
  const symbol = pairs.address === selectedPairAddress ? pairs.symbol : pairs.symbol
  switch (symbol) {
    case 'WETH':
      return 'ETH'
    case 'WBNB':
      return 'BNB'
    default:
      return symbol
  }
}
