import { FC } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { TBridgeTokenItem } from '@/pages/modal-page/libs/components/bridge/libs/components/bridge-token-select/libs/types'

import styles from './styles.module.scss'

type TProps = {
  tokenItem: TBridgeTokenItem
  onlyShowInfo?: boolean
  onTokenSelect?: (token: TBridgeTokenItem) => void
  hideTokenAddress?: boolean
}

const BridgeTokenSelectItem: FC<TProps> = ({
  tokenItem,
  onlyShowInfo,
  hideTokenAddress,
  onTokenSelect,
}) => {
  return (
    <div
      className={cn(styles.container, { [styles.onlyShowInfo]: onlyShowInfo })}
      onClick={() => onTokenSelect?.(tokenItem)}
    >
      <div className={styles.infoRow}>
        <div className={styles.tokenInfo}>
          <div className={styles.tokenIcon}>
            <Icon name={(IconName as any)[tokenItem.iconName]} />
          </div>
          <Grid display="flex" flexDirection="column" justifyContent="center">
            <Grid display="flex" alignItems="center" columnGap={1}>
              <Typography className={styles.chainInfoText}>{tokenItem.tokenSymbol}</Typography>
              {/* <Typography>{tokenItem.networkLabel}</Typography> */}
            </Grid>
            <Typography>{tokenItem.networkLabel}</Typography>
          </Grid>
        </div>
        <Grid display="flex" alignItems="center" columnGap={2}>
          <Grid display="flex" flexDirection="column" alignItems="flex-end">
            <Typography className={styles.chainInfoText}>
              {formatNumber(tokenItem.totalBalance.native).formatted}
            </Typography>
            <Typography>${formatNumber(tokenItem.totalBalance.usd).formatted}</Typography>
          </Grid>
          {!!onlyShowInfo && <Icon name={IconName.DROPDOWN_ARROWS} />}
        </Grid>
      </div>

      {!onlyShowInfo && !hideTokenAddress && (
        <Typography className={styles.tokenAddress}>
          {hideWalletAddress(tokenItem.tokenAddress)}
        </Typography>
      )}
    </div>
  )
}

export { BridgeTokenSelectItem }
