import { api } from '@/api'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TBackendResponse } from '@/libs/types/backend-response.type'

type TGetBarsProps = {
  tokenAddress: string
  from: number
  to: number
  resolution: string
  blockchain: number
}

const BASE_URL = 'api/v1/token/chart'

const getBars = async ({ tokenAddress, from, to, resolution, blockchain }: TGetBarsProps) => {
  const query = {
    f: from,
    t: to,
    r: resolution,
    b: blockchain,
    // removeEmptyBars: '0',
    // removeLeadingNullValues: '1',
  }

  const { data } = await api.get<TBackendResponse<any[]>>(
    `${BASE_URL}/${tokenAddress}/bars${createQueryString(query)}`,
  )

  const barsData = data.data

  if (!barsData?.length) {
    return []
  }

  return barsData
}

export { getBars }
