import { ChangeEvent, useMemo, useState } from 'react'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'

import { Button, Icon, Input } from '@/libs/common'
import { IconName } from '@/libs/enums'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

// @TODO delete mock data
const rows = [
  {
    id: 1,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 2,
    position: 'UNIBOT/WETH',
    status: true,
    trader: 'Marketer',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 3,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Marketer',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 4,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 5,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 6,
    position: 'UNIBOT/WETH',
    status: true,
    trader: 'Marketer',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 7,
    position: 'UNIBOT/WETH',
    status: true,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 8,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
  {
    id: 9,
    position: 'UNIBOT/WETH',
    status: false,
    trader: 'Copycat',
    totalSpent: '25.528 ETH',
    totalRevenue: '312.034534 UNIB',
    realizedPL: '24.00 ETH / $45,694 ROI+1578,4%',
    potentialPL: '0.9 ETH / $1,678 ROI+68,4%',
    history: [
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
      {
        name: 'W1',
        spent: '0.1 ETH',
        revenue: '1 ETH',
        realizedPL: '0.9 ETH / $1,678 ROI+68,4%',
        potential: '24.00 ETH / $45,694 ROI+1578,4%',
      },
    ],
  },
]

const PositionsTable = () => {
  const [searchValue, setSearchValue] = useState('')

  const handleChangeSearchInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleClearSearchInput = () => {
    setSearchValue('')
  }

  const data = useMemo(() => {
    return rows
      .filter(({ trader }) => trader.toLowerCase().trim().includes(searchValue.toLowerCase()))
      .sort((a, b) => (a.status === b.status ? 0 : a.status ? -1 : 1))
  }, [searchValue])

  return (
    <Grid container flexDirection="column" gap={3}>
      <Grid className={styles.searchWrapper}>
        <Input
          isTransparent
          endAdornment={<Icon name={IconName.SEARCH} />}
          placeholder="Enter trader name"
          className={styles.input}
          value={searchValue}
          onChange={handleChangeSearchInput}
        />

        <Button
          className={styles.button}
          styleVariant="basic"
          borderless
          disabled={!searchValue}
          onClick={handleClearSearchInput}
        >
          Clear
        </Button>
      </Grid>

      <TableContainer component={Paper} className={styles.container}>
        <Table classes={styles} aria-label="collapsible table" size="small">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell className={cn(styles.cell, styles.arrowCeil)} />

              <TableCell className={cn(styles.cell, styles.headCell, styles.headCellPadding)}>
                Position
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.status)} align="right">
                Status
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCell, styles.trader)} align="right">
                Trader
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.totalSpent)}
                align="right"
              >
                Total Spent
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.totalRevenue)}
                align="right"
              >
                Total Revenue
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.headCellPadding)}
                align="right"
              >
                Realized P/L
              </TableCell>

              <TableCell
                className={cn(styles.cell, styles.headCell, styles.headCellPadding)}
                align="right"
              >
                Potential P/L
              </TableCell>

              <TableCell className={cn(styles.cell, styles.headCellWidth)}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {data.map((row, index) => (
              <Row key={row.id} row={row} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export { PositionsTable }
