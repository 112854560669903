import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { TNewToken } from '@/api/tokens/types'
import styles from '@/components/token-list/libs/new-tokens-table/styles.module.scss'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { ButtonCopy, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { store, useAppSelector } from '@/store'

type TProperty = {
  row: TNewToken
}

const Row: FC<TProperty> = ({ row }) => {
  const handleNewTokenSelection = useSelectNewToken()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const handleChangeCurrentToken = async () => {
    if (row.status === 'DEPLOYED') return
    handleNewTokenSelection(row.address)
  }

  return (
    <TableRow
      className={cn(styles.row, row.status === 'DEPLOYED' && styles.unclickable)}
      onClick={handleChangeCurrentToken}
    >
      <TableCell className={cn(styles.cell, styles.token)}>
        <div className={styles.tokenInfoWrapper}>
          <div className={styles.controls}>
            {row.status === 'RUGGED' && (
              <span className={styles.warning}>
                <Icon name={IconName.TRIANGLE} />
              </span>
            )}
            {/*<div className={styles.iconWrapper}>*/}
            {/*  {row.images.small ? (*/}
            {/*    <img src={row.images.small} className={styles.icon} loading="lazy" alt={row.name} />*/}
            {/*  ) : (*/}
            {/*    <TokenPlaceholder size="35px" />*/}
            {/*  )}*/}

            {/*  <img src={eth} loading="lazy" alt="network logo" className={styles.network} />*/}
            {/*</div>*/}
            <Grid container flexDirection="column">
              <Typography
                variant="body2"
                className={cn(styles.symbol, row.status === 'RUGGED' && styles.redText)}
              >
                {row.symbol}
              </Typography>

              <Typography variant="body2" className={styles.name}>
                {row.name}
              </Typography>
            </Grid>
          </div>
          <ButtonCopy value={row.address} />
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.actions)}>
        <div className={styles.exchangeWrapper}>
          <Link
            to={`${store.getState().chain.currentChain.explorer}/token/${row.address}`}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name={IconName.ETHER_LOGO} />
          </Link>
          <Link
            to={`https://x.com/search?q=%24${row.symbol}`}
            target="_blank"
            className={styles.twitter}
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name={IconName.TWITTER} />
          </Link>
          {row.extended_data?.exchanges
            .filter((el) => el.icon_url)
            .slice(0, 4)
            .map((el, index) => (
              <Link
                key={el.id}
                to={`${el.trade_url}?outputCurrency=${row.address}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={el.icon_url}
                  alt={el.name}
                  className={styles.image}
                  style={{ zIndex: 4 - index }}
                ></img>
              </Link>
            ))}
        </div>
      </TableCell>
      <TableCell
        className={cn(styles.cell, styles.status, {
          [styles.redText]: row.status === 'RUGGED',
          [styles.greenText]: row.status === 'TRADING',
          [styles.orangeText]: row.status === 'DEPLOYED',
        })}
      >
        {row.status}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.listed)}>
        {moment
          .utc(row.time_created * 1000)
          .startOf('second')
          .fromNow()}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.tax)}>
        <div>
          <span className={styles.greenText}>B </span>
          {row.simulation?.buy_tax ? <>{Math.round(+row.simulation?.buy_tax * -1)} %</> : '-'}
        </div>
        <div>
          <span className={styles.redText}>S </span>
          {row.simulation?.sell_tax ? <>{Math.round(+row.simulation?.sell_tax * -1)} %</> : '-'}
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.liquidity)}>
        {row.extended_data?.liquidity ? (
          <>${formatNumber(row.extended_data.liquidity).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.time, styles.volume)}>
        {row.extended_data?.volume ? <>${formatNumber(row.extended_data.volume).formatted}</> : '-'}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.marketCap)}>
        {row.extended_data?.market_cap ? (
          <>${formatNumber(row.extended_data.market_cap).formatted}</>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.price)}>
        {row.extended_data?.price ? <>${formatNumber(row.extended_data.price).formatted}</> : '-'}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.traders)}>
        <div>
          <span className={styles.greenText}>B </span>
          {row.extended_data?.buys_one_day ? row.extended_data.buys_one_day : '-'}
        </div>
        <div>
          <span className={styles.redText}>S </span>
          {row.extended_data?.sells_one_day ? row.extended_data.sells_one_day : '-'}
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.supply)}>
        $
        {row.total_supply
          ? formatNumber(+row.total_supply / Math.pow(10, row.decimals)).formatted
          : 0}
      </TableCell>
      <TableCell className={cn(styles.cell, styles.deployed)}>
        <div className={styles.address}>{hideWalletAddress(row.creator_address)}</div>
        <div>
          {' '}
          {formatNumber(+row.creator_balance / Math.pow(10, 18)).formatted}{' '}
          {currentChain.chainSymbol}
        </div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
