import useCheckUser from '@/hooks/useCheckUser'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Icon, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const tabs = [
  {
    label: 'Buy',
    icon: <Icon name={IconName.SHOPPING_CART_LARGE} />,
    navigate: `${AppRoute.MODAL}/${AppRoute.BUY_MODAL}`,
  },
  {
    label: 'Sell',
    icon: <Icon name={IconName.BOTTOM_TABS_SELL} />,
    navigate: `${AppRoute.MODAL}/${AppRoute.SELL_MODAL}`,
  },
  {
    label: 'Positions',
    icon: <Icon name={IconName.BOTTOM_TABS_PROFIT} />,
    navigate: `${AppRoute.MODAL}/${AppRoute.PROFIT_MODAL}`,
  },
]

const BottomTabs = () => {
  const navigate = useCustomNavigate()
  const checkUserAndExecute = useCheckUser()

  return (
    <div className={styles.wrapper}>
      {tabs.map((item) => (
        <div
          key={item.label}
          className={styles.itemWrapper}
          onClick={() =>
            checkUserAndExecute(() => navigate({ isDashboard: true, path: item.navigate }))
          }
        >
          <div className={styles.iconWrapper}>{item.icon}</div>
          <Typography className={styles.label}>{item.label}</Typography>
        </div>
      ))}
    </div>
  )
}

export { BottomTabs }
