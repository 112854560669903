import { FC, useState } from 'react'

import cn from 'classnames'

import { Button, type TButtonIconProps, Tooltip } from '@/libs/common/index'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TButtonCopyProps = Omit<TButtonIconProps, 'icon'> & {
  value: string | undefined
  tooltipClassName?: string
  disabledVariant?: boolean
}

const ButtonCopy: FC<TButtonCopyProps> = ({
  value,
  className,
  tooltipClassName,
  disabledVariant = false,
  ...props
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleCopyToClipboard = (event: React.MouseEvent) => {
    setIsTooltipVisible(true)
    setTimeout(() => setIsTooltipVisible(false), 1000)
    event.stopPropagation()
    if (value) navigator.clipboard.writeText(value)
  }

  return (
    <Tooltip
      className={tooltipClassName}
      title={'Copied'}
      withIcon={false}
      open={!!isTooltipVisible}
    >
      <Button
        transparent
        onClick={handleCopyToClipboard}
        className={cn(styles.buttonCopy, className)}
        {...props}
        iconStartName={!disabledVariant ? IconName.COPY : IconName.COPY_GREY}
      />
    </Tooltip>
  )
}

export { ButtonCopy }
