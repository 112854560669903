import { useEffect, useState } from 'react'

import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { createQueryString } from '@/libs/helper/createQueryString'
import { SniperSockerService } from '@/socket'
import { TSniperWebsocketConnectProps } from '@/socket/sniper-socket.service'
import { useAppSelector } from '@/store'

type TProps = {
  socket: SniperSockerService
  connectionProps?: TSniperWebsocketConnectProps
  onMessage?: (data: string) => any
  verifyExistanceBeforeConnect?: any[]
}

const useSniperWebsocket = ({
  socket,
  connectionProps,
  onMessage,
  verifyExistanceBeforeConnect = [],
}: TProps) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [currentChainId, setCurrentChainId] = useState<number | null>(null)

  const isPublic = connectionProps?.isPublic || false

  useEffect(() => {
    const existanceVerified =
      !verifyExistanceBeforeConnect.length || verifyExistanceBeforeConnect.every((item) => !!item)
    if ((!isPublic && !userData) || !existanceVerified) {
      return
    }

    if (isAppIdle) {
      socket.disconnect()
      return
    } else if (
      currentChain.indexerChainId === currentChainId &&
      socket.getStatus() !== EWebsocketStatus.CLOSED
    ) {
      return
    }

    socket.disconnect()
    setCurrentChainId(currentChain.indexerChainId)
    ;(async () => {
      const props = connectionProps || {
        endpoint: '',
        query: createQueryString({ b: currentChain.indexerChainId }),
      }
      await socket.connect(props)
      if (onMessage) {
        socket.onMessage(onMessage)
      }
    })()
  }, [currentChain, isAppIdle, userData, ...verifyExistanceBeforeConnect])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      socket.forceDisconnect()
    })
  }, [])
}

export { useSniperWebsocket }
