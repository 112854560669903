import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Divider } from '@mui/material'
import cls from 'classnames'

import styles from '@/components/selected-token-info/styles.module.scss'
import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { IconName } from '@/libs/enums'
import {
  checkSimulationValue,
  countDays,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TSniperSimulationData } from '@/libs/types/sniper-simulation-socket-response.type'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { store } from '@/store'

type TProps = {
  selectedToken: TTokenInfo
  simulation: TSniperSimulationData | null
  handleOpenModal: () => void
  handleOpenBubbleMap: () => void
}

const TokenInfoLite: FC<TProps> = ({
  simulation,
  selectedToken,
  handleOpenModal,
  handleOpenBubbleMap,
}) => {
  const isDataAvailable = !!simulation
  return (
    <>
      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideMb)}
      />

      <TokenDataItem
        title="Tax Buy / Sell / Transfer"
        tooltipInfo="The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring."
        componentToHide="componentToHideMb"
        value={
          simulation && (
            <>
              <span className={styles[getTaxesColor(simulation.tx.b)]}>
                {getPercentage(simulation.tx.b)}
              </span>{' '}
              /{' '}
              <span className={styles[getTaxesColor(simulation.tx.s)]}>
                {getPercentage(simulation.tx.s)}
              </span>{' '}
              /{' '}
              <span className={styles[getTaxesColor(simulation.tx.t)]}>
                {getPercentage(simulation.tx.t)}
              </span>
            </>
          )
        }
        isDataAvailable={isDataAvailable}
        noDataValue="- / - / -"
        isCentered
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideMb)}
      />

      <TokenDataItem
        title="Honeypot"
        tooltipInfo="If the token is a Honeypot, purchased tokens will not be able to be sold."
        componentToHide="componentToHideMb"
        value={simulation?.t.h ? 'Yes' : 'No'}
        isDataAvailable={isDataAvailable}
        isCentered
        className={cls(!simulation?.t.h ? styles.greenText : styles.redText)}
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideMb)}
      />

      <TokenDataItem
        title="Renounced"
        tooltipInfo="When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced."
        componentToHide="componentToHideMb"
        value={simulation?.t.r ? 'Yes' : 'No'}
        isDataAvailable={isDataAvailable}
        isCentered
        className={cls(simulation?.t.r ? styles.greenText : styles.redText)}
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideMb)}
      />

      <TokenDataItem
        title="Liquidity locked"
        isDataAvailable={isDataAvailable}
        isCentered
        componentToHide="componentToHideMb"
        value={
          <Typography
            variant="body2"
            className={cls(
              simulation?.additional_data?.is_pair_locked.status
                ? styles.greenText
                : styles.grayText,
            )}
          >
            {simulation?.additional_data?.is_pair_locked.status ? 'Yes' : 'No'}{' '}
            <span>
              {!!simulation?.additional_data?.is_pair_locked.status &&
                simulation?.additional_data?.is_pair_locked.unlock_date &&
                `(${countDays(simulation?.additional_data?.is_pair_locked.unlock_date)}d)`}
            </span>
            <div className={styles.additionalInfo}>
              {simulation?.additional_data?.is_pair_locked.locked_percentage}
            </div>
          </Typography>
        }
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideDt)}
      />

      <TokenDataItem
        title="Burn LP"
        tooltipInfo="Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%."
        componentToHide="componentToHideDt"
        value={simulation?.l.b === '-' ? simulation?.l.b : `${simulation?.l.b}%`}
        isDataAvailable={isDataAvailable}
        isCentered
        className={simulation?.l.b && +simulation?.l.b > 50 ? styles.greenText : ''}
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideDt)}
      />

      <TokenDataItem
        title="Clog"
        tooltipInfo="How many tokens are stored in the smart contract waiting to be sold. The best value is 0%."
        componentToHide="componentToHideDt"
        value={`${simulation?.t.c}%`}
        isDataAvailable={isDataAvailable}
        className={styles[getClogColor(+(simulation?.c || '0'))]}
        isCentered
      />
      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideDt)}
      />

      <TokenDataItem
        title="Holders"
        componentToHide="componentToHideDt"
        value={simulation?.additional_data?.holders.holderCount || 0}
        isDataAvailable={isDataAvailable}
      />
      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideDt)}
      />
      <TokenDataItem
        componentToHide="componentToHideDt"
        title="Top 10 holders"
        value={simulation?.additional_data?.holders.top10Holders || '-'}
        isDataAvailable={isDataAvailable}
      />
      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideLp)}
      />

      <TokenDataItem
        componentToHide="componentToHideLp"
        title="Max Buy"
        tooltipInfo="How many tokens can be purchased per transaction / how many tokens can be held per wallet."
        value={
          simulation && (
            <Typography variant="body2" className={styles.defaultColor}>
              <div>{getPercentage(simulation.lt.b.p)}</div>
              <div className={styles.additionalInfo}>
                {checkSimulationValue(simulation.lt.b.ccc) ? (
                  <>${formatTokenPrice(simulation.lt.b.ccc).formatted}</>
                ) : (
                  '-'
                )}
              </div>
            </Typography>
          )
        }
        isDataAvailable={isDataAvailable}
        isCentered
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideLp)}
      />

      <TokenDataItem
        componentToHide="componentToHideLp"
        title="Max Sell"
        value={
          simulation && (
            <Typography variant="body2" className={styles.defaultColor}>
              <div>{getPercentage(simulation.lt.s.p)}</div>
              <div className={styles.additionalInfo}>
                {checkSimulationValue(simulation.lt.s.ccc) ? (
                  <>${formatTokenPrice(simulation.lt.s.ccc).formatted}</>
                ) : (
                  '-'
                )}
              </div>
            </Typography>
          )
        }
        isDataAvailable={isDataAvailable}
        isCentered
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideLp)}
      />
      <TokenDataItem
        componentToHide="componentToHideLp"
        title="Max Hold"
        value={
          simulation && (
            <Typography variant="body2" className={styles.defaultColor}>
              <div>{getPercentage(simulation.lt.w.p)}</div>
              <div className={styles.additionalInfo}>
                {checkSimulationValue(simulation.lt.w.ccc) ? (
                  <>${formatTokenPrice(simulation.lt.w.ccc).formatted}</>
                ) : (
                  '-'
                )}
              </div>
            </Typography>
          )
        }
        isDataAvailable={isDataAvailable}
        isCentered
      />

      <Divider
        orientation="vertical"
        flexItem
        className={cls(styles.divider, styles.componentToHideMb)}
      />

      <div className={styles.liteTokenDataWrapper}>
        <ButtonIcon tooltipTitle="List" onClick={handleOpenModal}>
          <Icon name={IconName.LIST_ALT} />
        </ButtonIcon>
        <Link
          to={`${store.getState().chain.currentChain.explorer}/token/${selectedToken.token.address}`}
          target="_blank"
          className={styles.link}
        >
          <Icon name={IconName.ETHERSCAN_GOLD} />
        </Link>
      </div>
    </>
  )
}

export { TokenInfoLite }
