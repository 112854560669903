import { FC } from 'react'

import { useAppMode } from '@/hooks/useAppMode'
import { ListButton } from '@/libs/common'
import { ButtonLink } from '@/libs/common/button-link'
import { AppRoute, IconName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = {
  wallet: TUserWallet | undefined
  type?: ESidebarBtnType
}
const TransferTab: FC<TProperty> = ({ wallet, type }) => {
  const { mode } = useAppMode()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return (
    <div className={styles.container}>
      {(type === ESidebarBtnType.TRANSFER || !type) && (
        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${wallet?.address}`}
        >
          <ListButton
            title="Transfer"
            description="You will be able to select one of your wallets inside the bot to send to, or send to external wallet."
            iconName={IconName.WITHDRAW}
          />
        </ButtonLink>
      )}
      {(type === ESidebarBtnType.DISPERSE_COLLECT || !type) && (
        <>
          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.DISPERSE_MULTI}/${wallet?.address}`}
          >
            <ListButton
              title="Disperse Multi"
              description="You will be able to select multiple wallets inside the bot to send to equal amounts."
              iconName={IconName.CROSS}
            />
          </ButtonLink>
          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.COLLECT_MULTI}/${wallet?.address}`}
          >
            <ListButton
              title="Collect Multi"
              description="You will be able to select receiving wallet and collect the funds from as many wallets as you have in the bot in one go."
              iconName={IconName.CROSS}
            />
          </ButtonLink>
        </>
      )}
    </div>
  )
}

export { TransferTab }
