import { FC, PropsWithChildren } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Modal, ModalProps } from '@mui/material'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = PropsWithChildren &
  ModalProps & {
    open: boolean
    name: string
    setOpen?: (value: boolean) => void
    title?: string
    message?: string
  }

const ModalComponent: FC<TProps> = ({ name, title, message, open, setOpen, children, ...rest }) => {
  return (
    <Modal open={open} disableEnforceFocus onClose={() => setOpen?.(false)} {...rest}>
      <div className={styles.wrapper}>
        <TransitionGroup exit={false}>
          <CSSTransition
            key={name}
            classNames={{
              enter: styles.animationEnter,
              enterActive: styles.animationEnterActive,
              appear: styles.animationAppear,
              appearActive: styles.animationAppearActive,
            }}
            timeout={300}
            in
            appear
          >
            <div id={name} className={styles.container}>
              {!!title && (
                <Typography variant="h1" align="center" className={styles.title}>
                  {title}
                </Typography>
              )}
              {!!message && (
                <Typography align="center" variant="body1" className={styles.message}>
                  {message}
                </Typography>
              )}

              {children}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Modal>
  )
}

export { ModalComponent }
