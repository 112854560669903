import momentTz from 'moment-timezone'

const getCurrentTimezone = () => {
  const offsetInMinutes = momentTz.tz(momentTz.tz.guess()).utcOffset()
  return {
    name: momentTz.tz.guess(),
    offsetInSeconds: offsetInMinutes * 60,
    offsetInMinutes,
    offsetInHours: offsetInMinutes / 60,
  }
}

export { getCurrentTimezone }
