import { useMemo, useState } from 'react'

import { Sorting } from '@/libs/enums'
import { ValueOf } from '@/libs/types'
import { TPriceItemSocket } from '@/libs/types/panels-data-socket-response.type'

enum ESortingOption {
  NEWEST = 'Newest',
  HIGHEST_PNL = 'Highest pnl',
  WORTH = 'Worth',
}
type TSortingFields = { tpl: string; ca: string; tw: TPriceItemSocket }
type TUseSortingProps<T> = {
  data: (T & TSortingFields)[]
  search?: string
  sortingOrder: ValueOf<typeof Sorting>
}

const useSortedData = <T>({ data, search, sortingOrder }: TUseSortingProps<T>) => {
  const [activeOption, setActiveOption] = useState(ESortingOption.NEWEST)
  const handleChange = (selectedOption: ESortingOption) => {
    setActiveOption(selectedOption)
  }

  const sortedData = useMemo(() => {
    if (!data) {
      return data
    }

    switch (activeOption) {
      case ESortingOption.NEWEST: {
        if (!data[0]?.ca) return data
        return data.sort((a: any, b: any) =>
          sortingOrder == Sorting.ASC
            ? new Date(a.ca).getTime() - new Date(b.ca).getTime()
            : new Date(b.ca).getTime() - new Date(a.ca).getTime(),
        )
      }
      case ESortingOption.HIGHEST_PNL:
        return data.sort((a: any, b: any) =>
          sortingOrder == Sorting.ASC ? +a.tpl - +b.tpl : +b.tpl - +a.tpl,
        )
      case ESortingOption.WORTH: {
        if (!data[0]?.tw) return data
        return data.sort((a: any, b: any) =>
          sortingOrder == Sorting.ASC ? +a.tw.u - +b.tw.u : +b.tw.u - +a.tw.u,
        )
      }
      default:
        return data
    }
  }, [data, search, sortingOrder, activeOption])

  return { handleChange, activeSortingName: activeOption, sortedData }
}

export { useSortedData, ESortingOption }
