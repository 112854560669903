import { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { FormControlLabel, Grid, RadioGroup } from '@mui/material'

import { updateWebHook } from '@/api/device'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Input, Typography } from '@/libs/common'
import { Radio } from '@/libs/common/radio'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription.ts'
import { useModal } from '@/pages/modal-page/modal-page.tsx'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const EditWebhook: FC = () => {
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const [template, setTemplate] = useState(userTemplates?.[0].id as string)
  const [inputValue, setInputValue] = useState('')
  const location = useLocation()
  const hookInfo = location.state?.hook || {}
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)

  const { setModalProps } = useModal()
  const { mode } = useParams()

  useEffect(() => {
    setModalProps({ title: 'Edit Manual Buy Webhook' })
  }, [setModalProps])

  useEffect(() => {
    setTemplate(hookInfo.template.id)
    setInputValue(hookInfo.name)
  }, [])
  const handleTemplate = (template: string) => {
    setTemplate(template)
  }
  const navigate = useCustomNavigate()

  const editHook = async () => {
    try {
      await updateWebHook({
        id: hookInfo.id,
        name: inputValue,
        template_id: template,
        blockchain: networkId,
      })
      navigate({ isDashboard: true })
    } catch (err) {
      extractErrorDescription(err)
    }
  }

  return (
    <Grid gap={2} container flexDirection="column">
      <Grid gap={2} container flexDirection="column">
        <Input
          placeholder="Enter webhook name"
          label={'Webhook name'}
          className={styles.input}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Grid>

      <Grid gap={2} container flexDirection="column">
        <Typography variant="body2" textColor="color-grey-3">
          Select linked template
        </Typography>
        {userTemplates?.length ? (
          <RadioGroup
            value={template}
            name="balance-filters"
            onChange={(_, value) => handleTemplate(value)}
            className={styles.radioGroup}
          >
            {userTemplates.map((item) => (
              <FormControlLabel
                key={item.id}
                className={styles.radioButton}
                value={item.id}
                control={<Radio />}
                label={
                  <Typography variant="body2" textColor="color-grey-3" textAlign="center">
                    {item.name}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        ) : (
          <Typography variant="body2" textAlign="center" marginBottom={2}>
            No templates
          </Typography>
        )}
      </Grid>
      <Button styleVariant="yellow" onClick={editHook} disabled={!inputValue || !template}>
        Save
      </Button>
    </Grid>
  )
}

export { EditWebhook }
