import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'

import { TBalancesItem } from './types'

const BASE_URL = 'api/v1/indexer'

const getUserBalances = (networkId: number) => {
  const userId = getCurrentUserId()
  return api.get<TBackendResponse<TBalancesItem[]>>(
    `${BASE_URL}/wallet/${userId}/balances?b=${networkId}`,
  )
}

export { getUserBalances }
