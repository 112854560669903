import { FC, useMemo } from 'react'

import { Grid } from '@mui/material'

import { chainPriorityCurrencies } from '@/components/buy-tab/libs/constants'
import { SettingField } from '@/components/setting-field'
import { TemplateSettingsElement } from '@/components/template-settings-list/libs/components/template-settings-element'
import { Accordion, Typography } from '@/libs/common'
import { TCopyTradeTransaction } from '@/libs/types/copy-trade-transaction'
import { TEntityTemplate } from '@/libs/types/template'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  template: TEntityTemplate<TCopyTradeTransaction>
  onChange?: (value: boolean) => void
  expanded?: boolean
}

const CopyTradeTemplateSettings: FC<TProps> = ({ onChange, template, expanded }) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallets = useMemo(() => {
    if (!userWallets) return '-'

    const walletsIndexes: number[] = []
    template.setup.wallets.forEach((wallet) => {
      const userWalletIndex = userWallets.findIndex((item) => item.address === wallet.public_key)
      if (userWalletIndex !== -1) {
        walletsIndexes.push(userWalletIndex + 1)
      }
    })
    return `${walletsIndexes.join('/')} (${walletsIndexes.length})`
  }, [template])

  return (
    <Accordion
      className={styles.accordion}
      titleClassName={styles.accordionTitle}
      title="Template Settings"
      withDivider
      mainColorArrow
      onChange={onChange}
      expanded={expanded}
    >
      <Grid width="100%" gap={2} container>
        <Typography variant="body2" textColor="white">
          Template Settings
        </Typography>

        <Grid container flexDirection="column" gap={1.5}>
          <Typography variant="body2" textColor="white">
            Buy
          </Typography>

          <Grid container gap={3}>
            {template.setup.operation.setup.front_run_priority && (
              <Grid width="47.9%" container flexDirection="column" gap={1}>
                <TemplateSettingsElement
                  label="Frontrun buys"
                  value={template.setup.operation.setup.front_run_buys}
                />
                <TemplateSettingsElement
                  label="Frontrun priority"
                  value={`${template.setup.operation.setup.front_run_priority}`}
                />
                <TemplateSettingsElement
                  label="Backrun buys"
                  value={template.setup.operation.setup.back_run_buys}
                />
              </Grid>
            )}
            <Grid
              width={template.setup.operation.setup.front_run_priority ? '47.9%' : '100%'}
              container
              flexDirection="column"
              gap={1}
            >
              <TemplateSettingsElement
                label="Slippage"
                value={`${template.setup.operation.setup.slippage}%`}
              />
              <TemplateSettingsElement label="Wallets selected" value={wallets} />

              {!currentChain.features?.noPriorityGas && (
                <>
                  <TemplateSettingsElement
                    label="Buy priority"
                    value={`${template.setup.operation.gas.buy.miner_tip} ${chainPriorityCurrencies[currentChain.chainName]}`}
                  />

                  <TemplateSettingsElement
                    label="Approve priority"
                    value={`${template.setup.operation.gas.approve.miner_tip} ${chainPriorityCurrencies[currentChain.chainName]}`}
                  />
                </>
              )}

              <TemplateSettingsElement
                label="Degen Chad Mode"
                value={!template.setup.operation.shields.anti_honeypot}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container flexDirection="column" gap={1.5}>
          <Typography variant="body2" textColor="white">
            Safety
          </Typography>

          <Grid container gap={3}>
            {template.setup.operation.safety && (
              <Grid width="47.9%" container flexDirection="column" gap={1}>
                <TemplateSettingsElement
                  label="Anti-Rug"
                  value={template.setup.operation.safety.anti_rug}
                />

                <TemplateSettingsElement
                  label="Anti-Rug Threshold"
                  value={template.setup.operation.safety.anti_rug_theshold}
                />

                <TemplateSettingsElement
                  label="Sell-Rug Extra Tip"
                  value={template.setup.operation.safety.sell_rug_extra_tip}
                />

                <TemplateSettingsElement
                  label="Anti-Blacklist"
                  value={template.setup.operation.safety.anti_blacklist}
                />

                <TemplateSettingsElement
                  label="Transfer On Blacklist"
                  value={template.setup.operation.safety.transfer_on_blacklist}
                />
              </Grid>
            )}

            <Grid
              width={template.setup.operation.safety ? '47.9%' : '100%'}
              container
              flexDirection="column"
              gap={1}
            >
              <TemplateSettingsElement
                label="Max Buy Tax"
                value={
                  template.setup.operation.shields.buy_tax
                    ? `${template.setup.operation.shields.buy_tax}%`
                    : '-'
                }
              />
              <TemplateSettingsElement
                label="Max Sell Tax"
                value={
                  template.setup.operation.shields.sell_tax
                    ? `${template.setup.operation.shields.sell_tax}%`
                    : '-'
                }
              />

              <TemplateSettingsElement
                label="Min Liquidity"
                value={
                  template.setup.operation.shields.minimum_liquidity
                    ? `$${template.setup.operation.shields.minimum_liquidity}`
                    : '-'
                }
              />

              <TemplateSettingsElement
                label="Max Liquidity"
                value={
                  template.setup.operation.shields.maximum_liquidity
                    ? `$${template.setup.operation.shields.maximum_liquidity}`
                    : '-'
                }
              />
              <TemplateSettingsElement
                label="Max Marketcap"
                value={
                  template.setup.operation.shields.maximum_market_cap
                    ? `$${template.setup.operation.shields.maximum_market_cap}`
                    : '-'
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {template.setup.operation.gas.sell && (
          <Grid container flexDirection="column" gap={1.5}>
            <Typography variant="body2" textColor="white">
              Sell
            </Typography>

            <Grid container gap={3}>
              <Grid width="47.9%" container flexDirection="column" gap={1}>
                <TemplateSettingsElement
                  label="Sell priority"
                  value={`${template.setup.operation.gas.sell.miner_tip} ${chainPriorityCurrencies[currentChain.chainName]}`}
                />

                <SettingField title="Sell on Profit" value={''} />

                <SettingField title="Wallets to sell on Profit" value={''} />

                <SettingField title="Sell in steps" value={''} />
              </Grid>

              <Grid width="47.9%" container flexDirection="column" gap={1}>
                <SettingField title="Auto Sell" value={'Off'} />

                <SettingField title="Sell Profit Amount" value={''} />

                <SettingField title="Trailing Sell" value={'20%'} />

                <SettingField title="Sell in steps:" value={''} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Accordion>
  )
}

export { CopyTradeTemplateSettings }
