import { LocalStorageItem } from '@/libs/enums'
import { TPrioritiesDefaultValues } from '@/libs/types'
import { store } from '@/store'

export const getPrioritiesDefault = () => {
  const storagePriorities = localStorage.getItem(LocalStorageItem.PRIORITIES)
  const storePriorities = store.getState().user.defaultPriorities
  return storagePriorities
    ? (JSON.parse(storagePriorities) as TPrioritiesDefaultValues)
    : {
        sellPriority: storePriorities?.sell_priority || '7',
        buyPriority: storePriorities?.buy_priority || '7',
        approvePriority: storePriorities?.approve_priority || '7',
      }
}
