import { FC, useCallback, useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { updateFavoritesTokensOrder } from '@/api/favorites'
import { TUserFavorite } from '@/api/favorites/types'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { AccordionListElement, ButtonIcon, TokenPlaceholder, Typography } from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { IconName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppDispatch, useAppSelector } from '@/store'
import { removeFavoriteToken } from '@/store/slices/favorites-tokens.slice'

import styles from './styles.module.scss'

type TProps = {
  isFavorites: boolean
}

const FavoriteHistoryList: FC<TProps> = ({ isFavorites }) => {
  const userFavorites = useAppSelector((state) => state.favoritesTokens.userFavorites)
  const userHistory = useAppSelector((state) => state.favoritesTokens.userHistory)
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)
  const dispatch = useAppDispatch()

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)
  const [items, setItems] = useState<TUserFavorite[] | null>(null)

  useEffect(() => {
    if (isFavorites) {
      if (userFavorites) setItems(userFavorites)
    } else {
      if (userFavorites) setItems(userHistory)
    }
  }, [userFavorites, userHistory, isFavorites])

  const handleNewTokenSelection = useSelectNewToken()

  const handleChangeOrder = (_: number, newIndex: number) => {
    const changedItem = items?.[newIndex]
    if (!changedItem) return
    updateFavoritesTokensOrder({
      blockchain: networkId,
      token: changedItem.t,
      order: (newIndex + 1).toString(),
    })
  }

  const handleRemoveFavoriteToken = (tokenAddress: string) => {
    dispatch(removeFavoriteToken({ tokenAddress, networkId }))
  }

  const renderItem = useCallback(
    (item: TUserFavorite, isDragging: boolean) => (
      <AccordionListElement
        enableBurger={isFavorites}
        className={styles.favoriteItem}
        disableHover={isSomeElementDragging && !isDragging}
        onClick={() => {
          handleNewTokenSelection(item.t)
        }}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          className={styles.container}
        >
          <div className={styles.controls}>
            {isFavorites && (
              <ButtonIcon
                icon={IconName.CROSSED_STAR}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveFavoriteToken(item.t)
                }}
              />
            )}

            <div className={styles.iconWrapper}>
              {item.i.s ? (
                <img src={item.i.s} className={styles.icon} loading="lazy" alt={item.n} />
              ) : (
                <TokenPlaceholder size="35px" />
              )}

              {/* <img
                src={item.pairImg}
                loading="lazy"
                alt="network logo"
                className={styles.network}
              /> */}
            </div>

            <Grid container flexDirection="column">
              <Typography variant="body2" className={styles.symbol}>
                {item.s}
              </Typography>

              <Typography variant="body2" className={styles.name}>
                {item.n}
              </Typography>
            </Grid>
          </div>

          <Grid className={styles.info}>
            <Typography variant="body2" className={styles.price}>
              ${formatTokenPrice(item.p).formatted}
            </Typography>

            <Typography
              variant="body2"
              textAlign="end"
              className={cn(Number(item.pc24) > 0 ? styles.profit : styles.loss)}
            >
              {formatTokenPrice(item.pc24).formatted}%
            </Typography>
          </Grid>
        </Grid>
      </AccordionListElement>
    ),
    [isSomeElementDragging, isFavorites, handleNewTokenSelection],
  )

  return (
    <Grid gap={2} display="flex" flexDirection="column">
      {items?.length ? (
        isFavorites ? (
          <DragAndDropList
            propOfItemForKey="t"
            data={items}
            setData={setItems}
            renderItem={renderItem}
            onDragStateChange={(state) => {
              setIsSomeElementDragging(state)
            }}
            onOrderChanged={handleChangeOrder}
          />
        ) : (
          items.map((item) => renderItem(item, false))
        )
      ) : (
        <Typography variant="body1" component="span" textColor="grey" align="center">
          {isFavorites ? 'No favorites' : 'No history'}
        </Typography>
      )}
    </Grid>
  )
}

export { FavoriteHistoryList }
