import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const Currency = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return <span className={styles[currentChain.description.toLowerCase()]}>Ξ</span>
}
export { Currency }
