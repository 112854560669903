import styles from '@/components/header/styles.module.scss'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { AppRoute } from '@/libs/enums'

export function ConnectWalletButton() {
  const navigate = useCustomNavigate()
  return (
    <div
      className={styles.connectWalletBtn}
      onClick={() =>
        navigate({ path: `${AppRoute.MODAL}/${AppRoute.CONNECT_WALLET}`, isDashboard: true })
      }
    >
      Connect Wallet
    </div>
  )
}
