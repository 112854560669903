type VerificationStatusType = {
  CREATE_MASTER_PASSWORD: string
  ENABLE_2FA?: string
  SETUP_WALLETS: string
  ENJOY_BLAZING_BOT: string
  VERIFY_EMAIL?: string
  SELECT_PLAN?: string
}

let VerificationStatus: VerificationStatusType = {
  CREATE_MASTER_PASSWORD: 'Master password',
  ENABLE_2FA: 'Setup 2FA',
  SETUP_WALLETS: 'Setup Wallets',
  ENJOY_BLAZING_BOT: 'Enjoy Blazing Bot',
}

function initStatus() {
  VerificationStatus = {
    CREATE_MASTER_PASSWORD: 'Master password',
    SETUP_WALLETS: 'Setup Wallets',
    ENJOY_BLAZING_BOT: 'Enjoy Blazing Bot',
  }
}

function add2FA() {
  VerificationStatus = {
    CREATE_MASTER_PASSWORD: 'Master password',
    ENABLE_2FA: 'Setup 2FA',
    SETUP_WALLETS: 'Setup Wallets',
    ENJOY_BLAZING_BOT: 'Enjoy Blazing Bot',
  }
}

initStatus()
export { VerificationStatus, add2FA, initStatus }
