import { FC, useState } from 'react'

import { Grid } from '@mui/material'

import { Button, ButtonIcon, Icon, Popover, type TOnChangeVisible, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

interface Props {
  deviceName: string
  onConfirm: () => void
}

const DeleteConfirmationDevice: FC<Props> = ({ deviceName, onConfirm }) => {
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const handleClose = () => {
    setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
  }

  return (
    <Grid container alignItems="center" gap={0.35} justifyContent="center" width="25px">
      <ButtonIcon
        tooltipTitle="Close"
        onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
      >
        <Icon name={IconName.CLOSE_GREY} />
      </ButtonIcon>
      <div className={styles.popupContainer}>
        <Popover
          className={styles.popover}
          popoverState={deletePopup}
          onChangeVisible={setDeletePopup}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography variant="body2" className={styles.title}>
            Confirm Delete
          </Typography>
          <Typography variant="body2">
            Are you sure you want to delete {deviceName} device?
          </Typography>
          <div className={styles.popoverButtons}>
            <ButtonIcon onClick={handleClose}>
              <Typography variant="body2" className={styles.cancel}>
                Cancel
              </Typography>
            </ButtonIcon>
            <Button
              className={styles.confirm}
              styleVariant="red"
              onClick={() => {
                handleClose()
                onConfirm()
              }}
            >
              Delete
            </Button>
          </div>
        </Popover>
      </div>
    </Grid>
  )
}

export default DeleteConfirmationDevice
