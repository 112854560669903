import { FC, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { TImportWalletParsed } from '@/api/wallet/types'
import { ImportPrivateKeysComponent } from '@/components/import-private-keys-component'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { Button } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { importWalletsHandler } from '@/libs/helper/importWalletsHandler'
import { EImportModalType } from '@/pages/modal-page/libs/components/import-wallets/libs/enum'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch } from '@/store'
import { fetchUserWalletsForce } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const validationSchema = yup.object({
  keys: yup.string().required().min(60),
})

const defaultValues = {
  keys: '',
}

type TProps = {
  duringSignup?: boolean
}

const ImportPrivateKeys: FC<TProps> = ({ duringSignup = false }) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const formRef = useRef<any>(null)
  const navigate = useCustomNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: '', withBackButton: !duringSignup })
  }, [duringSignup])

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    const currentValue = getValues('keys')
    if (currentValue) return

    const walletsJson = searchParams.get('wallets') || ''
    if (!walletsJson) return

    const newValue = (JSON.parse(walletsJson) as TImportWalletParsed[]).reduce((acc, item) => {
      if (item.row.trim().length) {
        acc += `${item.row}\n`
      }
      return acc
    }, '')
    setValue('keys', newValue)

    searchParams.delete('wallets')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams])

  const onSubmit: SubmitHandler<typeof defaultValues> = async ({ keys }) => {
    setLoading(true)
    const wallets = keys.split('\n').reduce((acc, row) => {
      if (!row.replace(/ /g, '')) return acc
      acc.push({
        address: '',
        row,
        private_key: row.replace(/ /g, ''),
      })

      return acc
    }, [] as TImportWalletParsed[])

    if (duringSignup) {
      await importWalletsHandler({
        wallets,
        onSuccess: async () => {
          await dispatch(fetchUserWalletsForce('')).unwrap()
          navigate({
            isDashboard: false,
            path: AppRoute.SETUP_SUCCESS,
            replace: true,
          })
        },
        // onPartialSuccess: (walletsWithError) => {
        //   walletsWithError.forEach((item) => {
        //     if (item.description) CustomToast('error', item.description)
        //   })
        // },
      })
    } else {
      navigate({
        isDashboard: true,
        path: `${AppRoute.MODAL}/${AppRoute.IMPORT_WALLETS}`,
        searchParams: {
          type: EImportModalType.PUBLIC_AND_PRIVATE_KEYS,
          wallets: JSON.stringify(wallets),
        },
        replace: true,
      })
    }
    setLoading(false)
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="keys"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <ImportPrivateKeysComponent
            placeholder="Enter wallets here"
            className={styles.textarea}
            {...field}
          />
        )}
      />
      <Button
        disabled={!isValid}
        isLoading={loading}
        onClick={() => formRef?.current?.requestSubmit()}
        checkOnAccountLock
      >
        Continue
      </Button>
    </form>
  )
}

export { ImportPrivateKeys }
