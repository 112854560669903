import { formatNumber } from './formatNumber'

const formatPercentageNumber = (value: number) => {
  return value
    ? value <= 1
      ? (value * 100).toFixed(2)
      : formatNumber(value * 100, 2).formatted
    : '-'
}

export { formatPercentageNumber }
