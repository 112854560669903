import { useState } from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

import { ToggleButtonGroupProps } from '@mui/material'
import cn from 'classnames'

import { Input } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName } from '@/libs/enums'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TErrorField = {
  message?: string
}

const buySimulationStaticInfo = {
  amount: {
    label: 'Buy with simulation amount',
    placeholder: 'ETH amount',
    tooltipInfo: 'Enter the number of simulation tokens to use for this mock purchase',
  },
  gas: {
    label: 'Buy with simulation GAS',
    placeholder: 'ETH amount',
    tooltipInfo: 'Specify the simulated gas fee for this transaction.',
  },
}

type TProps = ToggleButtonGroupProps & {
  isDisabled?: boolean
  fieldsNames?: string[]
  control: Control<any>
  errors?: Merge<FieldError, FieldErrorsImpl<{ amount?: TErrorField; gas?: TErrorField }>>
  isInitialExpanded?: boolean
}

const defaultFieldsNames = [
  'template.advancedBuy.buyWithSimulation.amount',
  'template.advancedBuy.buyWithSimulation.gas',
]

const BuyWithSimulation = ({
  isDisabled = false,
  errors,
  fieldsNames = defaultFieldsNames,
  control,
  isInitialExpanded = false,
}: TProps) => {
  const [isExpanded, setIsExpanded] = useState(isInitialExpanded)

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const staticInfo = buySimulationStaticInfo

  return (
    <>
      <SwitchInline
        label="Buy simulation"
        tooltipInfo="Allows to preview a trade before executing it"
        value={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      />

      {isExpanded && (
        <div className={styles.container}>
          <div className={styles.spend}>
            <Controller
              name={fieldsNames[0] as any}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Input
                  isNumeric
                  label={staticInfo.amount.label}
                  placeholder={staticInfo.amount.placeholder}
                  className={cn(styles.input)}
                  disabled={isDisabled}
                  tooltipInfo={staticInfo.amount.tooltipInfo}
                  endAdornment={
                    <EndAdornment
                      label={'ETH TIP'}
                      icon={(IconName as any)[currentChain.iconName]}
                      isDisabled={isDisabled}
                    />
                  }
                  error={!!errors?.amount?.message}
                  onChange={(e: any) => {
                    const eventValue = e.target.value
                    if (!eventValue) {
                      onChange(eventValue !== '' ? eventValue : '')
                      return
                    } else {
                      const newValue = convertScientificNotationNumber(
                        eventValue,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }
                  }}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name={fieldsNames[1] as any}
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Input
                  tooltipInfo={staticInfo.gas.tooltipInfo}
                  label={staticInfo.gas.label}
                  placeholder={staticInfo.gas.placeholder}
                  isNumeric
                  className={styles.input}
                  endAdornment={
                    <EndAdornment
                      label={currentChain.chainSymbol}
                      icon={(IconName as any)[currentChain.iconName]}
                      isDisabled={isDisabled}
                    />
                  }
                  disabled={isDisabled}
                  error={!!errors?.gas?.message}
                  onChange={(e: any) => {
                    const eventValue = e.target.value
                    if (!eventValue) {
                      onChange(eventValue !== '' ? eventValue : '')
                      return
                    } else {
                      const newValue = convertScientificNotationNumber(
                        eventValue,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue)
                    }
                  }}
                  {...field}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  )
}

export { BuyWithSimulation }
