import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

const ANALYTICS_EVENTS = {
  BUY_TOKENS: {
    category: 'Buying',
    action: 'Buy tokens',
  },
  SELL_TOKENS: {
    category: 'Selling',
    action: 'Sell tokens',
  },
  CREATE_MANUAL_BUY_TEMPLATE: {
    category: 'Manual buy templates',
    action: 'Create manual buy template',
  },
  DELETE_MANUAL_BUY_TEMPLATE: {
    category: 'Manual buy templates',
    action: 'Delete manual buy template',
  },
  EDIT_MANUAL_BUY_TEMPLATE: {
    category: 'Manual buy templates',
    action: 'Edit manual buy template',
  },
}

const IS_ANALYTICS_ENABLED = !!import.meta.env.VITE_GA_ID

const initAnalytics = () => {
  if (!IS_ANALYTICS_ENABLED) return
  ReactGA.initialize(import.meta.env.VITE_GA_ID)
}

const sendAnalyticsEvent = (event: UaEventOptions) => {
  if (!IS_ANALYTICS_ENABLED) return
  ReactGA.event(event)
}

export { sendAnalyticsEvent, ANALYTICS_EVENTS, initAnalytics }
