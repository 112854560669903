import { FC, PropsWithChildren } from 'react'

import { TableRow, TableRowProps } from '@mui/material'
import cn from 'classnames'

import styles from '@/libs/common/custom-table/styles.module.scss'

type TProps = PropsWithChildren & TableRowProps

const CustomTableRow: FC<TProps> = ({ children, className, ...props }) => {
  return (
    <TableRow {...props} className={cn(styles.row, className)}>
      {children}
    </TableRow>
  )
}

export { CustomTableRow }
