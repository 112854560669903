import { FC } from 'react'

import { SelectedTokenInfo } from '@/components/selected-token-info'
// import { Bubblemap } from '@/components/coming-soon-overlay'
import { Spinner } from '@/components/spinner'
import { useCurrentTokenInfoLoading } from '@/hooks/useCurrentTokenInfoLoading'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { InputSearch } from '@/libs/common'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { SpinnerSize } from '@/libs/enums'
import { useAppSelector } from '@/store'

// import { GroupBundles } from './libs/components/group-bundles/group-bundles'
import { NoTokenSelected } from './libs/components/no-token-selected'
import { TokenData } from './libs/components/token-data'
import styles from './styles.module.scss'

const SearchWithTokenInfo: FC = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { width } = useWindowDimensions()

  const isDefaultToken = selectedTokenAddress === currentChain.defaultToken ?? DEFAULT_TOKEN

  const isLoading = useCurrentTokenInfoLoading()

  return (
    <section className={styles.section}>
      <div className={styles.topPart}>
        <div className={styles.searchBlock}>
          {width < 1200 && <InputSearch placeholder="Enter token name or address" />}
        </div>

        <div className={styles.groupBlock}>
          {/* <Bubblemap />
          <GroupBundles /> */}
        </div>
      </div>

      <div className={styles.tokenInfoBlock}>
        {selectedTokenAddress ? (
          isLoading ? (
            <Spinner size={SpinnerSize.SMALL} centered />
          ) : (
            currentToken && <SelectedTokenInfo selectedToken={currentToken} />
          )
        ) : (
          <NoTokenSelected />
        )}
      </div>

      <div className={styles.additionalTokenInfo}>
        {selectedTokenAddress ? (
          isLoading ? (
            <Spinner size={SpinnerSize.SMALL} centered />
          ) : (
            (currentToken || isDefaultToken) && <TokenData isDefaultToken={isDefaultToken} />
          )
        ) : (
          <NoTokenSelected />
        )}
      </div>
    </section>
  )
}

export { SearchWithTokenInfo }
