import { useMemo } from 'react'

import { useAppSelector } from '@/store'

const useSelectedPanel = () => {
  const panels = useAppSelector((state) => state.profitSell.profitSell?.ap)
  const currentPositionId = useAppSelector((state) => state.profitSell.currentPositionId)

  return useMemo(
    () => panels?.find((item: any) => item.id === currentPositionId),
    [currentPositionId, panels],
  )
}

export { useSelectedPanel }
