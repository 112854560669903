import { FC } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  label: string
  value: string | JSX.Element
  subValue?: string | JSX.Element | null
  subValueAsValue?: boolean
  small?: boolean
  marginTop?: number
}

const BridgeInfoItem: FC<TProps> = ({
  label,
  value,
  subValue,
  subValueAsValue,
  small,
  marginTop = 0,
}) => {
  return (
    <Grid
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={cn(styles.container, { [styles.small]: small })}
      marginTop={marginTop}
    >
      <Typography variant="body1" className={styles.label}>
        {label}
      </Typography>

      <Grid display="flex" alignItems="center" gap={0.5}>
        <Typography className={styles.value}>{value}</Typography>
        {!!subValue && (
          <Typography className={cn({ [styles.value]: subValueAsValue })}>({subValue})</Typography>
        )}
      </Grid>
    </Grid>
  )
}

export { BridgeInfoItem }
