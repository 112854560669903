import { type ComponentProps } from 'react'

import { Tooltip } from '@/libs/common'

type TooltipProps = ComponentProps<typeof Tooltip>

type Props = {
  children: TooltipProps['children']
  title: TooltipProps['title']
  isOpen: boolean
}

export function WithTooltip({ children, title, isOpen }: Props) {
  if (isOpen) return children

  return (
    <Tooltip title={title} placement="right" withIcon={false}>
      {children}
    </Tooltip>
  )
}
