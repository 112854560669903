import { FC } from 'react'

import { Grid, Popover } from '@mui/material'
import cn from 'classnames'

import { Button, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  isOpen: boolean
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  onClick: () => void
}

const UnlinkPopup: FC<TProps> = ({ isOpen, anchorEl, onClose, onClick }) => {
  return (
    <Popover
      classes={styles}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: -25,
        horizontal: 118,
      }}
    >
      <Typography variant="body2" textColor="white">
        Confirm unlinking
      </Typography>

      <Typography variant="body2">
        Are you sure you want to unlink Startkind telegram account?
      </Typography>

      <Grid container flexWrap="nowrap" justifyContent="flex-end">
        <Button
          borderless
          styleVariant="basic"
          className={cn(styles.button, styles.buttonColor)}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button styleVariant="red" className={styles.button} onClick={onClick}>
          Unlink
        </Button>
      </Grid>
    </Popover>
  )
}

export { UnlinkPopup }
