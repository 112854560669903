import { useEffect } from 'react'

import { Box } from '@mui/material'
import cn from 'classnames'

import { selectedTokenData } from '@/components/selected-token-info/selectedTokenData'
import { Spinner } from '@/components/spinner'
import { ButtonCopy, Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { DividerInline } from '@/libs/common/divider-inline'
import { TokenCreated } from '@/libs/common/token-created'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { TokenSocials } from '@/libs/common/token-socials'
import { IconName, SpinnerSize } from '@/libs/enums'
import {
  checkSimulationValue,
  formatNumber,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const TokenInfo = () => {
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: !selectedToken || !simulation ? '' : `${simulation?.t.n} Info` })
  }, [selectedToken, simulation])

  const transactionsPeriod = 'one_day'
  const {
    transactions: transactionsCount,
    buys: txBuys,
    sells: txSells,
  } = selectedToken?.statistics
    ? selectedToken.statistics[transactionsPeriod].non_currency
    : { transactions: 0, buys: 0, sells: 0 }

  return !simulation && !!selectedToken ? (
    <div className={styles.spinnerWrapper}>
      <Spinner size={SpinnerSize.MEDIUM} centered />
    </div>
  ) : simulation && selectedToken ? (
    <div className={styles.wrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
        className={styles.tokenInfoWrapper}
      >
        <Box display="flex" alignItems="center" gap={1.5}>
          {selectedToken?.token.images.small ? (
            <img
              src={selectedToken.token.images.small}
              className={styles.tokenLogo}
              loading="lazy"
              alt={selectedToken.pair.name}
            />
          ) : (
            <TokenPlaceholder size="44px" />
          )}
          <div>
            <Box display="flex" gap={1.5} alignItems="center">
              <Typography variant="body1" className={cn(styles.symbol)}>
                {selectedToken?.token.symbol}
              </Typography>

              <TokenSocials gap={1.5} selectedToken={selectedToken} isActive={true} />
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <ButtonCopy value={selectedToken.token.address} />
              <Typography variant="body1" className={styles.address}>
                {hideWalletAddress(selectedToken.token.address)}
              </Typography>
            </Box>
          </div>
        </Box>
        <TokenCreated
          className={styles.tokenCreated}
          status={'TRADING'}
          createdAt={selectedToken.pair.created_at}
        />
      </Box>

      <DividerInline>
        <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
          General
        </Typography>
      </DividerInline>
      <div className={styles.tokenDataContainer}>
        <TokenDataItem
          title="Price / Price change"
          titleClassName={styles.fieldDescription}
          value={
            <>
              <Typography className={styles['ff-15']} textColor="color-grey-3" height="21px">
                ${formatNumber(simulation?.p.ou).formatted}
              </Typography>
              <Typography className={styles['ff-13']} textColor="color-grey-2">
                <span
                  className={
                    +selectedToken.token.price_change_24h < 0
                      ? styles.redText
                      : selectedToken.token.price_change_24h !== '-'
                        ? styles.greenText
                        : ''
                  }
                >
                  {+selectedToken.token.price_change_24h > 0 && '+'}
                  {Math.abs(+selectedToken.token.price_change_24h * 100).toFixed(2)}%
                </span>
                {simulation?.l.b !== '-' && <span> (24h)</span>}
              </Typography>
            </>
          }
        />

        <TokenDataItem
          title="Liq/ MKT CAP"
          titleClassName={styles.fieldDescription}
          value={
            simulation && (
              <>
                <Box
                  className={styles.liquidityAmount}
                  alignItems="center"
                  display="flex"
                  gap={0.5}
                >
                  <Typography className={styles['ff-15']} textColor="color-grey-3">
                    {formatTokenPrice(simulation.l.p.vu).formatted}
                  </Typography>
                  <Icon name={IconName.LOCK_GREEN} />
                </Box>

                <Typography className={styles['ff-13']}>
                  {simulation && `${formatTokenPrice(simulation.t.mc).raw}`}
                </Typography>
              </>
            )
          }
        />

        {/* <TokenDataItem
          title="Token Locked"
          tooltipInfo="The duration of the token's liquidity pool is locked or unlocked."
          value={
            <Typography
              variant="body2"
              className={cls(
                simulation?.additional_data?.is_pair_locked.status
                  ? styles.greenText
                  : styles.grayText,
              )}
            >
              {simulation?.additional_data?.is_pair_locked.status ? 'Yes' : 'No'}{' '}
              <span>
                {!!simulation?.additional_data?.is_pair_locked.status &&
                  simulation?.additional_data?.is_pair_locked.unlock_date &&
                  `(${countDays(simulation?.additional_data?.is_pair_locked.unlock_date)}d)`}
              </span>
              <div>
                <div className={styles.additionalInfo}>
                  {simulation?.additional_data?.is_pair_locked.locked_percentage}
                </div>
              </div>
            </Typography>
          }
        /> */}

        <TokenDataItem
          title="VOL / TXs"
          titleClassName={styles.fieldDescription}
          value={
            simulation && (
              <>
                <Typography className={styles['ff-15']} textColor="color-grey-3">
                  $
                  {
                    formatNumber(
                      +(simulation?.additional_data?.volume || selectedToken.token.volume_24h),
                    ).formatted
                  }
                </Typography>
                <Box display="flex">
                  <Typography className={styles['ff-13']} textColor="color-grey-2">
                    <span className={styles.txVolume}>{transactionsCount || ''}</span>
                    <span className={styles.greenText}>{txBuys || '-'}</span>
                    <span className={cn(styles.txSellCount, styles.redText)}>{txSells || '-'}</span>
                  </Typography>
                </Box>
              </>
            )
          }
          className={cn(styles.fieldValue, simulation?.t.r ? styles.greenText : styles.redText)}
        />

        <TokenDataItem
          title="Total Supply"
          tooltipInfo="The total number of tokens that have been issued."
          titleClassName={styles.fieldDescription}
          className={styles.fieldValue}
          value={
            simulation?.t.ts != '-' ? (
              <>
                {formatNumber(simulation?.t.ts).formatted} {simulation.t.s}
              </>
            ) : (
              '-'
            )
          }
        />

        <TokenDataItem
          title="Owner Supply"
          titleClassName={styles.fieldDescription}
          tooltipInfo="The percentage of tokens held by the token creator."
          value={`${simulation?.t.ow}%`}
          className={styles.fieldValue}
        />

        <TokenDataItem
          title="Holder Count"
          titleClassName={styles.fieldDescription}
          tooltipInfo="How many unique wallets hold the token."
          value={simulation?.t.ho || 0}
          className={styles.fieldValue}
        />

        <TokenDataItem
          title="Gain"
          titleClassName={styles.fieldDescription}
          tooltipInfo="How much the token has grown from the first candle to the current moment."
          value={`${simulation?.t.g || 0}x`}
          isGreen={!!simulation?.t.g}
          className={styles.fieldValue}
        />

        <TokenDataItem
          title="Has Blacklist"
          titleClassName={styles.fieldDescription}
          tooltipInfo="Indicates whether the token developer can block holder wallets. Once blacklisted, tokens in those wallets cannot be sold."
          value={selectedTokenData.hasBlacklist}
          className={styles.fieldValue}
        />

        <TokenDataItem
          title="Burn LP"
          titleClassName={styles.fieldDescription}
          tooltipInfo="Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%."
          value={simulation?.l.b === '-' ? simulation?.l.b : `${simulation?.l.b}%`}
          className={cn(
            styles.fieldValue,
            simulation?.l.b && +simulation?.l.b > 50 ? styles.greenText : '',
          )}
        />
      </div>

      <DividerInline>
        <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
          Trading
        </Typography>
      </DividerInline>
      <div className={styles.tokenDataContainer}>
        {/* <TokenDataItem
          title="Marketcap"
          value={`$${formatTokenPrice(simulation?.t.mc).raw}`}
          isGreen
        />*/}

        {/* <TokenDataItem
          title="Top 10 holders %"
          tooltipInfo="Cumulative percentage of total supply held by the 10 largest holders. The smaller the better."
          value={simulation.additional_data?.holders.top10Holders || '-'}
        /> */}

        <TokenDataItem
          title="Max Buy / Wallet"
          tooltipInfo="How many tokens can be purchased per transaction / how many tokens can be held per wallet."
          titleClassName={styles.fieldDescription}
          className={styles.fieldValue}
          value={
            simulation && (
              <>
                <Typography className={cn(styles['ff-15'], styles.defaultColor)}>
                  <div>{`${getPercentage(simulation.lt.b.p)} /
                    ${getPercentage(simulation.lt.w.p)}`}</div>
                </Typography>
                <Typography className={styles['ff-13']}>
                  {checkSimulationValue(simulation.lt.b.ccc)
                    ? formatTokenPrice(simulation.lt.b.ccc).formatted
                    : '-'}{' '}
                  /{' '}
                  {checkSimulationValue(simulation.lt.w.ccc)
                    ? formatTokenPrice(simulation.lt.w.ccc).formatted
                    : '-'}
                </Typography>
              </>
            )
          }
        />

        <TokenDataItem
          title="Tax Buy / Sell / Transfer"
          titleClassName={styles.fieldDescription}
          tooltipInfo="The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring."
          value=""
        >
          <Box display="flex" gap={0.75}>
            <span className={cn(styles.taxBuy, styles[getTaxesColor(simulation.tx.b)])}>
              {getPercentage(simulation.tx.b)}
            </span>{' '}
            <span className={cn(styles.taxSell, styles[getTaxesColor(simulation.tx.s)])}>
              {getPercentage(simulation.tx.s)}
            </span>{' '}
            <span className={cn(styles.taxTransfer, styles[getTaxesColor(simulation.tx.t)])}>
              {getPercentage(simulation.tx.t)}
            </span>
          </Box>
        </TokenDataItem>

        <TokenDataItem
          title="Clog"
          titleClassName={styles.fieldDescription}
          tooltipInfo="How many tokens are stored in the smart contract waiting to be sold. The best value is 0%."
          value={`${simulation?.t.c}%`}
          className={cn(styles.fieldValue, styles[getClogColor(+(simulation?.t.c || '0'))])}
        />
      </div>

      <DividerInline>
        <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
          Audit
        </Typography>
      </DividerInline>
      <div className={styles.tokenDataContainer}>
        <TokenDataItem
          title="Honeypot"
          tooltipInfo="If the token is a Honeypot, purchased tokens will not be able to be sold."
          value={simulation?.t.h ? 'Yes' : 'No'}
          titleClassName={styles.fieldDescription}
          className={cn(styles.fieldValue, !simulation?.t.h ? styles.greenText : styles.redText)}
        />

        <TokenDataItem
          title="Contract Verified"
          tooltipInfo="If yes, it means that the source code of the contract is published and can be analyzed."
          value={simulation.t.t ? 'Yes' : 'No'}
          titleClassName={styles.fieldDescription}
          className={cn(styles.fieldValue, simulation?.t.t ? styles.greenText : styles.redText)}
        />

        <TokenDataItem
          title="Renounced"
          tooltipInfo="When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced."
          value={simulation?.t.r ? 'Yes' : 'No'}
          titleClassName={styles.fieldDescription}
          className={cn(styles.fieldValue, simulation?.t.r ? styles.greenText : styles.redText)}
        />

        {/*<TokenDataItem*/}
        {/*  title="Vol 24h"*/}
        {/*  tooltipInfo="Trading volume (buy volume + sale volume) for the previous 24 hours."*/}
        {/*  value={<>${formatNumber(+(simulation?.additional_data?.volume || '0')).formatted}</>}*/}
        {/*/>*/}
      </div>
    </div>
  ) : (
    <div>
      <Typography variant="body1" align="center">
        Current token simulation is not available
      </Typography>
      <Typography variant="body1" align="center">
        Please, try it later
      </Typography>
    </div>
  )
}

export { TokenInfo }
