import { FC } from 'react'

import cn from 'classnames'

import { Typography } from '@/libs/common'
import { EAffiliateSnapshotStatus } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  status: EAffiliateSnapshotStatus
}

const StatusIndicator: FC<TProps> = ({ status }) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.indicator, styles[status])} />
      <Typography variant="h6" className={styles.label}>
        {status}
      </Typography>
    </div>
  )
}

export { StatusIndicator }
