import { FC } from 'react'

import { CheckboxProps, Checkbox as DefaultCheckBox, FormControlLabel } from '@mui/material'
import cls from 'classnames'

import { Typography } from '@/libs/common/typography'

import styles from './styles.module.scss'

type TCheckboxProps = CheckboxProps & {
  whiteLabel?: boolean
  label?: string
  value?: string
}

const Checkbox: FC<TCheckboxProps> = ({ label, value, checked, whiteLabel, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <FormControlLabel
        control={
          <div className={styles.container}>
            <DefaultCheckBox
              size="small"
              disableRipple
              classes={styles}
              value={value}
              checked={checked}
              sx={{
                alignItems: 'center',
                '&.Mui-checked': {
                  color: ' #ffc800',
                },
              }}
              {...props}
            />
            <div className={cls({ [styles.active]: (value || checked) && whiteLabel })}></div>
          </div>
        }
        label={<Typography variant="body2">{label}</Typography>}
        value={value}
        className={styles.checkbox}
      />
    </div>
  )
}

export { Checkbox, type TCheckboxProps }
