import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'

import { TGetSwapInfoPayload, TGetSwapInfoResponse, TSwapPayload } from './types'

const BASE_URL = 'api/v1/bridge'

const getSwapParams = (payload: TGetSwapInfoPayload) => {
  const userId = getCurrentUserId()
  const { source_blockchain, ...body } = payload
  return api.post<TBackendResponse<TGetSwapInfoResponse>>(
    `${BASE_URL}/base/${userId}/params?b=${source_blockchain}&l=en`,
    body,
  )
}

const swapTokens = (payload: TSwapPayload) => {
  const userId = getCurrentUserId()
  const { source_blockchain, ...body } = payload
  return api.post(`${BASE_URL}/base/${userId}/swap?b=${source_blockchain}&l=en`, body)
}

export { swapTokens, getSwapParams }
