import { AuthCard } from '@/components/auth-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Button, Icon } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppMode, AppRoute, IconName, LocalStorageItem, VerificationStatus } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const SetupSuccess = () => {
  const navigate = useCustomNavigate()
  const handleNewTokenSelection = useSelectNewToken()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const redirectToDashboard = async () => {
    localStorage.removeItem('signData')
    localStorage.setItem(LocalStorageItem.MODE, AppMode.LITE)
    if (localStorage.cachedPath) {
      navigate({
        isDashboard: true,
        path: localStorage.cachedPath,
      })
    }

    await handleNewTokenSelection(currentChain.defaultToken, {
      skipTokenSetToUrl: !localStorage.cachedPath,
      customAppMode: AppMode.LITE,
      isForceNavigate: !localStorage.cachedPath,
      skipRedirect: !!localStorage.cachedPath,
    })

    if (localStorage.code) {
      navigate({
        path: `${AppRoute.ADD_DEVICE}`,
        searchParams: {
          code: localStorage.code,
        },
      })
    }
  }
  return (
    <AuthCard
      verificationStatus={VerificationStatus.ENJOY_BLAZING_BOT}
      className={styles.card}
      isHeaderButtons
    >
      <div className={styles.content}>
        <div className={styles.desc}>
          <Icon name={IconName.SUCCESS} />
          <Typography variant="h1" align="center">
            Success
          </Typography>
          <Typography variant="body1" align="center">
            Your account is set up and your pro plan is now active, you can access the dashboard
          </Typography>
        </div>

        <Button onClick={redirectToDashboard}>Continue to dashboard</Button>
      </div>
    </AuthCard>
  )
}

export { SetupSuccess }
