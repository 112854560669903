import { Grid } from '@mui/material'
import moment from 'moment'

import { UserStatisticWithProgress } from '@/components/user-statistic-with-progress/user-statistic-with-progress'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Tabs, Typography } from '@/libs/common'
import { AppRoute, TabName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { Affiliates, Security, Servers } from './libs/components'
import { Account } from './libs/components/account/account'
import styles from './styles.module.scss'

//
// const defaultValues = {
//   userName: 'Startkind',
//   email: 'design@startkind.com',
//   password: '',
//   twoFA: true,
// }

const tabs = [
  {
    label: 'Account',
    content: <Account />,
  },
  {
    label: 'Security',
    content: <Security />,
  },
  {
    label: 'Affiliates',
    content: <Affiliates />,
  },
  // {
  //   label: 'Notifications',
  //   content: <Notification />,
  // },
  {
    label: 'Devices',
    content: <Servers />,
  },
]

const UserSettings = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const userTemplates = useAppSelector((state) => state.user.userTemplates)
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const navigate = useCustomNavigate()

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.userDataWrapper}>
          <div className={styles.planType}>
            <Grid container flexWrap="nowrap" alignItems="center">
              <Grid container gap={3}>
                <Grid>
                  <Typography
                    variant="body2"
                    textColor="light-grey"
                    className={styles.planDescription}
                  >
                    {userData?.username}
                  </Typography>
                  <Typography className={styles.planDescriptionSize}>{userData?.email}</Typography>
                </Grid>

                <Grid>
                  <Typography className={styles.planDescriptionSize}>Active Plan</Typography>
                  <Typography
                    variant="body2"
                    textColor="light-grey"
                    className={styles.planDescription}
                  >
                    {userData?.plan.name}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography className={styles.planDescriptionSize}>Referred by</Typography>
                  <Typography
                    variant="body2"
                    textColor="light-grey"
                    className={styles.planDescription}
                  >
                    {userData?.referred_by || '-'}
                  </Typography>
                </Grid>
              </Grid>

              {/* <div className={styles.buttonWrapper}>
                <Button className={styles.button}>UPGRADE</Button>
              </div> */}
            </Grid>
          </div>

          {!!userData && (
            <div className={styles.accountDates}>
              <Typography variant="body2" className={styles.accountDatesDescription}>
                Enrollment Date:{' '}
                <span className={styles.accountDatesSpan}>{userData?.created_at}</span>
              </Typography>

              {/* <Typography variant="body2" className={styles.accountDatesDescription}>
              Expiration Date: <span className={styles.accountDatesSpan}>2023-01-01</span>
            </Typography>

            <Grid container alignItems="center" gap={0.5} width="max-content">
              <Typography variant="body2" className={styles.accountDatesDescription}>
                Auto renew
              </Typography>

              <Switch />
            </Grid>

            <Typography variant="body2" className={styles.accountDatesDescription}>
              Total Days: <span className={styles.accountDatesSpan}>300</span>
            </Typography> */}

              <Typography variant="body2" className={styles.accountDatesDescription}>
                Consumed Days:{' '}
                <span className={styles.accountDatesSpan}>
                  {moment().diff(moment(userData.created_at), 'days')}
                </span>
              </Typography>

              {/* <Typography variant="body2" className={styles.accountDatesDescription}>
              Days Remaining: <span className={styles.accountDatesSpan}>300</span>
            </Typography> */}
            </div>
          )}

          <div className={styles.userStatistic}>
            {/* <UserStatisticWithProgress title="Pending orders" value={30} /> */}

            <UserStatisticWithProgress
              title="Templates active"
              value={userTemplates.buyTemplates?.length || 0}
              maxValue={userData?.plan.max_templates}
              onClick={() =>
                navigate({
                  isDashboard: true,
                  path: `${AppRoute.MODAL}/${AppRoute.TEMPLATE_MANAGEMENT}`,
                })
              }
            />

            <UserStatisticWithProgress
              title="Wallets active"
              value={userWallets?.length || 0}
              maxValue={userData?.plan.max_wallets}
              onClick={() =>
                navigate({
                  isDashboard: true,
                  path: `${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`,
                })
              }
            />

            {/* <UserStatisticWithProgress title="Consumed servers" value={50} />

            <UserStatisticWithProgress title="P/S panels active" value={10} /> */}
          </div>

          <Tabs name={TabName.SETTING_NAV} tabs={tabs} />
        </div>

        <Typography textAlign="center" className={styles.copyright}>
          © 2024 BlazingBot &middot; <span className={styles.terms}>Terms of Service</span>{' '}
          &middot; Privacy policy
        </Typography>
      </div>
    </section>
  )
}

export { UserSettings }
