import { makeSell } from '@/api/orders'
import { TransactionResponseType } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { TSellPayload } from '@/libs/types'

export const handleSell = async (payload: TSellPayload) => {
  try {
    //mock to test loading
    // await new Promise((resolve) => setTimeout(resolve, 1000))
    // console.log(payload)

    const { data } = await makeSell(payload)
    if (Array.isArray(data?.data)) {
      data.data.forEach((el: any) => processTransactionResponse(el, TransactionResponseType.SELL))
    } else {
      processTransactionResponse(data, TransactionResponseType.SELL)
    }
  } catch (err) {
    handleError(err)
  }
}
