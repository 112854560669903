import { FC, useEffect, useMemo, useState } from 'react'

import { Grid } from '@mui/material'
import Stack from '@mui/material/Stack'

import { ButtonLink, ListButton } from '@/libs/common'
import { SearchTextfield } from '@/libs/common/input-search/libs'
import { AppMode, AppRoute } from '@/libs/enums'
import { AddTokenButton } from '@/pages/modal-page/libs/components/add-token/index'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const AddToken: FC = () => {
  const userHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket)
  const positions = useAppSelector((state) => state.profitSell.profitSell?.ap)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [search, setSearch] = useState('')

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Add new token to Positions Panel ' })
  }, [])

  const searchedHoldings = useMemo(() => {
    const keyword = search.toLowerCase()
    const selectedTokensAddresses = positions
      ?.filter((el: any) => el.v && !!el.tb)
      .map((el: any) => el.ta)

    return userHoldings?.v.t.filter(
      (holding) =>
        !selectedTokensAddresses?.includes(holding.a) &&
        (holding.n?.toLowerCase().includes(keyword) || holding.a.toLowerCase().includes(keyword)),
    )
  }, [search, positions, userHoldings])

  return (
    <Stack className={styles.stack}>
      <Grid>
        <SearchTextfield
          value={search}
          onChange={(event) => {
            setSearch(event.currentTarget.value)
          }}
          variant="standard"
          className={styles.search}
          placeholder="Enter token name or address"
        />

        <Stack maxHeight="80vh" overflow="auto" spacing={2} useFlexGap marginTop={2}>
          {searchedHoldings?.map((holding) => (
            <ButtonLink
              key={holding.a}
              to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.PROFIT_SELL_ADD_TOKEN_SETUP}/${holding.a}`}
            >
              <ListButton>
                <AddTokenButton holding={holding} />
              </ListButton>
            </ButtonLink>
          ))}
        </Stack>
      </Grid>
    </Stack>
  )
}

export { AddToken }
