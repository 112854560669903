import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { collect, disperse } from '@/api/orders'
import {
  TCollectPayload,
  TCollectWallet,
  TDispersePayload,
  TDisperseWallet,
} from '@/api/orders/types'
import { useAppMode } from '@/hooks/useAppMode'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Button, Typography } from '@/libs/common'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { ETransferMode, TransactionResponseType } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { ECollectMode } from './libs/enums'
import styles from './styles.module.scss'

type TPayload = {
  type: ETransferMode
  token: THoldingsToken
  wallets: string[]
  minerTip: number
  amount: string
  percentage?: number
}

const ConfirmTransfer: FC = () => {
  const transferData = useLocation()?.state?.payload as TPayload | undefined
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [isLoading, setIsLoading] = useState(false)

  const { mode } = useAppMode()
  const navigate = useCustomNavigate()

  const currentWallet = useWalletFromParams()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Confirm transfer', withBackButton: true })
  }, [])

  const onTransfer = async () => {
    try {
      if (!transferData || !currentWallet) return
      setIsLoading(true)
      const wallets = transferData.wallets.map((publicKey) => {
        if (transferData.type === ETransferMode.COLLECT) {
          const mode = transferData.percentage ? ECollectMode.PERCENTAGE : ECollectMode.TOKENS

          const payload: TCollectWallet = {
            public_key: publicKey,
            mode,
            percentage: mode === ECollectMode.PERCENTAGE ? +(transferData.percentage || 0) : 0,
            amount:
              mode === ECollectMode.TOKENS
                ? +convertScientificNotationNumber(
                    +transferData.amount / transferData.wallets.length,
                    MAX_TRX_DECIMALS,
                  )
                : 0,
          }

          return payload
        }

        return {
          public_key: publicKey,
          amount: +convertScientificNotationNumber(+transferData.amount, MAX_TRX_DECIMALS),
        } as TDisperseWallet
      })

      const payload = {
        blockchain: currentChain.indexerChainId,
        mode: transferData.token ? 1 : 0,
        token: transferData.token?.a || currentChain.nativeTokenAddress,
        wallets: {
          main_wallet: currentWallet.address,
          wallets,
        },
        gas: {
          gas_limit: 1000000,
          auto_calculate: true,
          gas: currentChain.features?.noPriorityGas ? transferData.minerTip : 10.0,
          miner_tip: currentChain.features?.noPriorityGas ? 0 : transferData.minerTip,
        },
      }

      let response
      if (transferData.type === ETransferMode.COLLECT) {
        response = await collect(payload as TCollectPayload)
      } else {
        response = await disperse(payload as TDispersePayload)
      }
      processTransactionResponse(
        response.data,
        transferData.type === ETransferMode.COLLECT
          ? TransactionResponseType.COLLECT
          : TransactionResponseType.TRANSFER,
      )

      setIsLoading(false)
      navigate({ isDashboard: true })
    } catch (err) {
      extractErrorDescription(err)
      setIsLoading(false)
      navigate({ isDashboard: true })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              Asset
            </Typography>
            <Typography variant="body2" className={styles.main}>
              {transferData?.token
                ? `${transferData.token.n} (${transferData.token.s})`
                : `${currentChain.label} (${currentChain.chainSymbol})`}
            </Typography>
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              From
            </Typography>
            {transferData?.type === ETransferMode.COLLECT ? (
              <div>
                {transferData?.wallets.map((public_key) => {
                  const wallet = userWallets?.find((item) => item.address === public_key)
                  return (
                    <div className={styles.values} key={public_key}>
                      <Typography variant="body2" className={styles.main}>
                        {wallet?.name}
                      </Typography>
                      <div className={styles.walletsWrapper}>
                        <Typography variant="body2" className={styles.additional}>
                          {wallet
                            ? `(${hideWalletAddress(wallet.address)})`
                            : public_key && hideWalletAddress(public_key)}
                        </Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  {currentWallet?.name}
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  ({currentWallet && hideWalletAddress(currentWallet.address)})
                </Typography>
              </div>
            )}
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              To
            </Typography>
            {transferData?.type === ETransferMode.COLLECT ? (
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  {currentWallet?.name}
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  ({currentWallet && hideWalletAddress(currentWallet.address)})
                </Typography>
              </div>
            ) : (
              <div>
                {transferData?.wallets.map((public_key) => {
                  const wallet = userWallets?.find((item) => item.address === public_key)
                  return (
                    <div className={styles.values} key={public_key}>
                      {!!wallet && (
                        <Typography variant="body2" className={styles.main}>
                          {wallet.name}
                        </Typography>
                      )}
                      <div className={styles.walletsWrapper}>
                        <Typography variant="body2" className={styles.additional}>
                          {wallet
                            ? `(${hideWalletAddress(wallet.address)})`
                            : public_key && hideWalletAddress(public_key)}
                        </Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={styles.section}>
            <Typography variant="body2" className={styles.main}>
              Amount
            </Typography>
            <div className={styles.values}>
              {transferData?.percentage ? (
                <Typography variant="body2" className={styles.main}>
                  {transferData?.percentage} %{' '}
                  {!!transferData.amount && (
                    <>
                      ({formatNumber(transferData.amount).formatted}{' '}
                      {transferData.token?.s || currentChain.chainSymbol})
                    </>
                  )}
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" className={styles.main}>
                    {formatNumber(transferData?.amount || '0').formatted}{' '}
                    {transferData?.token ? transferData.token.s : currentChain.chainSymbol}
                  </Typography>
                  {/* <Typography variant="body2" className={styles.additional}>
                    (~${transferData?.wallets.wallets[0].amount.valueUSD.toFixed(2)})
                  </Typography> */}
                </>
              )}
              {transferData &&
                transferData.wallets.length > 1 &&
                transferData.type !== ETransferMode.COLLECT && (
                  <Typography variant="body2" className={styles.main}>
                    each
                  </Typography>
                )}
            </div>
          </div>
          <div className={styles.total}>
            {/* <div className={styles.totalSection}>
              <Typography variant="body2" className={styles.main}>
                Network fee
              </Typography>
              <div className={styles.values}>
                <Typography variant="body2" className={styles.main}>
                  0.00070043 ETH
                </Typography>
                <Typography variant="body2" className={styles.additional}>
                  (~$11.83)
                </Typography>
              </div>
            </div> */}
            {/* <div className={styles.totalSection}>
              <Typography variant="body2" className={cls(styles.light, styles.bold)}>
                Total
              </Typography>
              <div className={styles.values}>
                <Typography variant="body2" className={cls(styles.light, styles.bold)}>
                  0.10070043 ETH
                </Typography>
                <Typography variant="body2" className={styles.bold}>
                  (~$112.83)
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Button onClick={onTransfer} isLoading={isLoading}>
        Transfer
      </Button>
    </div>
  )
}

export { ConfirmTransfer }
