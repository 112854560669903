import { FC } from 'react'

import { Button, ButtonCopy, Checkbox } from '@/libs/common'
import { Typography } from '@/libs/common/typography'

import styles from '../../styles.module.scss'
import { TAuthenticator } from '../types'

type TProps = {
  goForward: () => void
  setEnableCode: React.Dispatch<React.SetStateAction<boolean>>
  enableCode: boolean
  setStored: React.Dispatch<React.SetStateAction<boolean>>
  stored: boolean
  authenticator: TAuthenticator
}

const GenerateMasterPassword: FC<TProps> = ({
  goForward,
  setEnableCode,
  enableCode,
  authenticator,
  stored,
  setStored,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.titleWrapper}>
        <Typography variant="h1" className={styles.title} align="center">
          Master password
        </Typography>
        <Typography variant="h2" textColor="color-grey-2" align="center">
          This master password ensures that your wallets are encrypted with your password,
          dramatically increasing the security of the system.
        </Typography>
      </div>
      <div className={styles.body}>
        <div className={styles.copyContainer}>
          <div>
            <Typography variant="body2" textColor="color-grey-2">
              Master password
            </Typography>
            <Typography variant="body1" textColor="color-grey-3">
              {authenticator.master}
            </Typography>
          </div>
          <div className={styles.btn}>
            <ButtonCopy value={authenticator.master} className={styles.copyBtn} />
          </div>
        </div>
        <Checkbox
          label="I have securely stored the master password for my account and understand that if I forget it, I will not be able to recover the account."
          value="option1"
          checked={stored}
          onChange={() => setStored(!stored)}
        />
        <Checkbox
          label="(Optional) I want to enable two-factor authentication for extra security."
          value="option2"
          checked={enableCode}
          onChange={() => setEnableCode(!enableCode)}
        />
      </div>

      <Button disabled={!stored} type="submit" onClick={goForward}>
        Continue
      </Button>
    </div>
  )
}

export { GenerateMasterPassword }
