import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { addUserFavoriteToken, deleteUserFavoriteToken } from '@/api/favorites'
import { TPanelHistoryOrder, TUserFavorite } from '@/api/favorites/types'
import { TOrderHistory } from '@/libs/types'

type TInitialState = {
  userFavorites: TUserFavorite[] | null
  userHistory: TUserFavorite[] | null
  orderHistory: TOrderHistory[] | null
  panelHistory: TPanelHistoryOrder[] | null
}

const initialState: TInitialState = {
  userFavorites: null,
  orderHistory: null,
  userHistory: null,
  panelHistory: null,
}

const addFavoriteToken = createAsyncThunk(
  'favoritesTokens/addFavoriteToken',
  async ({ tokenAddress, networkId }: { tokenAddress: string; networkId: number }) => {
    const { data } = await addUserFavoriteToken(tokenAddress, networkId)

    return data.data
  },
)

const removeFavoriteToken = createAsyncThunk(
  'favoritesTokens/removeFavoriteToken',
  async ({ tokenAddress, networkId }: { tokenAddress: string; networkId: number }) => {
    const { data } = await deleteUserFavoriteToken(tokenAddress, networkId)

    return data.data
  },
)

const favoritesTokensSlice = createSlice({
  name: 'favoritesTokens',
  initialState,
  reducers: {
    setUserFavourites: (state, { payload }: PayloadAction<TUserFavorite[]>) => {
      state.userFavorites = payload
    },
    setUserHistory: (state, { payload }: PayloadAction<TUserFavorite[]>) => {
      state.userHistory = payload
    },
    setOrderHistory: (state, { payload }: PayloadAction<TOrderHistory[]>) => {
      state.orderHistory = payload
    },
    setPanelHistory: (state, { payload }) => {
      state.panelHistory = payload
    },
  },
})

const { setUserFavourites, setPanelHistory, setUserHistory, setOrderHistory } =
  favoritesTokensSlice.actions

export {
  setUserFavourites,
  setUserHistory,
  setPanelHistory,
  setOrderHistory,
  favoritesTokensSlice,
  addFavoriteToken,
  removeFavoriteToken,
}
