import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { TSellForm } from '@/libs/types'
import { TWallet } from '@/libs/types/transaction'
import { store } from '@/store'

type TProps = {
  token?: string
  data?: TSellForm
  wallets: TWallet[]
  sellPercentage: number
  mode?: number
  dex?: number | string
}

const createSellPayload = async ({ token, wallets, sellPercentage, data, mode, dex }: TProps) => {
  const defaultPriorities = getPrioritiesDefault()
  const userData = store.getState().user.userData
  const currentChain = store.getState().chain.currentChain
  const tokenFromStore = store.getState().chain.currentToken?.token.address
  let currentToken
  let currentDex = dex

  if (token && token !== tokenFromStore) {
    currentToken = token

    if (currentDex === undefined) {
      const holding = store
        .getState()
        .user.userHoldingsWebsocket?.v.t.find((holding) => holding.a.toLowerCase() === token)
      currentDex = holding?.sd
    }
  }

  if (currentDex === undefined) {
    currentDex = store.getState().chain.currentTokenSimulationWebsocket?.l.sd
  }
  if (currentToken === undefined) {
    currentToken = tokenFromStore
  }

  if (!userData) return

  if (!userData || !currentToken || currentDex === undefined) return

  const sellPriority = data
    ? +data.sellPriority || +defaultPriorities.sellPriority
    : +defaultPriorities.sellPriority

  return {
    token: currentToken, // Token address a user wants to sell
    network: {
      blockchain: currentChain.indexerChainId, // Current blockchain id
      dex: +currentDex, // Selected dex service
    },
    userId: userData.user_id,
    sellPercentage: sellPercentage,
    wallets,
    operation: {
      private_tx:
        !currentChain.features?.noPrivateTx && data
          ? data.privateTransaction
          : !currentChain.features?.noPrivateTx,
      tx_type: currentChain.defaultValues?.txType || 0,
      bribe_amount: 0, // Default value
      contract: {
        separate_txs: true, // Combined transactions are not working so far, so we use separate ones
      },
      gas: {
        gas_limit: 1000000, // Default value for the internal tests. Later the limit will be calculated automatically
        auto_calculate: true,
        gas: currentChain.features?.noPriorityGas ? sellPriority : 10,
        miner_tip: currentChain.features?.noPriorityGas ? 0 : sellPriority,
      },
      pair: {
        routing: {
          mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
          path: [], // Not specified, as we only use native tokens so far
        },
        fee: {
          mode: 1, // 1 for the automatic calculation
          path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
        },
      },
      setup: {
        mode: mode || (sellPercentage ? 0 : 3), // Sell percentage of amount or sell specific amount of tokens
        sell_percentage: sellPercentage,
        sell_amount: data
          ? +convertScientificNotationNumber(
              mode === 1 ? +data.receive : +data.sell,
              MAX_TRX_DECIMALS,
            )
          : 0,
        rounds_to_sell: 1,
        slippage: data ? +data.slippage : currentChain.defaultValues.slippage,
        sell_to_another_wallet: false, // Default value
        sell_wallet: '', // Default value
      },
      // Shields are default values so far
      shields: {
        anti_tax_mode: 0,
        anti_tax: false,
        anti_duplicate: false,
        sell_tax: 0,
        anti_price_impact: false,
        price_impact: 0,
      },
    },
  }
}

export { createSellPayload }
