import { Grid } from '@mui/material'

import { Typography } from '@/libs/common'

const BrowserNotSupportedMessage = () => {
  return (
    <Grid
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
    >
      <Typography variant="h1" textAlign="center">
        Your browser is not supported at this time.
        <br />
        Please consider using a different browser to access this site.
      </Typography>
    </Grid>
  )
}

export { BrowserNotSupportedMessage }
