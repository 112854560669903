import { FC, PropsWithChildren } from 'react'

import { TableCell, TableCellProps } from '@mui/material'
import cn from 'classnames'

import styles from '@/libs/common/custom-table/styles.module.scss'

type TProps = PropsWithChildren & TableCellProps

const CustomTableHeadCell: FC<TProps> = ({ children, className, ...props }) => {
  return (
    <TableCell {...props} className={cn(styles.tableHeadCell, className)}>
      {children}
    </TableCell>
  )
}

export { CustomTableHeadCell }
