import cn from 'classnames'

import { TSelectOption } from '@/libs/types/select-option.type'

import { ButtonGroupRadio, ButtonGroupRadioButton } from '../button-group-radio'
import styles from './styles.module.scss'

type TProps<T> = {
  value: T
  setValue: (value: T) => void
  options: TSelectOption<T>[]
}

const FilterRadio = <T extends string | number>({ value, setValue, options }: TProps<T>) => {
  return (
    <ButtonGroupRadio
      className={styles.group}
      value={value}
      onChange={(_: React.BaseSyntheticEvent, newValue: T) => setValue(newValue)}
      exclusive
    >
      {options.map(({ value: option, label }) => (
        <ButtonGroupRadioButton
          key={option}
          value={option}
          className={cn(styles.button, { [styles.active]: value === option })}
        >
          {label}
        </ButtonGroupRadioButton>
      ))}
    </ButtonGroupRadio>
  )
}

export { FilterRadio }
