import moment from 'moment'

const calculateTimeFromNow = (dateSeconds: number) => {
  const start = moment()
  const end = moment.unix(dateSeconds)

  const diff = start.diff(end)
  const duration = moment.duration(diff)

  const years = Math.floor(duration.asYears())
  const days = Math.floor(duration.asDays())
  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes())
  const seconds = Math.floor(duration.asSeconds())

  if (seconds < 60) {
    return `${seconds}s`
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (hours < 24) {
    return `${hours}h`
  } else if (days < 365) {
    return `${days}d`
  } else {
    return `${years}y`
  }
}

export { calculateTimeFromNow }
