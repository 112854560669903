import { TSelectOption } from '@/libs/types/select-option.type'

const spendValuesOptions: TSelectOption<number>[] = [
  {
    value: 0.05,
    label: '0.05',
  },
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
]

const spendPercentageOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]

const addressLength = 42

export { spendValuesOptions, spendPercentageOptions, addressLength }
