import { FC, useMemo } from 'react'

import styles from './styles.module.scss'

type TProperty = {
  href: string
  label: string
}

const Link: FC<TProperty> = ({ href, label }) => {
  const validatedLink = useMemo(() => {
    if (!href.includes('https')) {
      return 'https://' + href
    } else {
      return href
    }
  }, [href])

  return (
    <a href={validatedLink} className={styles.link}>
      {label}
    </a>
  )
}

export { Link }
