import { Grid } from '@mui/material'
import cn from 'classnames'

import { Chart } from '@/components/copy-trade/libs/components'
import { TimeFrameToggle } from '@/components/time-frame-toggle'
import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

const data = {
  totalInvested: '4441.434354 ETH',
  worth: '121.434354 ETH',
  profitLoss: 44.53,
  realizedProfit: '12431.434354 ETH',
  unrealizedProfit: '331.434354 ETH',
}

const options = [
  { value: '1D' },
  { value: '7D' },
  { value: '1M' },
  { value: '1Y' },
  { value: 'All' },
]

const CopyTradeStatistics = () => {
  return (
    <div className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h2">Statistics</Typography>

        <div className={styles.timeFrameWrapper}>
          <TimeFrameToggle options={options} />
        </div>
      </Grid>

      <div className={styles.wrapper}>
        <div>
          <Typography variant="body2" className={styles.statisticDescription}>
            Total invested
          </Typography>

          <Typography className={styles.data}>{data.totalInvested}</Typography>
        </div>

        <div>
          <Typography variant="body2" className={styles.statisticDescription}>
            Worth
          </Typography>

          <Typography className={styles.data}>{data.worth}</Typography>
        </div>

        <div>
          <Typography variant="body2" className={styles.statisticDescription}>
            Profit/Loss
          </Typography>

          <Typography className={styles.data}>
            <span className={cn(data.profitLoss > 0 ? styles.profit : styles.loss)}>
              {'%'}
              {Math.abs(data.profitLoss)}
            </span>
          </Typography>
        </div>

        <div>
          <Typography variant="body2" className={styles.statisticDescription}>
            Realized Profit
          </Typography>

          <Typography className={styles.data}>{data.realizedProfit}</Typography>
        </div>

        <div>
          <Typography variant="body2" className={styles.statisticDescription}>
            Unrealized Profit
          </Typography>

          <Typography className={styles.data}>{data.unrealizedProfit}</Typography>
        </div>
      </div>

      <Chart />
    </div>
  )
}

export { CopyTradeStatistics }
