import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { base, bsc, mainnet } from '@reown/appkit/networks'
import { createAppKit } from '@reown/appkit/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'

import { App } from '@/components/app'

import './index.scss'
import { store } from './store'
import { initAnalytics } from './utils/analytics'

initAnalytics()

const root = createRoot(document.getElementById('root') as HTMLElement)
const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID

// Wagmi
const wagmiQueryClient = new QueryClient()

const metadata = {
  name: 'Web3Modal',
  description: 'BBot',
  url: 'https://app.blazingbot.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const wagmiConfig = new WagmiAdapter({
  networks: [mainnet, bsc, base],
  projectId,
  ssr: true,
})
createAppKit({
  adapters: [wagmiConfig],
  networks: [mainnet, bsc, base],
  metadata: metadata,
  projectId,
  features: {
    analytics: true,
    email: false,
    socials: false,
    smartSessions: true,
    allWallets: true,
  },
  enableWalletConnect: true,
  enableWallets: true,
})
root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <WagmiProvider config={wagmiConfig.wagmiConfig}>
        <QueryClientProvider client={wagmiQueryClient}>
          <App />
        </QueryClientProvider>
      </WagmiProvider>
    </DndProvider>
  </Provider>,
)

export { wagmiConfig }
