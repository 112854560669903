import { Box } from '@mui/material'
import cn from 'classnames'

import styles from './styles.module.scss'

const DividerInline = ({ className, children, gap = 2 }: any) => {
  return (
    <Box display="flex" alignItems="center" gap={gap} className={cn(styles.container, className)}>
      {children && children}
      <span className={styles.divider} />
    </Box>
  )
}

export { DividerInline }
