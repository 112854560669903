import { ESortingOption } from '@/hooks/useSortedData'

const sortingOptions = [
  {
    label: 'Age',
    value: ESortingOption.NEWEST,
    image: null,
  },
  {
    label: 'Worth',
    value: ESortingOption.WORTH,
    image: null,
  },
]

export { sortingOptions }
