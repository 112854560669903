import { FC, useMemo, useState } from 'react'

import { AuthCard } from '@/components/auth-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { AppRoute, VerificationStatus } from '@/libs/enums'
import { add2FA } from '@/libs/enums/verification-status.enum'

import { ConfirmMasterPassword } from './libs/components/confirm-master-password'
import { GenerateMasterPassword } from './libs/components/generate-master-password'
import { TAuthenticator } from './libs/components/types'
import { EMasterPasswordFormStep } from './libs/form-step.enum'
import styles from './styles.module.scss'

const CreateMasterPassword: FC = () => {
  const navigate = useCustomNavigate()
  const [formStep, setFormStep] = useState(EMasterPasswordFormStep.CREATE_PASSWORD)
  const [enableCode, setEnableCode] = useState(false)
  const [stored, setStored] = useState(false)

  const goForward = () => setFormStep(EMasterPasswordFormStep.CONFIRM_PASSWORD)
  const goBack = () => setFormStep(EMasterPasswordFormStep.CREATE_PASSWORD)

  const authenticator: TAuthenticator = useMemo(() => JSON.parse(localStorage.signUpData || {}), [])

  const onSubmit = () => {
    if (enableCode) {
      add2FA()
      navigate({
        path: `/${AppRoute.ENABLE_2FA}`,
        replace: false,
      })
    } else {
      navigate({ path: AppRoute.SETUP_WALLETS, replace: false })
    }
  }

  const formContent = useMemo(() => {
    switch (formStep) {
      case EMasterPasswordFormStep.CREATE_PASSWORD:
        return (
          <GenerateMasterPassword
            goForward={goForward}
            setEnableCode={setEnableCode}
            enableCode={enableCode}
            authenticator={authenticator}
            stored={stored}
            setStored={setStored}
          />
        )
      case EMasterPasswordFormStep.CONFIRM_PASSWORD:
        return (
          <ConfirmMasterPassword
            goBack={goBack}
            onSubmit={onSubmit}
            authenticator={authenticator}
          />
        )
      default:
        return null
    }
  }, [formStep, enableCode, stored])

  return (
    <AuthCard
      verificationStatus={VerificationStatus.CREATE_MASTER_PASSWORD}
      className={styles.card}
      isHeaderButtons
    >
      {formContent}
    </AuthCard>
  )
}

export { CreateMasterPassword }
