const templateDefaultValues = {
  shields: {
    buy_tax: '',
    sell_tax: '',
    minimum_liquidity: '',
    maximum_liquidity: '',
    maximum_market_cap: '',
    antiRug: false,
    antiRugThreshold: 0,
    sellRugExtraTip: '',
    transferOnBlackList: false,
    antiBlackList: false,
    duplicateBuy: false,
  },
  ordinaryBuy: {
    degenChadMode: false,
    frontrunBuys: false,
    backrunBuys: false,
    frontrunBuyPriority: '',
    amount: '',
    privateTransaction: true,
    slippage: 50,
    selectedWallets: [],
    spend: '',
    receive: '',
  },
  sell: {
    sellPriority: '',
    sellOnProfit: '',
    walletsToSellOnProfit: [],
    walletsToSellOnLoss: [],
    selectedWalletsForProfit: [],
    selectedWalletsForLoss: [],
    walletsToTrailingSell: [],
    firstStep: '10x-2',
    secondStep: '20x-2',
    thirdStep: '50x-1',
    autoSell: true,
    sellOnProfitAmount: '',
    trailingSell: '',
    frontrunSells: false,
    frontrunSellPriority: '',
    copySells: false,
    stopLoss: '',
    stopLossAmount: '',
  },
}

export { templateDefaultValues }
