import { FC, PropsWithChildren } from 'react'

import { TableCell, TableCellProps } from '@mui/material'
import cn from 'classnames'

import styles from '@/libs/common/custom-table/styles.module.scss'

type TProps = PropsWithChildren &
  TableCellProps & {
    nowrap?: boolean
  }

const CustomTableCell: FC<TProps> = ({ nowrap, children, className, ...props }) => {
  return (
    <TableCell
      component="th"
      scope="row"
      {...props}
      className={cn(styles.cell, className, { [styles.nowrap]: nowrap })}
    >
      {children}
    </TableCell>
  )
}

export { CustomTableCell }
