import { FC, useState } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ActiveCopyTradesTable, PositionsTable } from '@/components/copy-trade/libs/components'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Icon, Tabs, Typography } from '@/libs/common'
import { AppRoute, IconName, TabName } from '@/libs/enums'

import { CopyTradeStatistics } from './libs/components'
import { UnlinkPopup } from './libs/components/unlink-popup/unlink-popup'
import styles from './styles.module.scss'

type TProps = {
  isScrape?: boolean
}

const CopyTrade: FC<TProps> = ({ isScrape }) => {
  const navigate = useCustomNavigate()
  const [isTelegramConnected, setIsTelegramConnected] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  const title = isScrape ? 'Scrape' : 'Copy trade'

  const tabs = [
    {
      label: isScrape ? 'Active scrape channels' : 'Active copy trades',
      content: <ActiveCopyTradesTable />,
    },
    {
      label: 'Positions',
      content: <PositionsTable />,
    },
  ]

  const handleOpenCopyTradeSettings = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.COPY_TRADE_TEMPLATE}`,
      searchParams: {
        action: 'add-copytrade',
      },
    })
  }

  const handleOpenTelegramModal = () => {
    // `TODO: return action=phone when feature is ready and tested
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.TELEGRAM}`,
      searchParams: {
        action: 'null',
      },
    })
    setIsTelegramConnected(true)
  }

  const handleUnlinkTelegram = () => {
    setIsTelegramConnected(false)
    setAnchorEl(null)
  }

  return (
    <div className={styles.section}>
      {/* <ComingSoonOverlay /> */}
      <div className={styles.container}>
        <Grid container alignItems="center">
          <Typography variant="h1" className={styles.title}>
            {title}
          </Typography>

          <Button
            className={styles.addButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.ADD_CIRCLE}
            transparent
            onClick={isScrape ? handleOpenTelegramModal : handleOpenCopyTradeSettings}
          >
            Add new
          </Button>
        </Grid>

        {isScrape && (
          <Grid>
            <Typography textColor="color-grey-2" className={styles.telegramDescription}>
              Telegram linked
            </Typography>

            {!isTelegramConnected ? (
              <Typography variant="body2" className={styles.linkWrapper}>
                None{' '}
                <Button
                  borderless
                  styleVariant="basic"
                  onClick={handleOpenTelegramModal}
                  className={styles.button}
                >
                  <Icon name={IconName.TELEGRAM_LINK} />
                  Link
                </Button>
              </Typography>
            ) : (
              <>
                <Typography variant="body2" className={styles.linkWrapper}>
                  Stratkind{' '}
                  <Button
                    borderless
                    styleVariant="basic"
                    onClick={handleClick}
                    className={cn(styles.button, { [styles.buttonColor]: isTelegramConnected })}
                  >
                    <Icon name={IconName.TELEGRAM_UNLINK} />
                    Unlink
                  </Button>
                </Typography>

                <UnlinkPopup
                  anchorEl={anchorEl}
                  isOpen={isOpen}
                  onClose={handleClose}
                  onClick={handleUnlinkTelegram}
                />
              </>
            )}
          </Grid>
        )}

        <div className={styles.statisticsWrapper}>
          <CopyTradeStatistics />
        </div>

        <Tabs
          name={isScrape ? TabName.SCRAPE_TABLE : TabName.COPY_TRADE_TABLE}
          tabs={tabs}
          defaultValue={0}
          variant="standard"
        />
      </div>
    </div>
  )
}

export { CopyTrade }
