import { FC, ReactNode } from 'react'

import { Grid } from '@mui/material'

import { Button, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProps = {
  iconName: ValueOf<typeof IconName>
  title: string
  value: string | ReactNode
  additionalValue?: string | ReactNode
  buttonTitle?: string
  onButtonClick?: () => void
  buttonLoading?: boolean
}

const OverviewItem: FC<TProps> = ({
  iconName,
  title,
  value,
  additionalValue,
  buttonTitle,
  onButtonClick,
  buttonLoading,
}) => {
  return (
    <Grid display="flex" flexDirection="column" className={styles.container}>
      <Icon name={iconName} />
      <Typography variant="body1">{title}</Typography>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        className={styles.valueContainer}
      >
        <Typography variant="h6" className={styles.value}>
          {value}
        </Typography>
        {!!buttonTitle && !!onButtonClick && (
          <Button onClick={onButtonClick} className={styles.button} isLoading={buttonLoading}>
            {buttonTitle}
          </Button>
        )}
      </Grid>
      {!!additionalValue && (
        <Typography variant="body1" className={styles.additionalValue}>
          {additionalValue}
        </Typography>
      )}
    </Grid>
  )
}

export { OverviewItem }
