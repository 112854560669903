import { useState } from 'react'

import { signUp } from '@/api/user'
import { TSignupBody } from '@/api/user/types'
import { AuthCard } from '@/components/auth-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useAppDispatch } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

export const AlreadySign = () => {
  const navigate = useCustomNavigate()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  async function addUser() {
    try {
      setLoading(true)
      const data = JSON.parse(localStorage.signData) as TSignupBody

      const affiliateId = sessionStorage.affiliateId
      if (!!affiliateId) {
        data.referred_by = affiliateId
        sessionStorage.removeItem('affiliateId')
      }

      const res = await signUp(data)
      localStorage.signUpData = JSON.stringify(res.data.data)
      localStorage.token = res.data.data.token
      localStorage.refreshToken = res.data.data.refresh_token
      await dispatch(fetchUserById(res.data.data.id)).unwrap()
      navigate({ path: AppRoute.CREATE_MASTER_PASSWORD })
    } catch (e) {
      extractErrorDescription(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthCard className={styles.card}>
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title} align="center">
            Did you already sign up before with an another wallet?
          </Typography>
        </div>
        <Button styleVariant="yellow" isLoading={loading} onClick={addUser}>
          <Typography variant="body2" textColor="black" className={styles.message}>
            I am a new user
          </Typography>
        </Button>

        <Button
          styleVariant="black"
          onClick={() => {
            navigate({ path: `/${AppRoute.MODAL}/${AppRoute.WALLET_CONNECT}` })
          }}
        >
          <Typography variant="body2" textColor="white" className={styles.message}>
            I have signed up before with an another wallet
          </Typography>
        </Button>
      </div>
    </AuthCard>
  )
}
