import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import cn from 'classnames'

import { Icon } from '@/libs/common/index'
import { IconName } from '@/libs/enums'
import { validateLink } from '@/libs/helper/formatLink.ts'
import { TTokenInfo } from '@/libs/types/token-info-response.type'

import styles from './styles.module.scss'

type TTokenSocialsProps = {
  selectedToken: TTokenInfo | null
  isActive?: boolean
  className?: string
  gap?: number | string
}

const TokenSocials: FC<TTokenSocialsProps> = ({
  selectedToken,
  className,
  isActive = false,
  gap = 1,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={gap} className={className}>
      <Link
        to={validateLink(selectedToken?.socialLinks?.website) || ''}
        target="_blank"
        className={cn(styles.socialLink, {
          [styles.activeLink]: isActive,
          [styles.disabledLink]: !selectedToken?.socialLinks?.website,
        })}
      >
        <Icon name={IconName.LANGUAGE} />
      </Link>

      <Link
        to={validateLink(selectedToken?.socialLinks?.telegram) || ''}
        target="_blank"
        className={cn(styles.socialLink, {
          [styles.activeLink]: isActive,
          [styles.disabledLink]: !selectedToken?.socialLinks?.telegram,
        })}
      >
        <Icon name={IconName.EXCLUDE} />
      </Link>

      <Link
        to={validateLink(selectedToken?.socialLinks?.x) || ''}
        target="_blank"
        className={cn(styles.socialLink, {
          [styles.activeLink]: isActive,
          [styles.disabledLink]: !selectedToken?.socialLinks?.x,
        })}
      >
        <Icon name={IconName.TWITTER} />
      </Link>
    </Box>
  )
}

export { TokenSocials }
