import { Grid, InputLabel } from '@mui/material'
import { SwitchProps } from '@mui/material/Switch'
import cls from 'classnames'

import { Switch } from '@/libs/common/switch'
import { TooltipIcon } from '@/libs/common/tooltip-icon'

import styles from './styles.module.scss'

type TProps = SwitchProps & {
  label?: string
  switchLabel?: string
  tooltipInfo?: string
}

const SwitchInline = ({ className, label, switchLabel, tooltipInfo, value, ...props }: TProps) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={cls(styles.wrapper, className)}
    >
      {label && <InputLabel className={styles.label}>{label}</InputLabel>}
      {tooltipInfo && <TooltipIcon info={tooltipInfo} marginLeft={5} />}
      <div className={styles.divider} />
      {switchLabel && <InputLabel className={styles.switchLabel}>{switchLabel}</InputLabel>}
      {value ? <Switch {...props} checked={Boolean(value)} /> : <Switch {...props} />}
    </Grid>
  )
}

export { SwitchInline }
