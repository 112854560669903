import { api } from '@/api'
import { TBackendResponse } from '@/libs/types/backend-response.type'

import {
  TFilterTokensResponse,
  TNewToken,
  TPairStatsResponse,
  TTokenInfoResponse,
  TTokenPriceResponse,
  TTokenTransactionFiltered,
  TTokenTransactionResponse,
  TTopTokensListResponse,
} from './types'

const BASE_URL = 'tokens'

const getTokenPriceInUsd = (address: string, networkId: number) =>
  api.get<TTokenPriceResponse>(`${BASE_URL}/price/${address}/${networkId}`)

const getTopTokensList = (networkId: number) =>
  api.get<TBackendResponse<TTopTokensListResponse>>(`api/v1/token/summary/trending?b=${networkId}`)

const getNewTokensList = (networkId: number) =>
  api.get<TBackendResponse<TNewToken[]>>(`api/v1/token/summary/new?b=${networkId}`)

const getTokenTransactions = (
  address: string,
  networkId: number,
  sorting: string,
  cursor?: string,
  maker?: string,
) =>
  api.get<TBackendResponse<TTokenTransactionResponse>>(
    `api/v1/token/chart/${address}/transactions?b=${networkId}&d=${sorting}&c=${cursor}&m=${maker}`,
  )

const getFilterTokens = (addressOrPhrase: string, networkId: number) =>
  api.get<TFilterTokensResponse>(`/api/v1/token/base/${addressOrPhrase}/search?b=${networkId}`)

const getTransactionsEnsNames = (body: any) =>
  api.post<any>(`${BASE_URL}/transactions/ens/name`, body)

const getTokenTransactionsFiltered = async (
  address: string,
  networkId: number,
  sorting: string,
  cursor?: string,
  maker?: string,
): Promise<TTokenTransactionResponse> => {
  const { data } = await getTokenTransactions(address, networkId, sorting, cursor, maker)
  return {
    cursor: data.data.cursor,
    items: data.data.transactions.map((item: TTokenTransactionFiltered) => {
      return { ...item, ...item.data } as TTokenTransactionFiltered
    }),
  }
}

const getTokenExchangesAndSocial = (address: string, networkId: number) =>
  api.get<TBackendResponse<TTokenInfoResponse>>(`api/v1/token/base/${address}/data?b=${networkId}`)

const getDetailedPairStats = (address: string, networkId: number) =>
  api.get<TPairStatsResponse>(`${BASE_URL}/pair-stats/${address}/${networkId}`)

const removeTokenFromHoneypot = (tokenAddress: string, networkId: number) =>
  api.delete(`api/v1/indexer/wallet/${tokenAddress}/remove?b=${networkId}&l=en`)

export {
  getTokenPriceInUsd,
  getTokenTransactions,
  getTokenTransactionsFiltered,
  getTokenExchangesAndSocial,
  getDetailedPairStats,
  getFilterTokens,
  getTopTokensList,
  getNewTokensList,
  getTransactionsEnsNames,
  removeTokenFromHoneypot,
}
