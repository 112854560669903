import { TSniperSimulationData } from '@/libs/types/sniper-simulation-socket-response.type'

export function adaptSimulation(data: TSniperSimulationData) {
  return {
    data: {
      name: data.t.n,
      symbol: data.t.s,
      address: data.t.a,
      tradeable: data.t.t,
      honeypot: data.t.h,
      market_cap: data.t.mc,
      renounced: data.t.r,
      burned: data.t.b,
      liquidity: {
        pair: {
          reserve: data.l.p.r,
          value_usd: data.l.p.vu,
        },
        burned: data.l.b,
        dex_name: data.l.dn,
        total_supply: data.l.ts,
        suggested_dex: Number(data.l.sd),
      },
      max_tx: data.lt.b.ccc,
      max_wallet: data.lt.w.ccc,
      clog: data.t.c,
      taxes: {
        buy: data.tx.b,
        sell: data.tx.s,
        transfer: data.tx.t,
      },
      limits: {
        buy: {
          percentage: data.lt.b.p,
          cost_chain_currency: data.lt.b.ccc,
        },
        wallet: {
          percentage: data.lt.w.p,
          cost_chain_currency: data.lt.w.ccc,
        },
      },
    },
  } as const
}
