import { FC, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Grid } from '@mui/material'

import { TBalancesItem } from '@/api/indexer/types'
import { SelectWallet } from '@/components/select-wallet'
import { chainsConfig } from '@/libs/configs/chains.config'
import { TChainConfig } from '@/libs/types/chain.type'

import { BridgeTokenSelect } from '../../../bridge-token-select'
import { TBridgeTokenItem } from '../../types'

type TProps = {
  title: string
  walletsLabel: string
  setSelectedToken: (token: TBridgeTokenItem) => void
  selectedNetwork: TChainConfig
  walletsFieldName: string
  chainFieldName: string
  excludeNetworkIds?: number[]
  onChainChange?: (oldChain: TChainConfig, newChain: TChainConfig) => void
  onWalletsChange?: (newWallet: TBalancesItem) => void
  defaultWalletAddress?: string
  disabled?: boolean
}

const BridgeTokenSelectWithWallets: FC<TProps> = ({
  title,
  walletsLabel,
  setSelectedToken,
  selectedNetwork,
  excludeNetworkIds,
  walletsFieldName,
  chainFieldName,
  onChainChange,
  onWalletsChange,
  defaultWalletAddress,
  disabled,
}) => {
  const [wallets, setWallets] = useState<TBalancesItem[]>([])
  const defaultWalletUpdated = useRef(!defaultWalletAddress)

  const { control, setValue } = useFormContext<any>()

  const selectChain = async (fieldName: string, chainId: number) => {
    const newChain = chainsConfig.find((item) => item.indexerChainId === chainId)!
    onChainChange?.(selectedNetwork, newChain)
    setValue(fieldName, newChain)
  }

  return (
    <Grid gap={2} display="grid">
      <Controller
        name={walletsFieldName}
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => {
          return (
            <>
              <BridgeTokenSelect
                title={title}
                selectedIndexerChainId={selectedNetwork.indexerChainId}
                selectChain={(chainId) => selectChain(chainFieldName, chainId)}
                excludeChainIds={excludeNetworkIds}
                onTokenSelect={(token) => {
                  setSelectedToken(token)
                  setWallets(token.wallets)
                }}
                selectedNetwork={selectedNetwork}
                disabled={disabled}
                onDataLoad={(data) => {
                  setWallets(data.wallets)
                  if (defaultWalletAddress && !defaultWalletUpdated.current) {
                    defaultWalletUpdated.current = true
                    onChange(data.wallets.find((wallet) => wallet.address === defaultWalletAddress))
                  }
                }}
              />
              <SelectWallet
                label={walletsLabel}
                disabled={disabled}
                tooltipInfo="Select which wallets will be used to purchase token."
                showOnlyWallets
                wallet={value ? [value.address] : []}
                wallets={wallets.map((wallet) => ({
                  ...wallet,
                  balanceFormatted: wallet.balance.formatted,
                  holdingData: {
                    balanceFormatted: wallet.balance.formatted,
                  },
                }))}
                onChange={(value) => {
                  const newWallet = wallets.find((wallet) => value.includes(wallet.address))!
                  onChange(newWallet, { validate: true })
                  onWalletsChange?.(newWallet)
                }}
                tokenSymbol={selectedNetwork.chainSymbol}
                {...field}
              />
            </>
          )
        }}
      />
    </Grid>
  )
}

export { BridgeTokenSelectWithWallets }
