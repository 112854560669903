import { FC, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { Currency } from '@/components/currency'
import { PositionCard } from '@/components/position-card'
import { SuspiciousTokenWarningLabel } from '@/components/suspicious-token-warning-label'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSorting } from '@/hooks/useSorting'
import {
  Button,
  Divider,
  TokenPlaceholder,
  TokenRedirect,
  Tooltip,
  Typography,
} from '@/libs/common'
import { AppRoute, IconName, Sorting } from '@/libs/enums'
import { createSellPayload, getPriceImpactColor, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import {
  TActivePanelItem,
  TPanelPositionItemSocket,
} from '@/libs/types/panels-data-socket-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentPositionId } from '@/store/slices/profit-sell.slice'

import styles from './styles.module.scss'

type TProps = {
  position: TActivePanelItem
  usedInModal?: boolean
}

const PositionsPage: FC<TProps> = ({ position, usedInModal }) => {
  const dispatch = useAppDispatch()
  const navigate = useCustomNavigate()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const returnToPositionsSummariesPage = () => dispatch(setCurrentPositionId(null))
  const userConfirmationRequired = useAppSelector((state) => state.user.userData.sell_confirmation)

  const { SortIcon, sorting } = useSorting()

  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({ loadingAtLeastOne: false })
  const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
    checkedAtLeastOne: false,
    checkedAll: false,
  })

  const availableWallets = useMemo(
    () =>
      position.po
        .filter((wallet) => +wallet.ba !== 0)
        .sort((a, b) => (sorting === Sorting.ASC ? +a.w.u - +b.w.u : +b.w.u - +a.w.u)),
    [position, sorting],
  )

  const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedState = {
      ...positionsChecked,
      [event.target.name]: checked,
    }
    const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
    const positionsValues = Object.values(onlyPositions)
    const chackedPositions = positionsValues.filter((checked) => checked)
    const atLeastOneChecked = !!chackedPositions.length
    const checkedAllPositions = positionsValues.length === chackedPositions.length

    setPositionsChecked({
      ...updatedState,
      checkedAtLeastOne: atLeastOneChecked,
      checkedAll: checkedAllPositions,
    })
  }

  const handleLoading = (loading: boolean, selectedWallets: TPanelPositionItemSocket[]) => {
    const updatedState: Record<string, boolean> = {
      loadingAtLeastOne: loading && !!selectedWallets.length,
    }
    selectedWallets.forEach((el) => (updatedState[el.wa] = loading))
    setIsLoading(updatedState)
  }

  const sellPosition = async (
    selectedWallets: TPanelPositionItemSocket[],
    type: 'all' | 'initial',
  ) => {
    if (!selectedWallets.length) return null

    handleLoading(true, selectedWallets)
    const wallets = selectedWallets.map((item) => ({
      name: item.wn,
      public_key: item.wa,
    }))

    const payload = await createSellPayload({
      data: {
        receive: '0',
        sell: '0',
        sellPriority: defaultPriorities.sell_priority,
        slippage: currentChain.defaultValues.slippage,
        privateTransaction: !currentChain.features?.noPrivateTx,
      },
      token: position.ta,
      wallets,
      mode: type === 'all' ? 0 : 2,
      sellPercentage: type === 'all' ? 100 : 0,
      dex: selectedWallets[0].sd,
    })

    if (payload) {
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: {
            payload,
            data: {
              receive: selectedWallets[0].w.t,
              sell: selectedWallets[0].ba,
              sellPriority: defaultPriorities.sell_priority,
              slippage: currentChain.defaultValues.slippage,
              privateTransaction: !currentChain.features?.noPrivateTx,
              tokenName: position.tos,
            },
          },
        })
      } else {
        await handleSell(payload)
      }
    }

    handleLoading(false, selectedWallets)
    return null
  }

  const setAllChecked = (isChecked = !positionsChecked.checkedAll) => {
    if (!availableWallets) return

    const allPositionsChecked = availableWallets.reduce((sum, { wa }) => {
      return { ...sum, [wa]: isChecked }
    }, {})

    setPositionsChecked({
      ...positionsChecked,
      ...allPositionsChecked,
      checkedAtLeastOne: isChecked,
      checkedAll: isChecked,
    })
  }

  const handleSellSelected = (type: 'all' | 'initial') => {
    if (!availableWallets) return
    const wallets = availableWallets.filter(
      (wallet) => wallet && positionsChecked[wallet.wa],
    ) as TPanelPositionItemSocket[]
    if (wallets?.length && wallets[0]) sellPosition(wallets, type)
  }

  const handleOpenManualSellModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.MANUAL_SELL}`,
      state: {
        wallets: availableWallets.filter((wallet) => wallet && positionsChecked[wallet.wa]),
        position,
      },
    })
    setAllChecked(false)
  }

  // const [isActiveLoading, setIsActiveLoading] = useState(false)
  // const [isActive, setIsActive] = useState(position.panel.isActive)
  // const [isSellAll, setIsSellAll] = useState(false)
  // const [isSellInitial, setIsSellInitial] = useState(false)
  // const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
  //   checkedAtLeastOne: false,
  //   checkedAll: false,
  // })

  // const handleActive = async (_: BaseSyntheticEvent, newValue: boolean) => {
  //   try {
  //     setIsActiveLoading(true)
  //     setIsActive(newValue)
  //     await setProfitPanelActivity(position.panel._id, newValue)
  //     await dispatch(fetchProfitSell()).unwrap()
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //     setIsActive(!newValue)
  //   } finally {
  //     setIsActiveLoading(false)
  //   }
  // }

  // const handleCloseSellMessage = () => {
  //   if (isSellAll) {
  //     setIsSellAll(false)
  //   } else if (isSellInitial) {
  //     setIsSellInitial(false)
  //   }
  // }

  // const handleOpenEditPositions = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}`, { state: { positionsChecked } })
  // }

  // const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   const updatedState = {
  //     ...positionsChecked,
  //     [event.target.name]: checked,
  //   }

  //   const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
  //   const atLeastOneChecked = Object.values(onlyPositions).some((checked) => checked)

  //   setPositionsChecked({
  //     ...updatedState,
  //     checkedAtLeastOne: atLeastOneChecked,
  //   })
  // }
  //   setPositionsChecked({
  //     ...positionsChecked,
  //     ...allPositionsChecked,
  //     checkedAtLeastOne: !positionsChecked.checkedAll,
  //     checkedAll: !positionsChecked.checkedAll,
  //   })
  // }

  // const invertAllChecked = () => {
  //   const invertedPositionsChecked = mockPositionsData.reduce((result, { id }) => {
  //     return { ...result, [id]: !positionsChecked[id] }
  //   }, {})

  //   setPositionsChecked({
  //     ...positionsChecked,
  //     ...invertedPositionsChecked,
  //     checkedAtLeastOne: !positionsChecked.checkedAll,
  //     checkedAll: !positionsChecked.checkedAll,
  //   })
  // }

  // const handleOpenShareProfitModal = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
  //     state: {
  //       positionsSummary: positionSummary,
  //     },
  //   })
  // }

  const { checkedAtLeastOne, checkedAll, ...walletsWithToken } = positionsChecked
  const countOfSelectedWalletsWithToken = Object.values(walletsWithToken).filter(Boolean).length
  const isHoneypotWithLiquidity = position.h && position.l > 0
  return (
    <>
      <Grid container wrap="nowrap" alignItems="center" gap={1}>
        <Button
          className={styles.buttonBack}
          styleVariant="borderless-gold"
          iconStartName={IconName.ARROW_RIGHT}
          onClick={returnToPositionsSummariesPage}
          transparent
        />
        <Typography variant="body2" textColor="color-grey-4" marginLeft={-1}>
          Select position
        </Typography>
        <SortIcon />
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <TokenRedirect className={styles.rowContainerItem} address={position.ta}>
          <Grid container alignItems="center" gap={1}>
            <Grid container className={styles.iconHeader}>
              {position.i && position.i.s ? (
                <img className={styles.tokenLogo} src={position.i.s} alt={position.tn + ' token'} />
              ) : (
                <TokenPlaceholder />
              )}
            </Grid>
            {position.tn && (
              <Typography variant="body2" component="span" textColor="light-grey">
                {position.tn}
              </Typography>
            )}
            <Typography variant="body2" component="span">
              ({formatTokenPrice(`${position.tb}`).formatted})
            </Typography>
            {position.tps && (
              <Typography variant="body2" component="span">
                {formatTokenPrice(`${position.tps}`).formatted}%
              </Typography>
            )}
          </Grid>
        </TokenRedirect>

        {/* <Grid container className={styles.rowContainerItem}>
          <Typography variant="body2" noWrap>
            P/S Panel active
          </Typography>
          <Switch checked={isActive} onChange={handleActive} disabled={isActiveLoading} />
        </Grid> */}
      </Grid>

      <SuspiciousTokenWarningLabel tokenPosition={position} marginTop={-1} />

      <Grid container wrap="nowrap" justifyContent="space-between" marginTop={1.5}>
        <VerticalGroup
          title={'Profit/Loss'}
          description={
            <span
              className={cn(
                +position.tpl > 0 && !isHoneypotWithLiquidity ? styles.isProfit : styles.isLoss,
              )}
            >
              {'%'}
              {Math.abs(!isHoneypotWithLiquidity ? +position.tpl : 100).toFixed(3)}
            </span>
          }
        />
        <VerticalGroup
          title={'Cost'}
          description={
            <>
              {formatTokenPrice(`${position.tc.t}`, 3).formatted} <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'Worth'}
          descClassName={position.tw.u > position.tc.u ? 'greenText' : ''}
          description={
            <>
              {!isHoneypotWithLiquidity ? formatTokenPrice(`${position.tw.t}`, 3).formatted : 0}{' '}
              <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'After Tax'}
          descClassName={position.tat.u > position.tc.u ? 'greenText' : ''}
          description={
            <>
              {!isHoneypotWithLiquidity ? formatTokenPrice(`${position.tat.t}`, 3).formatted : 0}{' '}
              <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'Sold'}
          description={
            <>
              {!isHoneypotWithLiquidity ? formatTokenPrice(position.ts.t, 3).formatted : 0}{' '}
              <Currency />
            </>
          }
        />
        <VerticalGroup
          title={'Price impact'}
          descClassName={getPriceImpactColor(+position.tpi)}
          description={
            <>
              {!isHoneypotWithLiquidity
                ? formatTokenPrice(position.tpi.toString(), 3).formatted
                : 0}
              %
            </>
          }
        />
      </Grid>

      <Divider color="dashboard-border" />

      {availableWallets.length ? (
        <>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" gap={1} className={styles.rowContainerItem}>
              <Typography variant="body2" component="span" textColor="light-grey">
                Open positions
              </Typography>
              <Typography variant="body2" component="span" textColor="light-grey">
                <Box display="flex" gap="12px" color="#d1d1d1">
                  {`${countOfSelectedWalletsWithToken}/${availableWallets?.length}`}
                </Box>
              </Typography>
            </Grid>

            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              className={styles.buttonGroup}
            >
              <Button
                styleVariant="borderless-gold"
                transparent
                className={styles.button}
                onClick={() => setAllChecked()}
              >
                {!positionsChecked.checkedAll ? 'Select all' : 'Unselect all'}
              </Button>

              {/* <Button
                styleVariant="borderless-gold"
                iconStartName={IconName.SHARE}
                transparent
                className={styles.button}
                // disabled={!positionsChecked.checkedAtLeastOne}
                // onClick={handleOpenShareProfitModal}
                disabled
              >
                Share selected
              </Button>

              <Button
                styleVariant="borderless-gold"
                iconStartName={IconName.EDIT}
                transparent
                className={styles.button}
                // disabled={!positionsChecked.checkedAtLeastOne}
                // onClick={handleOpenEditPositions}
                disabled
              >
                Edit selected
              </Button> */}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap={1.5}
            className={cn(styles.cardsContainer, { [styles.usedInModal]: usedInModal })}
          >
            {availableWallets.map((wallet) => (
              <PositionCard
                className={cn(styles.card, { [styles.expandedCard]: availableWallets.length > 1 })}
                variant="profit"
                key={wallet.wa}
                // message={
                //   <PositionSoldCard
                //     isSellAll={isSellAll}
                //     isSellInitial={isSellInitial}
                //     soldTokenData={soldTokenData}
                //     handleCloseSellMessage={handleCloseSellMessage}
                //     setIsSellInitial={setIsSellInitial}
                //     tokenInfo={positionData}
                //   />}
                message={<div></div>}
                isMarketSellLoading={isLoading[wallet.wa]}
                onMarketSellAll={() => sellPosition([wallet], 'all')}
                onSellInitial={() => sellPosition([wallet], 'initial')}
                positionData={{
                  id: wallet.wa,
                  isActive: true,
                  coin: position.tn || '',
                  coinCompairedWith: currentChain.description,
                  totalBalance: +wallet.ba || 0,
                  totalWallets: position.po.length || 0,
                  profitOrLoss: +wallet.pl,
                  cost: `${wallet.c.t}`,
                  sold: +wallet.so.t,
                  worth: +wallet.w.t,
                  afterTax: +wallet.at.t || 0,
                  priceImpact: wallet.pi,
                  percentageOfSupply: wallet.ps,
                }}
                position={{ ...position, tw: wallet.w, tc: wallet.c, tpl: wallet.pl }}
                isSold={false}
                checkboxProps={{
                  name: wallet.wa,
                  checked: positionsChecked[wallet.wa] ?? false,
                  onChange: handlePositionChecking,
                }}
              />
            ))}
          </Grid>

          <Grid container wrap="nowrap" className={styles.footer}>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={() => handleSellSelected('all')}
              >
                Sell All
              </Button>
            </Tooltip>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={() => handleSellSelected('initial')}
              >
                Sell Initial
              </Button>
            </Tooltip>
            <Tooltip
              title="Select positions to enable"
              withIcon={false}
              disableHoverListener={positionsChecked.checkedAtLeastOne}
            >
              <Button
                disabled={!positionsChecked.checkedAtLeastOne || isLoading.loadingAtLeastOne}
                onClick={handleOpenManualSellModal}
              >
                Manual Sell
              </Button>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
          You have no wallets
        </Typography>
      )}
    </>
  )
}

export { PositionsPage }
