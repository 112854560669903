import { FC, useState } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MUIInput, { InputProps as MUIInputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { Tooltip, Typography } from '@/libs/common/index'

import { getInputId } from './helpers/getInputId'
import styles from './styles.module.scss'

type TInputPasswordProps = MUIInputProps & {
  label?: string
  labelTooltipTitle?: string
  idForInput?: string
  isTransparent?: boolean
  maxLength?: number
  errorMessage?: string
  isHideInputCaption?: boolean
  labelClassName?: string
  dataQa?: string
}

const InputPassword: FC<TInputPasswordProps> = ({
  className,
  label,
  labelTooltipTitle,
  maxLength,
  placeholder = 'use placeholder prop',
  error,
  errorMessage,
  onChange,
  idForInput,
  value,
  isHideInputCaption = false,
  isTransparent,
  labelClassName,
  dataQa,
  ...props
}) => {
  idForInput = idForInput || getInputId(label)

  const [currentValue, setCurrentValue] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const onChangeDecorator = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event)
    } else {
      setCurrentValue(event.currentTarget.value)
    }
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Stack className={styles.container} spacing={1}>
      <Grid container wrap="nowrap" justifyContent="space-between">
        {label && !labelTooltipTitle && (
          <InputLabel className={cn(styles.label, labelClassName)} htmlFor={idForInput}>
            {label}
          </InputLabel>
        )}
        {label && labelTooltipTitle && (
          <Tooltip title={labelTooltipTitle}>
            <InputLabel className={cn(styles.label, labelClassName)} htmlFor={idForInput}>
              {label}
            </InputLabel>
          </Tooltip>
        )}
      </Grid>

      <MUIInput
        id={label && idForInput}
        type={showPassword ? 'text' : 'password'}
        className={cn(styles.root, className, {
          [styles['Mui-error']]: error,
          [styles.transparent]: isTransparent,
        })}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff className={styles.iconEye} />
              ) : (
                <Visibility className={styles.iconEye} />
              )}
            </IconButton>
          </InputAdornment>
        }
        onChange={onChangeDecorator}
        inputProps={{ maxLength }}
        value={value || currentValue}
        autoComplete="off"
        {...props}
      />
      {(errorMessage || maxLength) && !isHideInputCaption && (
        <div className={styles.inputCaption}>
          {errorMessage && (
            <Typography variant="caption" className={styles.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <div></div>
          {maxLength && (
            <Typography variant="caption" className={styles.counter}>
              {value ? `${value}`.length : currentValue.length}/{maxLength}
            </Typography>
          )}
        </div>
      )}
    </Stack>
  )
}

export { InputPassword, type TInputPasswordProps }
