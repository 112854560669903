import { FC } from 'react'
import { Line } from 'react-chartjs-2'

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js'
import cn from 'classnames'

import styles from './styles.module.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const verticalLinePlugin = {
  id: 'verticalLinePlugin',
  beforeDraw: (chart: any) => {
    if (chart.tooltip?._active?.length) {
      const ctx = chart.ctx
      const x = chart.tooltip._active[0].element.x
      const topY = chart.scales.y.top
      const bottomY = chart.scales.y.bottom

      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.setLineDash([5, 10])
      ctx.strokeStyle = '#505050'
      ctx.lineWidth = 1
      ctx.stroke()
      ctx.restore()
    }
  },
}

type TProps = {
  isMiniChart?: boolean
}

const Chart: FC<TProps> = ({ isMiniChart = false }) => {
  const ctx = document.createElement('canvas').getContext('2d')
  if (!ctx) return null

  const height = isMiniChart ? 102 : 400
  const lineGradient = ctx.createLinearGradient(0, 0, 0, height)

  lineGradient.addColorStop(0.3, 'rgba(61, 200, 65, 1)')
  lineGradient.addColorStop(0.46, 'rgba(255, 50, 0, 1)')

  const labels = [
    '2024-01-08 15:00',
    '2024-02-01 13:00',
    '2024-03-08 7:00',
    '2024-05-08 19:00',
    '2024-06-08 00:00',
    '2024-07-08 1:00',
    '2024-08-08 18:00',
    '2024-09-08 12:00',
    '2024-10-08 13:00',
    '2024-11-08 5:00',
    '2024-12-08 6:00',
    '2025-01-08 11:00',
  ]

  const data = () => {
    return {
      labels,
      datasets: [
        {
          data: [-1900, -200, -10, -710, 2840, 65, 1300, 1400, 1400, 1500, 2900, 3910],
          pointBackgroundColor: '#101010',
          pointBorderColor: '#FFC800',
          pointRadius: 0,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: 'start',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx
            const height = isMiniChart ? 53 : 205
            const gradient = ctx.createLinearGradient(0, 0, 0, height)
            gradient.addColorStop(0.67, 'rgba(61, 175, 65, 0.2)')
            gradient.addColorStop(1, 'rgba(60, 0, 0, 0.1)')
            return gradient
          },
          borderColor: lineGradient,
          borderWidth: 2,
        },
      ],
    }
  }

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: isMiniChart ? false : true,
          color: '#303030',
        },
        ticks: {
          display: isMiniChart ? false : true,
        },
      },
    },
    elements: {
      line: {
        tension: 0.3,
      },
      point: {
        radius: 0,
        hoverRadius: 5,
        hoverBorderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        bodyFont: {
          family: 'Grotesk, sans-serif',
          size: isMiniChart ? 12 : 14,
        },
        titleFont: {
          family: 'Grotesk, sans-serif',
          size: isMiniChart ? 12 : 14,
          weight: isMiniChart ? 500 : 'normal',
        },
        titleAlign: 'center',
        padding: isMiniChart ? 6 : 15,
        bodySpacing: 10,
        bodyAlign: 'center',
        displayColors: false,
        backgroundColor: '#2b2b2b',
        yAlign: 'top',
        titleColor: isMiniChart ? '#979797' : '#fff',
        callbacks: {
          title(tooltipItem: TooltipItem<'line'>[]) {
            return tooltipItem[0].label
          },
          label(tooltipItem: TooltipItem<'line'>) {
            if (isMiniChart) {
              const value = tooltipItem.raw as number

              return `$${value} ` + (value > 0 ? `▲` : `▼`) + ` ${(value / 100).toFixed(2)}%`
            }

            return (tooltipItem.raw as number) > 0
              ? `PNL:+${tooltipItem.formattedValue}%`
              : `PNL:${tooltipItem.formattedValue}%`
          },
          labelTextColor: function (context) {
            if (!isMiniChart) {
              return
            }

            const value = context.raw as number
            return value > 0 ? '#3daf41' : '#ff5757'
          },
        },
      },
      filler: {
        propagate: false,
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
  }

  return (
    <div className={cn(styles.container, { [styles.containerMini]: isMiniChart })}>
      <Line data={data()} options={options} plugins={[verticalLinePlugin]} />
    </div>
  )
}

export { Chart }
