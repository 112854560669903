import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'
import { store } from '@/store'

import { TAffilitesData } from './types'

const BASE_URL = 'api/v1/affiliates/base'

const claimAffiliatesFees = () => {
  const userId = getCurrentUserId()
  const blockchain = store.getState().chain.currentChain.indexerChainId
  return api.get(`${BASE_URL}/${userId}/fees/claim?b=${blockchain}&l=en`)
}

const getMyAffiliatesInfo = async () => {
  const userId = getCurrentUserId()
  const blockchain = store.getState().chain.currentChain.indexerChainId
  return api.get<TBackendResponse<TAffilitesData>>(
    `${BASE_URL}/${userId}/fees/get?b=${blockchain}&l=en`,
  )
}

export { getMyAffiliatesInfo, claimAffiliatesFees }
