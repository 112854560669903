const AppRoute = {
  ROOT: '/',
  LOGIN: '/login',
  ENABLE_2FA: 'enable-2fa',
  SIGN_UP: '/sign-up',
  SELECT_PLAN: '/select-plan',
  SETUP_WALLETS: '/setup-wallets',
  GENERATE_WALLETS: '/generate-wallets',
  SUCCESS_PAID_PLAN: '/success-paid-plan',
  SUCCESS_PAID_PLAN_SUCCESS: '/success-paid-plan-success',
  MASTER_PASSWORD_ADVANTAGES: '/master-password-advantages',
  CREATE_MASTER_PASSWORD: '/create-master-password',
  SETUP_SUCCESS: '/setup-success',
  IMPORT_KEYS: '/import-keys',
  IMPORT_WALLET_FILE: '/import-wallet-file',
  DASHBOARD: '/dashboard',
  FRAME: '/frame',
  MODAL: 'modal',
  WALLET_MANAGEMENT: 'wallet-management',
  EDIT_WALLET_NAME: 'edit-wallet-name',
  WALLET_DETAILS: 'wallet-details',
  HIDDEN_HOLDINGS: 'hidden-holdings',
  TRANSFER: 'transfer',
  CONFIRM_TRANSFER: 'confirm-transfer',
  DISPERSE_MULTI: 'disperse-multi',
  PRELAUNCH_SNIPES: 'prelaunch-snipes',
  TOKEN_INFO: 'token-info',
  SNIPE_SETTINGS: 'snipe-settings',
  SELECT_WALLET_COUNT: 'select-wallet-count',
  LIMIT_REACHED: 'limit-reached',
  IMPORT_PRIVATE_KEY: 'import-private-key',
  IMPORT_WALLETS: 'import-wallets',
  WALLET_FILE: 'wallet-file',
  MASTER_PASSWORD: 'master-password',
  PROFIT_SELL_ADD_TOKEN: 'add-token',
  PROFIT_SELL_ADD_TOKEN_SETUP: 'add-token-setup',
  MANUAL_SELL: 'manual-sell',
  EDIT_POSITION: 'edit-position',
  SHARE_PROFIT: 'share-profit',
  MANUAL_BUY_TEMPLATE: 'manual-buy-template',
  COPY_TRADE: 'copy-trade',
  COPY_TRADE_SETTINGS: 'copy-trade-settings',
  SNIPE_TEMPLATE: 'snipe-template',
  COPY_TRADE_TEMPLATE: 'copy-trade-template',
  TELEGRAM: 'telegram',
  ADD_NEW_SCRAPE_GROUP: 'add-new-scrape-group',
  ADD_NEW_SCRAPE: 'add-new-scrape',
  ADD_NEW_SCRAPE_TEMPLATE: 'add-new-scrape-template',
  LICENSE_NEW_SERVER: 'license-new-server',
  EDIT_LIMIT_SELL: 'edit-limit-sell',
  EDIT_LIMIT_BUY: 'edit-limit-buy',
  PAY_FOR_PLAN: 'pay-for-plan',
  TEMPLATE_MANAGEMENT: 'template-management',
  SELECT_WALLET_TO_DEPOSIT: 'select-wallet-to-deposit',
  DEPOSIT_TO_WALLET: 'deposit-to-wallet',
  SWITCH_CHAIN: 'switch-chain',
  TRANSFER_WALLETS: 'transfer-wallets',
  AFFILIATES: 'affiliates',
  SETUP_PRIORITIES: 'setup-priorities',
  BUY_MODAL: 'buy-modal',
  SELL_MODAL: 'sell-modal',
  PROFIT_MODAL: 'profit-modal',
  COLLECT_MULTI: 'collect-multi',
  LITE_BUY: 'lite-buy',
  LITE_SELL: 'lite-sell',
  CONNECT_WALLET: 'connect-wallet',
  ALREADY_SIGN: 'already-sign',
  ADD_CHAIN: 'list-chain',
  ENTER_CODE: 'enter-code',
  WALLET_CONNECT: 'wallet-connect',
  ADD_DEVICE: '/add-device',
  ACCOUNT_LOCKING: 'account-locking',
  EXPORT_WALLETS: 'export-wallets',
  FAVORITES_HISTORY: 'favorites-history',
  HOLDINGS: 'holdings',
  QUICK_BUY_SETTINGS: 'quick-buy-settings',
  CREATE_WEBHOOK: 'create-webhook',
  EDIT_WEBHOOK: 'edit-webhook',
  BUBBLE_MAP: 'bubblemap',
  APPROVE_SELL: 'approve-sell',
  BRIDGE: 'bridge',
  BRIDGE_CONFIRM: 'bridge-confirm',
} as const

export { AppRoute }
